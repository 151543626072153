import React, { useState, useEffect, Fragment } from 'react'
import { Button } from "rsuite"
import history from "../../helper/History"
import loader from "../../assets/loader.gif";
import CardForm from "../course/payments"
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { RiErrorWarningLine } from 'react-icons/all'
import logo from "../../assets/powered_by_stripe.svg";
import ssl from "../../assets/ssls.svg";
import axios from "axios"
const stripePromise = loadStripe("pk_test_JG9f3af2QL0GFvhhpmP0qZRB00bSb6T6dE");

export default function SavedCard({
    fullPay,
    fees,
    courseDetails,
    currentModule,
    paymentSuccess,
    payFromSavedCard,
    enrollCourse,
    updateCardTrue,
    updateCardFalse,
    handlePaymentResponse,
    handlePayError,
    payError,
    registrationFees
}) {
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [billData, setBillData] = useState('')
    const [cardNumber, setCardNumber] = useState('')

    const navigateToProfile = () => {
        setShowForm(true)
        updateCardTrue()
        // history.push({ pathname: '/profile', state: { pageId: '1' } })
    }


    const payNow = () => {
        updateCardFalse()
        let data = {
            course: courseDetails.id,
            full_pay: !fullPay
        }
        setLoading(true)
        payFromSavedCard(data)
            .then((res) => {
                paymentSuccess()
                setLoading(false)
                enrollCourse()
            })
            .catch((err) => {
                setLoading(false)
            })
        console.log("Course Details: ", courseDetails)
        console.log("Current Module: ", currentModule)
    }

    useEffect(() => {
        axios.get("/api/student/bill_info/").then((res) => {
            setBillData(res.data)
            setCardNumber("xxxx-xxxx-xxxx-" + res.data[0].last4_digit)
        })
    }, [])


    return (
        <div className="modal savedCards" tabIndex="-1" role="dialog" id="savedCards" >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" onClick={() => setShowForm(false)} >&times;</button>
                    <div className="modal-body savedCards-body">
                        {loading && <Fragment>
                            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
                            <div className="loader">
                                <img src={loader} alt="loader" />
                            </div>
                        </Fragment>}

                        {!showForm ?
                            <div className="payments">
                                {payError !== null &&
                                    <div className="payment-message">
                                        <RiErrorWarningLine
                                            size={20}
                                            className="payment-msg-icon"
                                        />
                                        <p>
                                            <span>Error</span>
                                            {payError}
                                        </p>
                                    </div>
                                }
                                <div>
                                    {fullPay && (
                                        <div className="alert alert-warning" role="alert" style={{ fontSize: "14px" }}>
                                            <span style={{ marginRight: "5px" }}>
                                                <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
                                            </span>
                          The registration fee for this program is
                                            {fullPay
                                                ? ` $${registrationFees} CAD`
                                                : courseDetails.course_price}
                          . You can make your payment here.
                                        </div>
                                    )}
                                    <div className="mb-24">
                                        <label htmlFor="Fees">Course Fees</label>
                                        <input type="text" id="Fees" value={courseDetails.course_price} style={{ width: "100%" }} />
                                    </div>
                                    <div className="mb-24">
                                        <label htmlFor="Fees">Amount to pay</label>
                                        <input type="text" id="Fees"
                                            value={
                                                fullPay
                                                    ? `$${registrationFees} CAD`
                                                    : courseDetails.course_price
                                            }
                                            placeholder="enter your email"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="mb-24">
                                        <label htmlFor="cardNumber">Card Number</label>
                                        <input type="text" id="cardNumber" value={cardNumber} style={{ width: "100%" }} />
                                    </div>
                                </div>

                            </div>
                            :
                            <div className="payments" >
                                <div className="stripe" >
                                    <Elements stripe={stripePromise}>
                                        <CardForm
                                            updateCard
                                            handlePaymentResponse={handlePaymentResponse}
                                            handlePayError={(e) => handlePayError(e)}
                                        />
                                    </Elements>
                                </div>
                            </div>
                        }
                        {!showForm && <div className="savedCards-btnBox" >
                            <Button appearance="ghost" size="lg" onClick={navigateToProfile} >Update Card Details</Button>
                            <Button appearance="primary" size="lg" onClick={payNow} >Pay Now</Button>
                        </div>}
                        {!showForm &&
                            <div className="paymet-partnerts">
                                <img
                                    src={logo}
                                    className="logo"
                                    alt="logo niwe"
                                // width="200"
                                />
                                <img
                                    src={ssl}
                                    className="logo"
                                    alt="logo niwe"
                                // width="200"
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
