import React, { useEffect, Fragment } from 'react'
import Navbar from "./Navbar"
import Banner from "./Banner"
import Info from "./Info"
import TopCourse from "./TopCourse"
import BrowseCourse from "./BrowseCourse"
import Footer from '../../components/Footer/Footer'
import { connect } from "react-redux"
import { getLandingDetail } from "../../actions/courseAction"
import loader from "../../assets/loader.gif"
import * as _ from "lodash"
function Landing({ isAuthenticated, getLandingDetail, landing }) {

    useEffect(() => {
        getLandingDetail()
    }, [])

    return (
        <div className="landing">
            {(landing !== null && !_.isEmpty(landing)) ? <div className="container" >
                {!isAuthenticated && <Navbar />}
                <Banner landing={landing} />
                <TopCourse />
                <Info type="video" showButton file={landing.speaker_video} info={landing.speaker_message} title="Message from our President" button />
                <BrowseCourse />
            </div> :
                <Fragment>
                    <div
                        className="modal-backdrop in"
                        style={{ opacity: "0" }}
                    ></div>
                    <div className="loader">
                        <img src={loader} alt="loader" />
                    </div>
                </Fragment>

            }
            {!isAuthenticated && landing !== null && !_.isEmpty(landing)&&
                <div>
                    <Footer />
                </div>
            }
        </div>
    )
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        landing: state.auth.landing
    }
}


export default connect(mapStateToProps, { getLandingDetail })(Landing)
