import React, { useMemo, Fragment, useState, useEffect } from "react";
import "../../App.scss";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import logo from "../../assets/powered_by_stripe.svg";
import ssl from "../../assets/ssls.svg";
import loader from "../../assets/loader.gif";
import { paymentComplete } from "../../actions/authActions";
import "./course.scss";
import "../profile/userProfile.scss";
import axios from "axios";
import { findByLabelText } from "@testing-library/react";
import { Message } from "rsuite";
const useOptions = () => {
  const fontSize = "18px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#B31818",
          "::selection": {
            backgroundColor: "#FAD9D9",
          },
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = ({
  paymentPartial,
  id,
  handlePaymentResponse,
  paymentComplete,
  handlePayError,
  updateCard
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isRequesting, setisRequesting] = useState(false);
  const [isCardValid, setisCardValid] = useState(false);
  const [isCVVvalid, setisCVVvalid] = useState(false);
  const [isExpiryValid, setisExpiryValid] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [cssCardValid, setCssCardValid] = useState(true);
  const [cssCvvValid, setCssCvvValid] = useState(true);
  const [cssExpiryValid, setCssExpiryValid] = useState(true);

  useEffect(() => {
    console.log("Props", paymentComplete);

    // window.addEventListener('keypress', (event) => {
    //   console.log("Press Event: ", event)
    // })

  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setisRequesting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        // Include any additional collected billing details.
        name: "Jenny Rosen",
      },
    });
    console.log("[PaymentMethod]", payload);
    stripePaymentMethodHandler(payload);
  };

  const stripePaymentMethodHandler = async (result) => {
    console.log("stripePaymentMethodHandler", result);

    if (result.error) {
      // Show error in payment form
      console.log("stripePaymentMethodHandler error", result.error);
    } else {
      // Otherwise send paymentMethod.id to your server (see Step 4)
      //   const res = await fetch('/student/pay', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({
      //       payment_method_id: result.paymentMethod.id,
      //     }),
      //   })
      let data;
      let url = "/api/student/pay/"
      if (updateCard) {
        url = "/api/student/update_card/"
        data = {
          payment_method_id: result.paymentMethod.id,
        }
      } else {
        data = {
          payment_method_id: result.paymentMethod.id,
          course: id,
          full_pay: !paymentPartial,
        }
      }
      axios
        .post(url, data)
        .then((paymentResponse) => {
          console.log(paymentResponse);
          handleServerResponse(paymentResponse, false);
        })
        .catch((err) => {
          console.log("ERR", err.response);
          handleServerResponse(err, true);
        });

      //   const paymentResponse = await res.json();

      //   // Handle server response (see Step 4)
    }
  };

  const handleServerResponse = async (response, status) => {
    console.log("handleServerResponse", response, response.status);

    if (response.error) {
      // Show error from server on payment form
    } else if (response.requires_action) {
      // Use Stripe.js to handle the required card action
      const {
        error: errorAction,
        paymentIntent,
      } = await stripe.handleCardAction(response.payment_intent_client_secret);

      if (errorAction) {
        // Show error from Stripe.js in payment form
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server

        axios
          .post("/api/student/pay/", {
            payment_intent_id: paymentIntent.id,
            course: id,
            full_pay: !paymentPartial,
          })
          .then((serverResponse) => {
            console.log("serverResponse", serverResponse);
            handleServerResponse("handleServerResponse", serverResponse);
            // paymentComplete()
          })
          .catch((err) => {
            console.log("serverResponse ERR", err);
            handleServerResponse("handleServerResponse ERR", err);
          });
      }
    } else {
      // Show success message
      console.log(
        "handleServerResponse--->>> SUCCESS",
        response,
        response.status,
        response.data,
        response.data
      );
      handlePaymentResponse(response, status);
      setisRequesting(false);
    }
  };

  const onCardNumber = (event) => {
    console.log("CardNumberElement [change]", event);
    if (event.complete && event.error == undefined) {
      setisCardValid(true);
    } else {
      setisCardValid(false);
    }
    if (event.error !== undefined) {
      setCssCardValid(false);
      handlePayError(event.error.message);
    } else {
      setCssCardValid(true);
      handlePayError();
    }
  };

  const onCVV = (event) => {
    console.log("CardNumberElement [change]", event);
    if (event.complete && event.error == undefined) {
      setisCVVvalid(true);
    } else {
      setisCVVvalid(false);
    }
    if (event.error !== undefined) {
      setCssCvvValid(false);
      handlePayError(event.error.message);
    } else {
      setCssCvvValid(true);
      handlePayError();
    }
  };

  const onExpiry = (event) => {
    console.log("CardNumberElement [change]", event);
    if (event.complete && event.error == undefined) {
      setisExpiryValid(true);
    } else {
      setisExpiryValid(false);
    }
    if (event.error !== undefined) {
      setCssExpiryValid(false);
      handlePayError(event.error.message);
    } else {
      setCssExpiryValid(true);
      handlePayError();
    }
  };


  return (
    <Fragment>
      {isRequesting && (
        <Fragment>
          <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
          {/* <div className="modal-backdrop show"></div> */}
          <div class="loader">
            <img src={loader} alt="loader" />
          </div>
        </Fragment>
      )}
      <form onSubmit={handleSubmit} id="payment-form" >
        <label>
          Card number
          <CardNumberElement
            options={options}
            className={!cssCardValid ? "not-valid" : ""}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              onCardNumber(event);
            }}

          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            options={options}
            className={!cssExpiryValid ? "not-valid" : ""}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              onExpiry(event);
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={options}
            className={!cssCvvValid ? "not-valid" : ""}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              onCVV(event);
            }}
          />
        </label>

        <div className="submit">
          {isCardValid && isCVVvalid && isExpiryValid ? (
            <button type="submit">{updateCard ? "Update Card" : "Submit payment"}</button>
          ) : (
              <button type="submit" className="disabled">
                {updateCard ? "Update Card" : "Submit payment"}
              </button>
            )}
        </div>
        <div className="paymet-partnerts">
          <img
            src={logo}
            className="logo"
            alt="logo niwe"
          // width="200"
          />
          <img
            src={ssl}
            className="logo"
            alt="logo niwe"
          // width="200"
          />
        </div>
      </form>
    </Fragment>
  );
};

export default connect(null, { paymentComplete })(SplitForm);
