import React from 'react'
import { Player, ControlBar, PlayToggle, Shortcut } from "video-react";
import { photos } from "../../contants/images"
import { Button } from "rsuite"
export default function Info({ file,info,title,type,showButton}) {
    return (
        <div className="info mt-4" >
            <div className="row" >
                <div className="col-md-6 col-sm-12">
                    {type=="video"?<Player poster={photos.poster} src={file} >
                        <ControlBar autoHide={false} disableDefaultControls={true}  >
                            <Shortcut clickable={false} />
                            <PlayToggle />
                        </ControlBar>
                    </Player>:
                    <div className="info__image" >
                    <img src={file} />
                    </div>
                    }
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="info__info-text" >
                        <h3>{title}</h3>
                        <p>{info}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
