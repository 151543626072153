import axios from "axios";
import { logoutUser } from "./actions/authActions";
import { toast } from "react-toastify";

export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        //catches if the session ended!
        if (error.response.status == 401) {
          if (!(window.location.pathname == "/login")) {
            toast.error("Session Timed Out!", {
              position: toast.POSITION.TOP_RIGHT,
            });

            localStorage.clear();
            setTimeout(() => {
              store.dispatch(logoutUser());
              window.location.href = "/login";
            }, 2000);
          } else {
            toast.error(error.response.data.detail, {
              position: toast.POSITION.TOP_RIGHT,
            });
            localStorage.clear();
          }
        } else if (error.response.status >= 500) {
          // toast.error("Server error", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
        return Promise.reject(error);
      }
    );
  },
};
