import React, { Component, Fragment } from "react";
import Navbar from "../shared/Navbar/navbar";
import coursePic from "../assets/course.PNG";
import axios from "axios";
import { connect } from "react-redux";
import { courseDetails } from "../actions/courseAction";
import loader from "../assets/loader.gif";
class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryData: [],
      active: 0,
      courses: [],
      search: "",
      isRequesting: true,
    };
  }

  componentDidMount() {
    axios
      .get("/api/course/category/")
      .then((res) => {
        let categories = res.data.map((el) => el.name);
        categories.unshift("All Courses");
        this.setState({ categories, categoryData: res.data });
      })
      .catch((err) => console.log("err", err));
    this.getAllCourses();
  }

  openCourse = (course) => {
    console.log("Adtya",course);
    this.setState({ isRequesting: true });
    this.props
      .courseDetails(course)
      .then((res) => {
        this.props.history.push("/coursedetails");
        this.setState({ isRequesting: false });
      })
      .catch((err) => {
        console.log("err in courseDetails actions", err);
      });
  };

  getAllCourses = (search) => {
    this.setState({ active: 0 });
    axios
      .get("/api/course/courselist/")
      .then((res) => {
        if (search !== undefined) {
          let filteredList = res.data.filter((el) => {
            return el.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
          });
          this.setState({ courses: filteredList, isRequesting: false });
        } else {
          this.setState({ courses: res.data, isRequesting: false });
        }
      })
      .catch((err) => console.log("err", err));
  };

  toggleCourse = (ID) => {
    this.setState({ isRequesting: true });
    if (ID > 0) {
      let categories = this.state.categoryData.slice();
      let category = categories[ID - 1].id;
      axios
        .get("/api/course/courselist/?category=" + category)
        .then((res) => {
          this.setState({ active: ID, courses: res.data, isRequesting: false });
        })
        .catch((err) => console.log("err", err));
    } else {
      this.getAllCourses();
    }
  };

  renderDummyCourses = () => {
    let courses = [1, 2, 3];
    return courses.map((course) => {
      return (
        <div class="card" >
          <div class="card-body">
            <img
              src={coursePic}
              alt="course-pic"
              height="300"
              style={{ width: "100%", borderRadius: "5px" }}
            />
            <h5 class="card-title">Course title</h5>
            <p class="card-text" style={{ visibility: "none" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              ornare nisi sed quam eleifend mattis.
              <span>Learn more</span>
            </p>
          </div>
        </div>
      );
    });
  };

  renderCourses = (courses) => {
    return courses.map((course,index) => {
      return (
        <div className="card" onClick={() => this.openCourse(course)} key={index} >
          <div className="card-body">
            <img
              src={course.course_logo!=null?course.course_logo:coursePic}
              alt="course-pic"
              height="300"
              style={{ width: "100%", borderRadius: "5px" }}
            />
            <h5 className="card-title caps">{course.name}</h5>
            <p className="card-text">{course.description}</p>
            <span className="learn-more">Learn more</span>
          </div>
        </div>
      );
    });
  };

  search = (e) => {
    this.setState({ search: e.target.value });
    this.getAllCourses(e.target.value);
  };

  render() {
    const { categories, courses, search, active, isRequesting } = this.state;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            {/* <div className="modal-backdrop in"></div> */}
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <div className="dashboard">
            <div className="welcome">
              Courses
              <div className="search-actions">
                <div className="search">
                  <input
                    className={`${search.length > 0 ? "search-text" : ""}`}
                    type="text"
                    id="username"
                    placeholder="Search for classes"
                    value={search}
                    onChange={this.search}
                  />
                </div>
              </div>
            </div>
            <Navbar
              navLinks={categories}
              activeNav={active}
              changeOver={this.toggleCourse}
            />
            <div class="x">
              {
                isRequesting ? <h1>Loading...</h1>:<Fragment>
                  {courses.length > 0 ? 
                 this.renderCourses(courses): <h1>Course coming soon!</h1>}
                </Fragment>
              }
           
            </div>
            <br />
            <br />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { courseDetails })(Courses);
