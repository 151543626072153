import React, { Component, Fragment } from "react";
import "../../App.scss";
import axios from "axios";
import { connect } from "react-redux";
import loader from "../../assets/loader.gif";
import { toast } from "react-toastify";

class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      userId: -1,
      isRequesting: true,
    };
  }
  componentDidMount() {
    axios.get("/api/student/student_info/").then((res) => {
      const { user } = res.data[0];
      this.setState({ userId: user, isRequesting: false });
    });
  }
  questionUpdate = (e) => {
    this.setState({ question: e.target.value });
  };
  submitPost = (e) => {
    let forum_type =
      this.props.courseDetails.forumDetails.forumName == "general"
        ? "general"
        : "course";
    let thread_course =
      this.props.courseDetails.forumDetails.forumName == "general"
        ? null
        : this.props.courseDetails.forumDetails.forumId;
    e.preventDefault();
    this.setState({ isRequesting: true });
    let obj = {
      user: this.state.userId,
      question_text: this.state.question,
      forum_type: forum_type,
      thread_course: thread_course,
    };
    axios
      .post("/api/forum/insertforum/", obj)
      .then((res) => this.props.history.push("/forum"))
      .catch((err) => {
        toast.error("Please try again Later");
        this.setState({ isRequesting: false });
      });
  };
  render() {
    const { forumName } = this.props.courseDetails.forumDetails;
    return (
      <Fragment>
        <div className="container">
          <div className="welcome">
            NiWE forums
            {/* <button className="hollow" onClick={()=>this.props.history.push('/forum')}>  <span>
                    <i className="fa fa-angle-left fa-sm"></i>
                  </span>Go Back</button> */}
          </div>

          <div className="course-name">{forumName}</div>
          <div className="write">Please write your question</div>

          <div className="text-container">
            Write your topic here
            <form onSubmit={this.submitPost}>
              <textarea
                placeholder="Begin writing your concern here…"
                value={this.state.question}
                onChange={this.questionUpdate}
                required
              ></textarea>
              <div>
                <button type="submit" style={{ marginRight: 20 }} >

                  Submit question
                  <span>
                    <i className="fa fa-angle-right fa-sm"></i>
                  </span>
                </button>
                <button
                  className="hollow"
                  onClick={() => this.props.history.push("/forum")}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, null)(AddQuestion);
