import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import "./quiz.scss";

class ExamResult extends Component {


  render() {
    // const { moduleDetails } = this.props.courseDetails;
    // const {
    //   result,
    //   score,
    //   total_score,
    // } = this.props.courseDetails.examResult;
    const name = this.props.courseDetails.examInstructions[0].name;

    return (
      <Fragment>
        <div className="container">
          <div className="quiz-instructions">
            <h1 className="result-heading">{name}   </h1>
            <p className="exam-info pb-3">
              Your Answers are Submitted. You will be notified of result when you instructor is done evaluating your exam
            </p>
            <button onClick={() => this.props.history.push("/dashboard")} >Go to Dashboard</button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps,)(
  ExamResult
);
