import React, { Component, Fragment } from "react";
import axios from "axios";
import "../App.scss";
import loader from "../assets/loader.gif";
import { toast } from "react-toastify";

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allNotifications: [],
      nextPage: 1,
      isRequesting: true,
    };
  }
  componentDidMount() {
    this.getNotifications(this.state.nextPage);
  }
  getNotifications(pageNo) {
    axios
      .get(`/api/notification/all_message/?page=${pageNo}`)
      .then((res) => {
        let allNotifications = this.state.allNotifications.slice();
        allNotifications = allNotifications.concat(res.data.results);

        let nextPage =
          res.data.next == null ? null : res.data.next.split("page=")[1];
        this.setState({ allNotifications, nextPage, isRequesting: false });
      })
      .catch((err) => {
        console.log("Notifications ******", err);
        this.setState({ isRequesting: false });
        toast.error(`Notifications Error${err}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  unread = (e) => {
    if (e.read_mark == false) {
      axios
        .patch(`api/notification/check_read/${e.id}/`, { read_mark: true })
        .then((res) => {
          let arr = this.state.allNotifications.slice();
          arr.forEach((el, index) => {
            if (el.id == e.id) {
              el.read_mark = true;
            }
          });
          this.setState({ allNotifications: arr });
        })
        .catch((err) => console.log("Mar as read failed"));
    }
  };

  goBack = () => {
    this.props.history.goBack();
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };
  render() {
    const { nextPage, allNotifications, isRequesting } = this.state;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <div className="welcome">
            Notifications{" "}
            <button className="hollow btn-md" onClick={this.goBack}>
              Go back
            </button>
          </div>
          {allNotifications.length == 0 && !isRequesting && (
            <h1>No Notifications</h1>
          )}
          {allNotifications.map((el, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between ",
                  alignItems: "center",
                }}
                className={`notify ${el.read_mark == false ? "unread" : ""}`}
              >
                {/* <h3>Notifications</h3> */}
                <div>
                  <div className="notify-header caps">{el.heading}</div>
                  <div className="notify-message">{el.message}</div>
                </div>
                {el.read_mark == false && (
                  <div>
                    <button
                      className="hollow btn-sm"
                      onClick={(e) => {
                        this.unread(el);
                      }}
                    >
                      Mark as Read
                    </button>
                  </div>
                )}
              </div>
            );
          })}
          {allNotifications.length > 0 &&
                <div className="load-more">
                {  nextPage == null
                  ? "Thats All we got"
                  : !isRequesting && (
                      <button
                        className="hollow btn-lg"
                        onClick={(e) => {
                          this.getNotifications(nextPage);
                        }}
                      >
                        Load more...
                      </button>
                    )}
              </div>
          }
    
        </div>
      </Fragment>
    );
  }
}
