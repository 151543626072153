import React, { useState } from "react";
import { Button, Input } from "rsuite";
import axios from "axios";
import loader from "../../assets/loader.gif";

export default function ReportIssue({ name, modalId, apiUrl, section }) {
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleSubmitIssue = () => {
    setLoading(true);
    axios
      .post(apiUrl, { issue, section })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Report Issue Err: ", err.response);
      });
  };

  const reset = () => {
    setData(null);
    setIssue("");
  };
  return (
    <div>
      <div
        className="modal enroll"
        tabIndex="-1"
        role="dialog"
        id={modalId}
        style={{ top: "0%" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button type="button" class="close" data-dismiss="modal" onClick={reset} >
              &times;
            </button>
            <div className="modal-body">
              {data == null ? (
                <>
                  <h4>{name}</h4>
                  <div className="input-box">
                    <Input
                      componentClass="textarea"
                      placeholder="Write your text here"
                      value={issue}
                      onChange={(v) => setIssue(v)}
                    />
                  </div>
                  <div className="modal-btn-box">
                    <Button
                      appearance="primary"
                      onClick={handleSubmitIssue}
                      disabled={issue == ""}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h4 className="text-center" style={{ fontSize: 20 }}>
                        Thank you for sharing your concern.
                    </h4>
                      <h5 className="text-center">
                        We are looking into it and will be in touch soon. Thanks!
                    </h5>
                      <div className="modal-btn-box">
                        <Button
                          appearance="primary"
                          class="close"
                          data-dismiss="modal"
                          onClick={reset}
                        >
                          Continue
                      </Button>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
