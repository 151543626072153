const logo = {
    logo: require('../assets/logo.png')
}

const photos = {
    banner: require('../assets/banner.jpg'),
    poster: require('../assets/poster.jpg'),
    certificate: require('../assets/1.jpg'),
    testimonials: require('../assets/testimonials.jpg'),
}


export {
    logo,
    photos
}