import React, { Component, Fragment } from "react";
import "./quiz.scss";
import { connect } from "react-redux";
import {
  submitQuiz,
  saveSingleAnswer,
  saveMultipleAnswer,
  resetAnswers,
  clearQuiz,
} from "../../actions/courseAction";
import { toast } from "react-toastify";

var x;
class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      answers: [],
      timer: 0,
      allAnswered: null,
      answerSubmited: false
    };
  }
  componentDidMount() {
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "#F4F4F4";
    document.getElementsByClassName("container")[1].style.backgroundColor =
      "#F4F4F4";
    alert(
      "Quiz has started. Do not refresh or navigate to other screen or else your quiz will be autosubmited or answers will be lost"
    );
    this.timmer();
    this.props.resetAnswers()
  }

  timmer = () => {
    // var y = setInterval(()=>{
    let timeOutAt = this.props.courseDetails.quizTimeoutTime;
    //   if()
    // })
    console.log(timeOutAt)
    if (timeOutAt == undefined) {
      setTimeout(() => {
        this.timmer();
      }, 1000);
    } else {
      x = setInterval(() => {
        let now = new Date().getTime();
        var distance = timeOutAt - now;
        var hours = Math.floor((distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Output the result in an element with id="demo"
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        if (distance < 0) {
          clearInterval(x);
          this.submitAnswers();
        }
        let timer = hours + ":" + minutes + ":" + seconds;
        if (hours < 0) {
          timer = minutes + ":" + seconds;
        }
        console.log("Timer: ", timer);
        this.setState({ timer: timer });
        // let timer = minutes + ":" + seconds;
        // this.setState({ timer: timer });
        // If the count down is over, write some text
      }, 1000);
    }
  };

  saveSingleAnswer = (e) => {
    this.props.saveSingleAnswer(e.target.name, e.target.id);
  };
  saveMultipleAnswer = (e) => {
    this.props.saveMultipleAnswer(e.target.name, e.target.id);
  };

  validateSubmitANswer = () => {
    let ans = this.props.courseDetails.quizAnswer.slice();
    let allAnswered = true;
    ans.forEach((el) => {
      if (el.answer[0].ans_id == null) {
        allAnswered = false;
      }
    });
    this.setState({ allAnswered });
  };

  submitAnswers = () => {
    if (this.state.answerSubmited) {
      return
    }
    this.setState({ answerSubmited: true })
    let attempt_quiz = this.props.courseDetails.attemptQuizId;
    let obj = {
      res: [...this.props.courseDetails.quizAnswer],
      attempt_quiz,
    };
    this.props.submitQuiz(obj)
      .then((res) => {
        this.props.history.push("/quizResult");
      })
      .catch((err) => toast.error("Access isnt Granted", {
        position: toast.POSITION.TOP_RIGHT,
      }));
  };

  closeSubmit = () => {
    this.setState({ allAnswered: false });
  };

  closeCheckModal = () => {
    this.setState({ allAnswered: null });
  };

  componentWillUnmount() {
    clearInterval(x);
    document.getElementsByClassName("container")[1].style.backgroundColor =
      "transparent";
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "transparent";
    this.submitAnswers();
    this.props.clearQuiz();
  }

  renderQuestions = (question, quesNo) => {
    if (question.correct_ans_no === 1) {
      return (
        <Fragment>
          <li className="question">{question.name}</li>
          <div className="options" onClick={this.saveSingleAnswer}>
            {question.answer.map((ans, index) => {
              return (
                <div className="radio-item">
                  <input
                    type="radio"
                    id={ans.id}
                    name={quesNo}
                    value={ans.name}
                  />
                  <label htmlFor={ans.id}> {String.fromCharCode(65 + index) + '. '} {ans.name}</label>
                </div>
              );
            })}
          </div>
          <br />

        </Fragment>
      );
    } else if (question.correct_ans_no > 1) {
      return (
        <Fragment>
          <li className="question">{question.name}</li>
          <div className="options" onClick={this.saveMultipleAnswer}>
            {question.answer.map((ans, index) => {
              return (
                <label
                  className="check-box-container"
                  id={ans.id}
                  name={quesNo}
                >
                  {String.fromCharCode(65 + index) + '. '} {ans.name}
                  <input type="checkbox" id={ans.id} name={quesNo} />
                  <span class="checkmark"></span>
                </label>
              );
            })}
          </div>

          <br />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <li className="question">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            ornare nisi sed quam eleifend mattis. Duis rutrum mi a magna ornare
            porta a ac orci. Nulla ac dapibus enim.?
          </li>
          <div className="options">
            <textarea placeholder="Begin writing your response here ..."></textarea>
          </div>
        </Fragment>
      );
    }
  };
  render() {
    let attempt;
    const questions = this.props.courseDetails.quizQuestions;
    const examHeading = this.props.courseDetails.moduleDetails.course;
    const { remain_attempts } = this.props.courseDetails.quizInstructions;
    var words = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    words.forEach((el, index) => {
      if (index == remain_attempts) {
        attempt = el;
      }
    });

    return (
      <Fragment>
        <div className="container quiz">
          <div className="attempt">You have {attempt} attempt(s)</div>
          <div className="quiz-header">
            <div className="exam-of">{examHeading} Quiz</div>
            <div className="time-remain">Time:{this.state.timer}</div>
          </div>
        </div>
        <div className="container questions">
          <ol>
            {questions.map((question, index) =>
              this.renderQuestions(question, index)
            )}
          </ol>
          <div>
            <button
              onClick={this.validateSubmitANswer}
              data-toggle="modal"
              data-target="#submitQuiz"
            >
              Submit
            </button>
          </div>
        </div>
        <div
          className="modal enroll"
          role="dialog"
          id="submitQuiz"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {this.state.allAnswered ? (
                  <p className="pb-3">
                    Are you sure you want to submit your quiz? You will not be
                    able to change your answers.
                  </p>
                ) : (
                  <p className="pb-3">
                    You have unanswered questions. All questions must have an
                    answer before submitting.
                  </p>
                )}
                <div className="actions">
                  {this.state.allAnswered ? (
                    <Fragment>
                      <button
                        className="hollow"
                        data-dismiss="modal"
                        onClick={this.closeSubmit}
                      >
                        Go back
                      </button>
                      <button
                        data-dismiss="modal"
                        onClick={this.submitAnswers}
                      >
                        Submit
                      </button>
                    </Fragment>
                  ) : (
                    <button
                      data-dismiss="modal"
                      onClick={this.closeCheckModal}
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, {
  saveMultipleAnswer,
  submitQuiz,
  resetAnswers,
  saveSingleAnswer,
  clearQuiz,
})(Quiz);
