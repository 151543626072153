import React, { Component, Fragment } from "react";
import "./quiz.scss";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { submitExam } from '../../actions/courseAction';
import axios from "axios";

var x;
class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      answers: [],
      timer: 0,
      course: "",
      answerSubmited: false
    };
  }
  componentDidMount() {
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "#F4F4F4";
    document.getElementsByClassName("container")[1].style.backgroundColor =
      "#F4F4F4";
    alert(
      "Exam has started. Do not refresh or navigate to other screen or else your exam will be autosubmited or answers will be lost"
    );
    if (this.props.courseDetails.examId == null) {
      this.props.history.push('/dashboard')
    }
    else {
      axios.get(`/api/exam/questions/?exam=${this.props.courseDetails.examInstructions[0].id}`).then((res) => {
        let answers = res.data.map((el) => {
          return {
            question: el.id,
            answer: "",
          };
        });

        this.setState({ course: this.props.courseDetails.examInstructions[0].course, questions: res.data, answers });
      });
      this.timmer();
    }

  }

  timmer = () => {
    // var y = setInterval(()=>{
    let timeOutAt = this.props.courseDetails.quizTimeoutTime;
    //   if()
    // })
    if (timeOutAt == undefined) {
      setTimeout(() => {
        this.timmer();
      }, 1000);
    } else {
      x = setInterval(() => {
        let now = new Date().getTime();
        var distance = timeOutAt - now;
        var hours = Math.floor((distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Output the result in an element with id="demo"
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        if (distance <= 0) {
          clearInterval(x);
          this.submitAnswers();
        }
        let timer = hours + ":" + minutes + ":" + seconds;
        if (hours < 0) {
          timer = minutes + ":" + seconds;
        }
        console.log("Timer: ", timer);
        this.setState({ timer: timer });
        // let timer = minutes + ":" + seconds;
        // this.setState({ timer: timer });
        // If the count down is over, write some text
      }, 1000);
    }
  };

  submitAnswers = () => {
    // return
    if (this.state.answerSubmited) {
      return;
    }
    this.setState({ answerSubmited: true });
    const obj = {
      attempt_exam: this.props.courseDetails.examId,
      answers: this.state.answers,
    };
    axios.post("/api/exam/submit/", obj).then((res) => {
      this.props.submitExam()
      this.props.history.push("/examResult");
    });
  };

  componentWillUnmount() {
    clearInterval(x);
    document.getElementsByClassName("container")[1].style.backgroundColor =
      "transparent";
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "transparent";
    this.submitAnswers();
  }

  saveAnswer = (e, question) => {
    let answers = this.state.answers.slice();

    if (question.question_type == 'Subjective') {
      answers.forEach((el, index) => {
        if (index == e.target.id) {
          el.answer = e.target.value;
        }
      });
    }
    if (e.question_type == 'MCQ') {

      answers.forEach((el, index) => {
        if (el.question == question.question) {
          el.answer = question.id
        }
      });
    }

    this.setState({ answers });
  };

  renderQuestions = (question, quesNo, answer) => {
    return (
      <Fragment>
        <li className="question">
          {question.name}
        </li>
        <div className="options">
          <textarea
            onChange={(e) => this.saveAnswer(e, question)}
            id={quesNo}
            name={question.id}
            value={answer}
            placeholder="Begin writing your response here ..."
          ></textarea>
        </div>
      </Fragment>
    );
  };

  renderMCQ = (question) => {
    return (
      <Fragment>
        <li className="question">{question.name}</li>
        <div className="options" >
          {question.answer.map((ans, index) => {
            return (
              <div className="radio-item" key={index}>
                <input
                  type="radio"
                  id={ans.id}
                  name={question.name}
                  value={ans.answer}
                  onClick={() => this.saveAnswer(question, ans)}
                />
                <label htmlFor={ans.id}> {String.fromCharCode(65 + index) + '. '} {ans.answer}</label>
              </div>
            );
          })}
        </div>
        <br />

      </Fragment>
    )
  }
  render() {
    // const questions = [1, 2, 3];
    const examHeading = this.props.courseDetails.moduleDetails.course;
    // const answers = ["", "", ""];
    const { questions, answers } = this.state
    return (
      <Fragment>
        <div className="container quiz">
          <div className="quiz-header">
            <div className="exam-of">{examHeading} Exam</div>
            <div className="time-remain">Time:{this.state.timer}</div>
          </div>
        </div>
        <div className="container questions">
          <ol>
            {questions.map((question, index) =>
              question.question_type == 'Subjective' ? this.renderQuestions(question, index, answers[index].answer)
                : this.renderMCQ(question)
            )}
          </ol>
          <div>
            <button data-toggle="modal" data-target="#submitQuiz">
              Submit
            </button>
          </div>
        </div>
        <div className="modal enroll" role="dialog" id="submitQuiz">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <p className="pb-3">
                  Are you sure you want to submit your Exam? You will not be
                  able to change your answers.
                </p>

                <div className="actions">
                  <button
                    className="hollow"
                    data-dismiss="modal"
                    onClick={this.closeSubmit}
                  >
                    Go back
                  </button>
                  <button data-dismiss="modal" onClick={this.submitAnswers}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, { submitExam })(Exam);
