import React from 'react'
import { photos } from '../../contants/images'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay]);

export default function Banner({ landing }) {
    return (
        <div className="landing-banner">
            {/* <img src={landing !== null ? landing.banner_link : photos.banner} />
            <div className="landing-banner__overlay">
                <div className="landing-banner__overlay--header" >
                    <h1>{landing !== null ? landing.banner_title : ""}</h1>
                </div>
            </div> */
            }
            <Swiper
                loop
                slidesPerView={1}
                autoplay
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination',
                    type: 'bullets',
                }}
            >
                <SwiperSlide>
                    <img src={landing!==null?landing.banner_link:photos.banner} />
                    <div className="landing-banner__overlay">
                <div className="landing-banner__overlay--header" >
                    <h1>{landing !== null ? landing.banner_title : ""}</h1>
                </div>
            </div>
                </SwiperSlide>
                <SwiperSlide>
                <img src={photos.certificate} />
                <div className="landing-banner__overlay">
                <div className="landing-banner__overlay--header" >
                    <h1>Industry Leading Certificate</h1>
                </div>
            </div> 
                </SwiperSlide>
                <SwiperSlide>
                <img src={photos.testimonials} />
                {/* <div className="landing-banner__overlay">
                <div className="landing-banner__overlay--header" >
                    <h1>Testimonial</h1>
                </div>
            </div>  */}
                </SwiperSlide>
            </Swiper>
        </div>
    )
}
