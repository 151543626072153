export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RESET_PROFILE = "RESET_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const GET_ALL_USERS = "GET_ALL_USERS";

export const COURSE_SELECTED = "COURSE_SELECTED";
export const MODULE_DETAILS = "MODULE_DETAILS";
export const MODULE_STATS = "MODULE_STATS";
export const LOAD_QUIZ_QUESTIONS = "LOAD_QUIZ_QUESTIONS";

export const START_LESSON = "START_LESSON";
export const CLEAR_STORE = "CLEAR_STORE";
export const SAVE_SINGLE_QUIZ_ANSWER = "SAVE_SINGLE_QUIZ_ANSWER";
export const SAVE_MULTIPLE_QUIZ_ANSWER = "SAVE_MULTIPLE_QUIZ_ANSWER";
export const SET_QUIZ_ID = "SET_QUIZ_ID";
export const START_QUIZ_TIMER = "START_QUIZ_TIMER";
export const RUN_QUIZ_TIMER = "RUN_QUIZ_TIMER";
export const ENROLL_COURSE = "ENROLL_COURSE";
export const START_COURSE = "START_COURSE";
export const CONTINUE_COURSE = "START_COURSE";
export const CHANGE_USER_COMPLETE = "CHANGE_USER_COMPLETE";
export const QUIZ_INSTRUCTIONS = "QUIZ_INSTRUCTIONS";
export const SUBMIT_QUIZ = "SUBMIT_QUIZ";
export const CLEAR_QUIZ = "CLEAR_QUIZ";
export const CHANGE_USER_COMPLETE_DEV = "CHANGE_USER_COMPLETE_DEV";
export const SET_FORUM_TYPE = "SET_FORUM_TYPE";

export const PAYMENT_COMPLETE = "PAYMENT_COMPLETE";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const RESET_ANSWERS = "RESET_ANSWERS";
export const EXAM_INSTRUCTIONS = "EXAM_INSTRUCTIONS";
export const START_EXAM_TIMMER = "START_EXAM_TIMMER"
export const CLEAR_EXAM_DATA = "CLEAR_EXAM_DATA";
export const ACTIVATE_EXAM_DATA = "ACTIVATE_EXAM_DATA";
export const CLEAR_EXAM_TIMER = "CLEAR_EXAM_TIMER";


export const GET_TOP_COURSES = "GET_TOP_COURSES"
export const GET_TOP_COURSES_START = 'GET_TOP_COURSES_START'
export const GET_TOP_COURSES_FINISH = 'GET_TOP_COURSES_FINISH'
export const GET_TOP_COURSES_SUCCESS = 'GET_TOP_COURSES_SUCCESS'
export const GET_TOP_COURSES_FAIL = 'GET_TOP_COURSES_FAIL'


export const GET_PUBLIC_COURSES = "GET_PUBLIC_COURSES"
export const GET_PUBLIC_COURSES_START = 'GET_PUBLIC_COURSES_START'
export const GET_PUBLIC_COURSES_FINISH = 'GET_PUBLIC_COURSES_FINISH'
export const GET_PUBLIC_COURSES_SUCCESS = 'GET_PUBLIC_COURSES_SUCCESS'
export const GET_PUBLIC_COURSES_FAIL = 'GET_PUBLIC_COURSES_FAIL'

export const GET_PUBLIC_COURSE_MODULE = "GET_PUBLIC_COURSE"
export const GET_PUBLIC_COURSE_MODULE_SUCCESS = 'GET_PUBLIC_COURSE_MODULE_SUCCESS'

export const GET_LANDING_DETAIL = "GET_LANDING_DETAIL";
export const GET_LANDING_DETAIL_SUCCESS = "GET_LANDING_DETAIL_SUCCESS"

export const UPLOAD_ASSIGNMENT_SUCCESS = "UPLOAD_ASSIGNMENT_SUCCESS"

export const UPLOAD_PERCENT = "UPLOAD_PERCENT"


export const CHECK_SAVED_CARD = "CHECK_SAVED_CARDS";

export const UPDATE_SELECTED_COURSE="UPDATE_SELECTED_COURSE"