import React from 'react'
import './verticalProgressBar.css'

const VerticalProgressBar = (props) => {
    var height;
    if (props.disable) {
        height = {
            height: "50%",
            backgroundColor: "#A8A8A8"
        }
    }
    else {
        height = {
            height: `${props.complete}%`
        }
    }
    return (
        <React.Fragment>

        <div className="progress progress-bar-vertical">
            <div className="progress-bar" role="progressbar" style={height}>
            </div>
        </div>
        </React.Fragment>
    )
}

export default VerticalProgressBar;