/** @format */

import React, { Component, Fragment } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VerticalProgressBar from "../shared/verticalProgressBar/verticalProgressBar";
import Navbar from "../shared/Navbar/navbar";
import axios from "axios";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Player,
  ControlBar,
  PlayToggle,
  Shortcut,
  ForwardControl,
} from "video-react";
import {
  courseDetails,
  moduleDetails,
  startCourse,
} from "../actions/courseAction";
import { paymentComplete } from "../actions/authActions";
import { getExamInstructs } from "../actions/courseAction";
import { connect } from "react-redux";
import loader from "../assets/loader.gif";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "./course/payments";
import tick from "../assets/tick.png";
import { Tooltip, Whisper, Badge, Button } from "rsuite";
import { RiErrorWarningLine } from "react-icons/all";
import moment from "moment";
import { isMobile } from "react-device-detect"
const stripePromise = loadStripe("pk_test_JG9f3af2QL0GFvhhpmP0qZRB00bSb6T6dE");

// const { player } = this.player.getState();
class Dashboard extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      enrolledCourses: [],
      navBar: [],
      moduleStats: [],
      courseName: "",
      courseDescription: "",
      module: 60,
      courseId: null,
      classCompletion: 0,
      current_module: null,
      student: "",
      introVideo: "",
      isRequesting: true,
      is_payment_complete: false,
      paymentSucess: false,
      status: null,
      registrationFees: 0,
      activeNav: 0,
      myCompletion: 0,
      myCompletion_text: "",
      class_comletion_text: "",
      is_started: null,
      payError: null,
      onTrack: false,
      stripeKey:null,
      trigger: []
    };
    this.newShortcuts = [
      {
        keyCode: 39, // Right arrow
        ctrl: false, // Ctrl/Cmd
        handle: (player, actions) => {
          if (!player.hasStarted) {
            return;
          }

          // this operation param is option
          // helps to display a bezel
          const operation = {
            action: "forward-0",
            source: "shortcut",
          };
          actions.forward(0, operation); // Go forward 0 seconds
        },
      },
    ];
  }

  handlePayError(error) {
    console.log("Pay Error: ", error);
    if (error != null) {
      this.setState({ payError: error });
    } else {
      this.setState({ payError: null });
    }
  }

  async componentDidMount() {
    // this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    let id;
    if (await localStorage.getItem("page") !== null && await localStorage.getItem("page") !== undefined) {
      id = localStorage.getItem("page");
    } else {
      localStorage.setItem("page", 0);
      id = 0;
    }
    let courseId;

    axios
      .get("/api/course/thresold_amount/")
      .then((res) =>
        this.setState({ 
          registrationFees: res.data.registation_fees,
          stripeKey:res.data.stripe_pub_key
        })
      )
      .catch((err) => console.log(err));
    axios
      .get("/api/student/enrolled_courses/")
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({ paymentSuccess: true });
          this.props.paymentComplete();
          console.log("Payment Success True");
        }
        let navBar = res.data.map((el) => el.course_name);
        courseId = res.data[0].course;
        this.setState({
          navBar,
          courseId,
          enrolledCourses: res.data,
          courseName: res.data[0].course_name,
          courseDescription: res.data[0].course_description,
          student: res.data[0].student,
          startDate: res.data[0].start_date,
          is_active: res.data[0].is_active,
        });

        if (localStorage.getItem("page")) {
          this.toggleUserCourse(localStorage.getItem("page"));
        } else {
          this.toggleUserCourse(courseId);
        }
      })
      .catch((err) => {
        this.setState({ isRequesting: false });
      });

    if (this.state.paymentSucess) {
    }

    if (isMobile) {
      this.setState({ trigger: ['focus', 'click', 'active'] })
    } else {
      this.setState({ trigger: ['hover', 'focus', 'click', 'active'] })
    }
  }

  // handleStateChange(state, prevState) {
  //   // copy player state to this component's state
  //   this.setState({
  //     player: state,
  //     currentTime: state.currentTime
  //   });
  // }

  startCourse = () => {
    let id = this.state.courseId;

    this.props
      .startCourse(id)
      .then((res) => {
        this.continueCourse();
      })
      .catch((err) => alert("startCourse", err));
  };

  continueCourse = () => {
    let { current_module } = this.state;
    localStorage.setItem("currentModule", current_module);
    let cm = localStorage.getItem("currentModule");
    this.props.moduleDetails(current_module).then((res) => {
      this.props.history.push({
        pathname: "/coursestart",
        state: { currentModule: cm },
      });
      }).catch((err) => {
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  toggleUserCourse = (ID) => {
    // localStorage.setItem('currentCourse',id)
    this.setState({ isRequesting: true, activeNav: parseInt(ID) });
    // debugger;
    localStorage.setItem("page", ID);
    console.log("ToggleUsercourseId: ", ID);
    console.table("Coure ID without slice: ", this.state.enrolledCourses);
    let courses = this.state.enrolledCourses.slice();
    console.log("Coure ID: ", courses);
    let id = courses[ID]?.course;
    console.log("IDDDDD: ", id);

    axios
      .post("/api/student/modulcompletion/", { course: id == undefined ? courses[0].course : id })
      .then((res) => {
        this.setState({
          current_module: res.data.current_module,
          moduleStats: res.data.module_progress,
          classCompletion: res.data.class_completion,
          myCompletion: res.data.my_completion,
          myCompletion_text: res.data.my_completion_text,
          moduleIndex: res.data.module_index,
          completeDate: res.data.to_complete_date,
          isRequesting: false,
          class_comletion_text: res.data.class_comletion_text,
          onTrack: res.data.track_status,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isRequesting: false });
      });
    console.log("IDddddddd: ", id == undefined ? courses[0].course : id)
    let ids = id === undefined ? courses[0].course : id
    axios
      .get("/api/course/detail_by_id/" + ids + "/")
      .then((res) => {
        console.log("Res", res);
        this.setState({ is_started: res.data.is_started });
        this.props.courseDetails(res.data).catch((err) => {
          console.log("err in courseDetails actions", err);
        });

        this.setState({
          introVideo: res.data.intro_video_link,
          status: res.data.status,
          coursePrice: res.data.price,
          is_all_module_complete: res.data.is_all_module_complete,
          is_exam_complete: res.data.is_exam_complete,
        });
        // debugger;
        console.log("Intro Video: ", this.state.introVideo);
      })
      .catch((err) => console.log("err", err));

    this.setState({
      courseName: courses[ID].course_name,
      courseDescription: courses[ID].course_description,
      startDate: courses[ID].start_date,
      courseId: id == undefined ? courses[0].course : id,
      is_active: courses[ID].is_active,
      status: courses[ID].status,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.introVideo !== null && this.state.introVideo == null) {
      console.log("Inside Compnenent did update");
      this.setState({ introVideo: "hello" });
    }
  }

  handlePaymentResponse = (response, fail) => {
    const { err } = response;
    console.log("Payment Response in Dashboard: ", response);
    if (fail) {
      toast.error("Payment Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (response.data.status == 500) {
        toast.error("Do not support this Card", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (response.data.status == 501) {
        toast.error("Insufficient Funds", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.setState({ paymentSucess: true });
        // this.afterPayment()
      }
    }
  };

  afterPayment = () => {
    this.setState({ paymentSucess: false });
    this.props.paymentComplete();
    window.location.reload();
  };

  gotToExam = async () => {
    await this.props
      .getExamInstructs(this.state.courseId)
      .then((res) => {
        this.props.history.push("/examInstructions");
      }).catch((err) => {
      let error = JSON.parse(err.response.request.response);
      this.setState({
        isRequesting: false,
        error:
          error.non_field_errors != undefined
            ? error.non_field_errors[0]
            : undefined,
      });
      toast.error(this.state.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };

  tooltip = (message) => {
    return (
      <Tooltip visible>
        <div>
          <h4>Message</h4>
          <p>Please provide this payment for access to this course.</p>
        </div>
      </Tooltip>
    );
  };

  render() {
    const {
      navBar,
      moduleStats,
      classCompletion,
      courseDescription,
      courseName,
      student,
      introVideo,
      startDate,
      is_active,
      isRequesting,
      paymentSucess,
      status,
      courseId,
      is_all_module_complete,
      is_exam_complete,
      registrationFees,
      coursePrice,
      completeDate,
      moduleIndex,
      activeNav,
      myCompletion,
      myCompletion_text,
      class_comletion_text,
      onTrack,
      stripeKey
    } = this.state;
    const {
      is_payment_complete,
      last_registered_course,
      course_price,
      course_name,
      thresold_amount,
      price,
    } = this.props.auth.profile;
    let partialPayment = thresold_amount < price;
    return (
      <Fragment>
        {is_payment_complete ? (
          <Fragment>
            {isRequesting && (
              <Fragment>
                <div
                  className="modal-backdrop in"
                  style={{ opacity: "0" }}
                ></div>
                {/* <div className="modal-backdrop show"></div> */}
                <div className="loader">
                  <img src={loader} alt="loader" />
                </div>
              </Fragment>
            )}
            <div className="container">
              <div className="dashboard">
                <div className="welcome">
                  <span>
                    Welcome back, <span className="caps">{student}</span>
                  </span>
                </div>
                <Navbar
                  navLinks={navBar}
                  heading={"Your Courses"}
                  activeNav={activeNav}
                  enrolledCourses={this.state.enrolledCourses}
                  changeOver={this.toggleUserCourse}
                />

                <div className="course-info">
                  <div className="left-half-video">
                    {/* <video
                     ef={ node => this.videoNode = node } className="video-js"
                      controlsList="nodownload noremoteplayback"
                      controls
                      type="video/mp4"
                      src={introVideo}
                    /> */}
                    <div className="dashboard-player">
                      <Player
                        src={this.state.introVideo}
                        height={350}
                        poster={require("../assets/poster.jpg")}
                      >
                        <ControlBar
                          autoHide={false}
                          disableDefaultControls={true}
                        >
                          <Shortcut
                            clickable={false}
                            shortcuts={this.newShortcuts}
                          />
                          <PlayToggle />
                        </ControlBar>
                      </Player>
                    </div>
                  </div>
                  <div className="right-half-summary">
                    <div className="subTitle caps">{courseName}</div>
                    <p className="subText">{courseDescription}</p>
                    <div className="action-btn containers">
                      {startDate ? (
                        <button
                          onClick={this.continueCourse}
                          className={
                            status !== "On Hold" && status !== "Stop"
                              ? ""
                              : "disabled"
                          }
                        >
                          {/* Continue course */}
                          Go to Course
                          <span>
                            <i className="fa fa-angle-right fa-sm"></i>
                          </span>
                        </button>
                      ) : (
                          <button
                            className={`${
                              is_active &&
                                status !== "On Hold" &&
                                status !== "Stop"
                                ? ""
                                : "disabled"
                              }`}
                            onClick={this.startCourse}
                          >
                            {/* Start this course */}
                            Go to Course
                            <span>
                              <i className="fa fa-angle-right fa-sm"></i>
                            </span>
                          </button>
                        )}
                      {is_all_module_complete && (
                        <Fragment>
                          {!is_exam_complete ? (
                            // <button onClick={this.gotToExam}>Go to exam</button>
                            <Button onClick={this.gotToExam} appearance="ghost">
                              Go to Exam
                            </Button>
                          ) : (
                              // <button

                              //   onClick={() =>
                              //     this.props.history.push(
                              //       "/viewResult/" + courseId
                              //     )
                              //   }
                              // >
                              //   View Result
                              // </button>
                              <Button
                                appearance="ghost"
                                onClick={() =>
                                  this.props.history.push(
                                    "/viewResult/" + courseId
                                  )
                                }
                              >
                                View Result
                              </Button>
                            )}
                        </Fragment>
                      )}
                      <div>
                        {!is_active &&
                          !isNaN(coursePrice - this.state.registrationFees) && (
                            <Whisper
                              trigger={this.state.trigger}
                              speaker={
                                <Tooltip visible>
                                  <div>
                                    <h4>Message</h4>
                                    <p>
                                      Oops! It looks like we are still waiting
                                      for your final payment of $
                                      {coursePrice -
                                        this.state.registrationFees}{" "}
                                      CAD. Please provide this payment for
                                      access to this course.
                                    </p>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <RiErrorWarningLine
                                size={24}
                                className="action-btn-info"
                              />
                            </Whisper>
                          )}

                        {status == "On Hold" || status == "Stop" ? (
                          <Whisper
                            trigger={this.state.trigger}
                            speaker={
                              <Tooltip visible>
                                <div>
                                  <h4>Message</h4>
                                  <p>
                                    Please Contact your Admin to start your
                                    course
                                  </p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <RiErrorWarningLine
                              size={24}
                              className="action-btn-info"
                            />
                          </Whisper>
                        ) : (
                            <div />
                          )}
                      </div>
                    </div>
                    {/* {!is_active && (
                      <p style={{ padding: " 5px 10px", fontSize: " 12px" }}>
                        Oops! It looks like we are still waiting for your final
                        payment of{" "}
                        <b>${coursePrice - this.state.registrationFees} CAD</b>.
                        Please provide this payment for access to this course.
                      </p>
                    )}
                    {status=='On Hold' || status=='Stop'?
                    (
                      <p style={{ padding: " 5px 10px", fontSize: " 12px" }} >Please Contact your Admin to start your course</p>
                    ):<div/>
                  } */}
                  </div>
                </div>

                <div className="user-course-stats">
                  <div className="class-info">
                    <div className="heading">Course engagement</div>
                    <div className="class-stats">
                      <div className="progress-meter">
                        <CircularProgressbar
                          className="circle-size"
                          counterClockwise={true}
                          value={myCompletion}
                          maxValue={100}
                          text={`${myCompletion}%`}
                          styles={buildStyles({
                            textColor: "#4D4D4D",
                            pathColor: "#4D4D4D",
                          })}
                        />
                      </div>
                      <div>
                        <div className="class-stats-title">My completion</div>
                        <div className="class-stats-summary">
                          {myCompletion_text != "" ? myCompletion_text : ""}
                        </div>
                      </div>
                    </div>
                    <div className="class-stats">
                      <div className="progress-meter">
                        <CircularProgressbar
                          className="circle-size"
                          counterClockwise={true}
                          value={classCompletion}
                          maxValue={100}
                          text={`${classCompletion}%`}
                          styles={buildStyles({
                            textColor: "#4D4D4D",
                            pathColor: "#4D4D4D",
                          })}
                        />
                      </div>
                      <div>
                        <div className="class-stats-title">
                          Class Completion
                        </div>
                        <div className="class-stats-summary">
                          {class_comletion_text != ""
                            ? class_comletion_text
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="module-info">
                    <div className="heading f-center">
                      Course tracking{" "}
                      {status !== "Enrolled" && <Badge
                        className="n-badge"
                        content={typeof(onTrack)=="boolean"?onTrack? "ON TRACK" : "OFF TRACK":onTrack}
                      />}
                    </div>
                    <div className="modules">
                      {moduleStats.map((module, index) => {
                        return (
                          <div className="module" key={index}>
                            <VerticalProgressBar
                              disable={module.progress === 0 ? true : false}
                              complete={module.progress}
                            />
                            <div className="module-name">
                              Module {index + 1}
                            </div>
                            <div className="module-completion ">
                              {module.progress !== 0
                                ? `${module.progress}%`
                                : "--"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {onTrack=="COMPLETED"?<p>Congratulations, you have completed the course!</p>:
                    this.state.is_started ? (
                      <p className="info">
                        Complete <b> Module {moduleIndex}</b> by{" "}
                        <b>
                          {completeDate
                            ? moment(completeDate).format("LL")
                            : ""}
                        </b>{" "}
                        to stay on track!
                      </p>
                    ) : (
                        <p className="info">
                          Start your course to get some statistics
                        </p>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
            <Fragment>
              {paymentSucess && (
                <div
                  className="modal enroll show"
                  tabIndex="-1"
                  role="dialog"
                  id="paymentModal"
                  style={{ top: "10%" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <h3>Payment was Successful !</h3>
                          </div>
                          <div style={{ marginBottom: "2rem" }}>
                            <img
                              src={tick}
                              width="150"
                              height="150"
                              alt="sucess"
                            />
                          </div>

                          <div>
                            <button
                              className="hollow"
                              onClick={this.afterPayment}
                            >
                              Continue to Dashboard
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="container">
                <div className="row">
                  <div className="col-md-7 m-auto">
                    <div className="payments">
                      {/* <h4>
                      The registration fee for this program is{" "}
                      {partialPayment
                        ? `$${registrationFees} CAD`
                        : course_price}
                      . You can make your payment here.
                    </h4> */}
                      {this.state.payError != null && (
                        <div className="payment-message">
                          <RiErrorWarningLine
                            size={20}
                            className="payment-msg-icon"
                          />
                          <p>
                            <span>Error.</span>
                            {this.state.payError}
                          </p>
                        </div>
                      )}
                      <br />
                      {partialPayment && (
                        <div
                          className="alert alert-warning"
                          role="alert"
                          style={{ fontSize: "14px" }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            ></i>{" "}
                          </span>
                        The registration fee for this program is
                          {partialPayment
                            ? ` $${registrationFees} CAD`
                            : course_price}
                        . You can make your payment here.
                        </div>
                      )}
                      <br />
                      <div className="mb-24">
                        <label htmlFor="course-name">Course Name</label>
                        <input
                          readOnly
                          type="text"
                          id="course-name"
                          value={course_name}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-24">
                        <label htmlFor="Fees">Course Fees</label>
                        <input
                          readOnly
                          type="text"
                          id="Fees"
                          value={course_price}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-24">
                        <label htmlFor="Fees">Amount To Pay</label>
                        <input
                          type="text"
                          id="Fees"
                          readOnly
                          value={
                            partialPayment
                              ? `$${registrationFees} CAD`
                              : course_price
                          }
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="stripe">
                       {stripeKey!==null&& <Elements stripe={loadStripe(stripeKey)}>
                          <CardForm
                            paymentPartial={partialPayment}
                            id={last_registered_course}
                            handlePaymentResponse={this.handlePaymentResponse}
                            handlePayError={(e) => this.handlePayError(e)}
                          />
                        </Elements>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
      </Fragment>
    );
  }
}

Shortcut.propTypes = {
  // Allow click to play/pause, by default true
  clickable: PropTypes.bool,
  // Allow double click to toggle fullscreen state, by default true
  dblclickable: PropTypes.bool,
  // Add your own shortcuts
  shortcuts: PropTypes.array,
  disableCompletely: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  paymentComplete,
  courseDetails,
  moduleDetails,
  startCourse,
  getExamInstructs,
})(Dashboard);
