/** @format */

import {
  COURSE_SELECTED,
  MODULE_DETAILS,
  MODULE_STATS,
  LOAD_QUIZ_QUESTIONS,
  CLEAR_STORE,
  SAVE_SINGLE_QUIZ_ANSWER,
  SET_QUIZ_ID,
  SAVE_MULTIPLE_QUIZ_ANSWER,
  ENROLL_COURSE,
  START_LESSON,
  START_COURSE,
  CHANGE_USER_COMPLETE,
  CHANGE_USER_COMPLETE_DEV,
  QUIZ_INSTRUCTIONS,
  SUBMIT_QUIZ,
  CLEAR_QUIZ,
  EXAM_INSTRUCTIONS,
  START_EXAM_TIMMER,
  RESET_ANSWERS,
  SET_FORUM_TYPE,
  CLEAR_EXAM_TIMER,
  CLEAR_EXAM_DATA,
  GET_TOP_COURSES_SUCCESS,
  GET_PUBLIC_COURSES_SUCCESS,
  GET_PUBLIC_COURSE_MODULE_SUCCESS,
  UPLOAD_PERCENT,
  UPDATE_SELECTED_COURSE
} from "../actions/types";

const initialState = {
  courseDetails: {},
  modules: [],
  current_module: 0,
  moduleDetails: {},
  quizQuestions: [],
  quizAnswer: [],
  quizResult: {},
  quizInstructions: {},
  quizTimeoutTime: null,
  attemptQuizId: null,
  timeRemaining: null,
  examInstructions: {},
  submitAssin: {},
  examId: null,
  forumDetails: { forumId: null },
  topCourses: [],
  publicCourses: [],
  uploadPercent: 0,
  selectedCourse:null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FORUM_TYPE:
      return {
        ...state,
        forumDetails: action.payload,
      };

    case COURSE_SELECTED:
      return {
        ...state,
        courseDetails: action.payload,
      };

    case MODULE_STATS:
      return {
        ...state,
        current_module: action.payload.current_module,
        modules: action.payload.modules,
      };
    case GET_PUBLIC_COURSE_MODULE_SUCCESS:
      return {
        ...state,
        modules: action.payload.module,
      }

    case MODULE_DETAILS:
      return {
        ...state,
        moduleDetails: action.payload,
      };

    case ENROLL_COURSE:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          is_enrolled: true,
          is_active: !action.payload,
        },
      };

    case START_COURSE:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          is_started: true,
        },
      };

    case START_LESSON:
      const { id, index } = action.payload;
      return {
        ...state,
        moduleDetails: {
          ...state.moduleDetails,
          current_lesson: { id, index },
        },
      };

    case CHANGE_USER_COMPLETE: {
      let newState = state.moduleDetails.lesson.slice();
      newState[action.payload - 1].is_user_complete = true;

      return {
        ...state,
        moduleDetails: {
          ...state.moduleDetails,
          submitAssign: action.payload.data,
          lesson: state.moduleDetails.lesson.map((el) => {
            if (el.id == action.payload) {
              el.is_user_complete = true;
            }
            return el;
          }),
        },
      };
    }

    case CHANGE_USER_COMPLETE_DEV: {
      let newState = state.moduleDetails.lesson.slice();
      newState[action.payload - 1].is_user_complete = true;
      if (action.payload === state.moduleDetails.lesson.length) {
        return {
          ...state,
          moduleDetails: {
            ...state.moduleDetails,
            check_all_lesson_complete: true,
            lesson: state.moduleDetails.lesson.map((el) => {
              if (el.id == action.payload) {
                el.is_user_complete = true;
              }
              return el;
            }),
          },
        };
      } else {
        return {
          ...state,
          moduleDetails: {
            ...state.moduleDetails,
            lesson: state.moduleDetails.lesson.map((el) => {
              if (el.id == action.payload) {
                el.is_user_complete = true;
              }
              return el;
            }),
          },
        };
      }
    }

    case QUIZ_INSTRUCTIONS:
      return {
        ...state,
        quizInstructions: action.payload,
      };

    case SET_QUIZ_ID:
      return {
        ...state,
        attemptQuizId: action.payload,
      };

    case LOAD_QUIZ_QUESTIONS:
      return {
        ...state,
        quizQuestions: action.payload,
        quizTimeoutTime:
          new Date().getTime() +
          parseInt(state.quizInstructions.time_allowed) * 60 * 1000 +
          2000,
        quizAnswer: action.payload.map((ques) => {
          return {
            question: ques.id,
            answer: [{ ans_id: null }],
          };
        }),
      };
    case RESET_ANSWERS:
      return {
        ...state,
        quizAnswer: !state.quizAnswer
          ? []
          : state.quizAnswer.map((ans) => {
            ans.answer = [{ ans_id: null }];
            return ans;
          }),
      };

    case SAVE_SINGLE_QUIZ_ANSWER:
      const { answerIndex, ansId } = action.payload;
      return {
        ...state,
        quizAnswer: state.quizAnswer.map((ans, index) => {
          if (index == answerIndex) {
            ans.answer[0].ans_id = ansId;
          }
          return ans;
        }),
      };

    case SAVE_MULTIPLE_QUIZ_ANSWER:
      const { ansIndex, answerId } = action.payload;
      return {
        ...state,
        quizAnswer: state.quizAnswer.map((ans, index) => {
          if (index == ansIndex) {
            if (ans.answer[0].ans_id == null) {
              ans.answer[0].ans_id = answerId;
            } else if (ans.answer.some((el) => el["ans_id"] == answerId)) {
              var indexOfans = ans.answer.findIndex(
                (i) => i.ans_id === answerId
              );
              if (ans.answer.length > 1) {
                ans.answer.splice(indexOfans, 1);
              } else {
                ans.answer[0].ans_id = null;
              }
            } else {
              ans.answer.push({ ans_id: answerId });
            }
          }
          return ans;
        }),
      };

    case SUBMIT_QUIZ:
      return {
        ...state,
        quizResult: action.payload,
      };

    case CLEAR_QUIZ:
      return {
        ...state,
        quizQuestions: [],
        quizAnswer: [],
        quizInstructions: {},
        quizTimeoutTime: null,
        attemptQuizId: null,
        timeRemaining: null,
        examInstructions: [],
      };

    case EXAM_INSTRUCTIONS:
      return {
        ...state,
        examInstructions: action.payload,
      };

    case START_EXAM_TIMMER:
      return {
        ...state,
        quizTimeoutTime:
          new Date().getTime() +
          parseInt(state.examInstructions[0].time_allowed) * 60 * 1000 +
          2000,
        examId: action.payload.attempt_exam,
      };
    case CLEAR_EXAM_TIMER: {
      return {
        ...state,
        quizTimeoutTime: 0,
        examId: null,
      };
    }

    case CLEAR_EXAM_DATA: {
      return {
        ...state,
        examInstructions: [],
      };
    }

    case CLEAR_STORE:
      return {
        courseDetails: {},
        modules: [],
        current_module: 0,
        moduleDetails: {},
        quizQuestions: [],
        quizAnswer: [],
      };

    case GET_TOP_COURSES_SUCCESS:
      return {
        ...state,
        topCourses: action.payload.data
      }

    case GET_PUBLIC_COURSES_SUCCESS:
      return {
        ...state,
        publicCourses: action.payload.data
      }
    case UPLOAD_PERCENT:
      return {
        ...state,
        uploadPercent: action.payload
      }

    case UPDATE_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse:action.payload
      }


    default:
      return state;
  }
}
