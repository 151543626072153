import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import logo from "../assets/logo.png";
import {ReactComponent as Bell} from "../assets/bell-icon.svg";
import {ReactComponent as Bells} from "../assets/bell-icons.svg";
import { Link } from "react-router-dom";
import {ReactComponent as forum} from "../assets/forum.svg";
import {ReactComponent as profile} from "../assets/forum.svg";
import {ReactComponent as Browse} from "../assets/search.svg";
import loader from "../assets/loader.gif";
import Notification from "react-web-notification";
import { logoutUser } from "../actions/authActions";
import history from "../helper/History";
import axios from "axios";
import { Modal } from "rsuite";
var interval;
var auditlogs;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      ignore: true,
      title: "",
      notification: [],
      unread: false,
      newNotification: false,
      loading: false,
    };
  }

  componentDidMount() {
    interval = setInterval(() => {
      axios
        .get("/api/notification/latest_message/")
        .then((res) => {
          let newNotification = false;
          res.data.forEach((el) => {
            if (el.read_mark == false) {
              newNotification = true;
              return;
            }
          });
          this.setState({ notification: res.data, newNotification });
        })
        .catch((err) => console.log("header notification ******", err));
    }, 15000);

    auditlogs = setInterval(() => {
      axios
        .get(`/api/student/track_time/?url=${window.location.href}`)
        .catch((err) => console.log(err));
    }, 600000);
    this.getNotify();
  }
  componentWillUnmount() {
    clearInterval(interval);
    clearInterval(auditlogs);
  }

  getNotify = () => {
    axios
      .get("/api/notification/latest_message/")
      .then((res) => {
        this.setState({ notification: res.data });
      })
      .catch((err) => console.log("header notification ******", err));
  };

  handleButtonClick() {
    if (this.state.ignore) {
      return;
    }

    const now = Date.now();

    const title = "React-Web-Notification" + now;
    const body = "Hello" + new Date();
    const tag = now;
    const icon =
      "http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png";

    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: "en",
      dir: "ltr",
    };
    this.setState({
      title: title,
      options: options,
    });
  }

  handleButtonClick2() {
    this.props.swRegistration
      .getNotifications({})
      .then(function (notifications) { });
  }

  onLogoutClick = () => {
    this.props.logoutUser();
  };

  toggleNotification = () => {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  unread = (e) => {
    if (e.read_mark == false) {
      this.setState({ loading: true });
      axios
        .patch(`/api/notification/check_read/${e.id}/`, { read_mark: true })
        .then((res) => {
          this.getNotify();
          let arr = this.state.notification.slice();
          arr.forEach((el, index) => {
            if (el.id == e.id) {
              el.read_mark = true;
            }
          });
          let newNotification = false;
          arr.forEach((el) => {
            if (el.read_mark == false) {
              newNotification = true;
              return;
            }
          });
          this.getNotify();
          this.setState({ notification: res.data, newNotification });
          this.setState({ loading: true });
        })
        .catch((err) => {
          this.setState({ loading: true });
          console.log("Mar as read failed");
        });
    }
  };
  render() {
    const path = this.props.location.pathname.split("/")[1];
    let showDashboard = true;
    if (
      path === "dashboard" ||
      path === "coursedetails" ||
      path === "forumQuestion"
    ) {
      showDashboard = false;
    }
    let nobutton = false;
    if (path === "dashboard") {
      nobutton = true;
    }
    let test = false;
    if (path === "quiz" || path === "exam") {
      test = true;
    }

    const { open, notification, unread, newNotification } = this.state;
    const { isAuthenticated } = this.props.auth;
    return (
      <Fragment>
        <div className="container">
          {/* <Modal show={true} > 
      <p>Hello</p>
      </Modal> */}
          <div className="header">
            <div className="left-half">
              <img
                src={logo}
                className="logo"
                alt="logo niwe"
                width="71"
                onClick={() => this.props.history.push("/dashboard")}
                height="35"
              />
              {/* <div>
                <button onClick={this.handleButtonClick.bind(this)}>
                  Notify
                </button>
                {document.title === "swExample" && (
                  <button onClick={this.handleButtonClick2.bind(this)}>
                    swRegistration.getNotifications
                  </button>
                )}
                <Notification
                  ignore={this.state.ignore && this.state.title !== ""}
                  notSupported={this.handleNotSupported.bind(this)}
                  onPermissionGranted={this.handlePermissionGranted.bind(this)}
                  onPermissionDenied={this.handlePermissionDenied.bind(this)}
                  onShow={this.handleNotificationOnShow.bind(this)}
                  onClick={this.handleNotificationOnClick.bind(this)}
                  onClose={this.handleNotificationOnClose.bind(this)}
                  onError={this.handleNotificationOnError.bind(this)}
                  timeout={5000}
                  title={this.state.title}
                  options={this.state.options}
                  swRegistration={this.props.swRegistration}
                />
              </div> */}
            </div>
            {isAuthenticated && !test && (
              <div className="right-half">
                {!nobutton ? (
                  showDashboard ? (
                    <Link to="/dashboard">
                      <div className="nav-dashboard">
                        <span>
                          <i className="fa fa-angle-left fa-sm"></i>
                        </span>{" "}
                        Dashboard
                      </div>
                    </Link>
                  ) : (
                      <button
                        style={{
                          position: "relative",
                          top: "8px",
                          right: "10px",
                        }}
                        className="hollow"
                        onClick={() => this.props.history.goBack()}
                      >
                        {" "}
                        <span>
                          <i className="fa fa-angle-left fa-sm"></i>
                        </span>
                      Go back
                      </button>
                    )
                ) : null}
                <div
                  className="dropdown"
                  style={{ cursor: "pointer", maxWidth: "auto" }}
                >
                  {newNotification ? (
                     <Bells 
                     alt="notification" 
                     width="22"
                       height="26"
                       data-toggle="dropdown"
                     className="bell stroke" />
                    // <img
                    //   src={bells}
                    //   className="bell"
                    //   alt="notification"
                    //   width="22"
                    //   height="26"
                    //   data-toggle="dropdown"
                    // />
                  ) : (
                    <Bell 
                    alt="notification" 
                    width="22"
                      height="26"
                      data-toggle="dropdown"
                    className="bell stroke" />

                      // <img
                      //   src={Bell}
                      //   className="bell"
                      //   alt="notification"
                      //   width="22"
                      //   height="26"
                      //   data-toggle="dropdown"
                      // />
                    )}
                  <ul className="dropdown-menu dropdown-menu notification">
                    {notification.length == 0 && (
                      <li className="all-notification">No New Notification</li>
                    )}
                    {notification.length > 0 &&
                      notification.map((el, idx) => (
                        <Fragment key={idx} >
                          <>
                            <li
                              className={`notify ${
                                el.read_mark == false ? "unread" : ""
                                }`}
                              onClick={(e) => {
                                this.unread(el);
                              }}
                            >
                              <div className="notify-header">
                                {el.heading}
                              </div>
                              <div className="notify-message">{el.message}</div>
                            </li>
                          </>
                        </Fragment>
                      ))}

                    <li className="all-notification">
                      <a href="/mynotifications">See All</a>
                    </li>
                  </ul>
                </div>

                <div className="dropdown">
                  <div id="nav-icon4" data-toggle="dropdown">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>

                  <ul className="dropdown-menu dropdown-menu-right">
                    <li>
                      <a href="/profile">
                        <i
                          className="fa fa-address-card-o"
                          
                        ></i>
                        <span>Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="/forum">
                        <i
                          className="fa fa-comments"
                          
                        ></i>
                        <span>Forum</span>
                      </a>
                    </li>
                    <li>
                      <a href="/browse">
                      <Browse 
                     alt="browse" 
                     className="stroke" />
                        {/* <img src={browse} alt="browse" /> */}
                        <span>Browse Courses</span>
                      </a>
                    </li>
                    <li onClick={this.onLogoutClick}>
                      <a href="/login">
                        <i
                          className="fa fa-sign-out"
                          
                        ></i>
                        <span>Log Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Header);
