import React, { Component, Fragment } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { toast } from "react-toastify";


class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      error: "",
      disabled: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({ error: "Please enter a email " })
      return;
    }

    const userData = {
      email: this.state.email,
    };
    axios
      .post("/api/password_reset/", userData)
      .then((res) => {
        this.setState({
          error:
            "We have sent you a password reset link on your email if this email is registered.",
          disabled: true
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        let errors = JSON.parse(err.response.request.response)
        console.log("Reset Error: ", errors.email[0])
        toast.error(errors.email[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          error: errors.email[0],
        });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.disabled == false && this.state.disabled == true) {
      setTimeout(() => {
        this.setState({ disabled: false })
      }, 30000)
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { error, isRequesting, email } = this.state;
    return (
      <Fragment>
        <div className="login">
          <div className="container" style={{ textAlign: "center" }}>
            {/* {error && <div className="error">{error}</div>} */}
            <div className="login__box_password m-auto">
              <div className=" row">
                <div className=" m-auto">
                  <img
                    src={logo}
                    className="logo"
                    alt="logo niwe"
                    width="150"
                    height="80"
                    style={{ marginBottom: "2.5rem" }}
                  />
                  <br />
                  <p className="lead text-center">Forgot Password? Enter your email to receive password reset link in your inbox</p>
                  <form onSubmit={this.onSubmit}>
                    <TextFieldGroup
                      placeholder="Email "
                      name="email"
                      type="email"
                      value={email}
                      onChange={this.onChange}
                    />
                    <div className="signIn">
                      <button type="submit" className={this.state.disabled ? "disabled" : undefined}  >
                        Request reset link
                      </button>
                    </div>
                  </form>

                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      marginRight: "20px",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Link
                      className="nav-link themeclr"
                      to="/login"
                      style={{

                        backgroundColor: "transparent",
                        textDecoration: "underline",
                      }}
                    >
                      Login
                    </Link>
                  </div>
                {/* new 2023    */}
                <div className="lead" style={{
                  textAlign: 'center'
                }} >
                  <div className="lead" style={{
                    borderTop: "2px solid #bbb",
                    borderRadius: "5px"
                  }} />
                  <div
                    style={{
                      fontSize: "14px",
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ marginTop: '1.1rem', marginRight: '1rem' }}>
                      Registered in year 2023?
                    </p>
                    <button
                      className="hollow"
                      style={{ fontSize: 14 }}
                      onClick={() => window.open("https://niwe.eduley.com/", '_self')}
                    >
                      Please login to New Portal
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ForgotPassword;
