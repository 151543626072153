import React, { Component, Fragment } from "react";
import Navbar from "../../shared/Navbar/navbar";
import axios from "axios";
import logo from "../../img/showcase.jpg";
import "../../App.scss";
import "./forum.scss";
import { connect } from "react-redux";
import { setForumType } from "../../actions/courseAction";
import loader from "../../assets/loader.gif";
import { toast } from "react-toastify";

class Forum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forumType: "general",
      navBar: [],
      forumQuestions: [],
      enrolledCourses: [],
      search: "",
      courseName: "",
      searchResult: [],
      isRequesting: true,
      activeNav: 0,
    };
  }

  componentDidMount() {
    axios
      .get("/api/student/enrolled_courses/")
      .then((res) => {
        let navBar = res.data.map((el) => el.course_name);
        navBar.unshift("General");
        this.setState({
          navBar,
          enrolledCourses: res.data,
        });
        console.log("Enrolled Courses")
        if (this.props.courseDetails.forumDetails) {
          const { forumId } = this.props.courseDetails.forumDetails;
          if (forumId) {
            this.toggleCourse(1, forumId);
          } else {
            this.toggleCourse(0);
          }
        } else {
          this.toggleCourse(0);
        }
      })
      .catch((err) => console.log("err", err));
  }
  toggleCourse = (ID, courseid) => {
    this.setState({ isRequesting: true });
    if (ID > 0) {
      let courses, courseId, courseName, activeNav;
      if (courseid) {
        courses = this.state.enrolledCourses.slice();
        courseId = courseid;
        courses.forEach((el, index) => {
          if (el.course == courseId) {
            courseName = el.course_name;
            activeNav = index + 1;
          }
        });
      } else {
        courses = this.state.enrolledCourses.slice();
        courseId = courses[ID - 1].course;
        activeNav = ID;
        courseName = courses[ID - 1].course_name;
      }

      axios
        .get("/api/forum/forumdata/?type=course&id=" + courseId)
        .then((res) => {
          this.setState({
            forumType: courseName,
            forumQuestions: res.data,
            isRequesting: false,
            activeNav,
          });
          this.props.setForumType(courseName, courseId);
        })
        .catch((err) => {
          toast.error("Error fetching the data", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ isRequesting: false });
        });
    } else {
      axios
        .get("/api/forum/forumdata/?type=general")
        .then((res) => {
          this.setState({
            forumType: "general",
            forumQuestions: res.data,
            isRequesting: false,
            activeNav: 0,
          });
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Error fetching the data", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ isRequesting: false });
        });
      this.props.setForumType("general", null);
    }
  };

  gotoQuestion = (id) => {
    this.props.history.push("/forumQuestion/" + id);
  };

  search = (e) => {
    this.setState({ search: e.target.value });
    axios.get("/api/forum/forumdata/?search=" + e.target.value).then((res) => {
      this.setState({ searchResult: res.data });
    });
  };
  render() {
    const {
      navBar,
      forumQuestions,
      search,
      activeNav,
      searchResult,
      isRequesting,
    } = this.state;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <div className="welcome">
            NiWE forums
            <div className="forum-actions">
              <div
                className="add-question"
                data-toggle="modal"
                data-target="#addQuestion"
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
                question
              </div>
              <div className="search">
                <input
                  className={`${search.length > 0 ? "search-text" : ""}`}
                  type="text"
                  id="username"
                  placeholder="Search for topic"
                  value={search}
                  onChange={this.search}
                />
              </div>
            </div>
          </div>
          {search == "" ? null : (
            <div className="question">
              {searchResult.length == 0 ? (
                <h3>No result Available</h3>
              ) : (
                searchResult.map((ques) => {
                  let createdAt = ques.created_date
                    .split(".")[0]
                    .replace("T", " ");
                  return (
                    <Fragment>
                      <div
                        className="forum-post course"
                        onClick={() => this.gotoQuestion(ques.id)}
                      >
                        <div className="lefthalf">
                          <div className="profile-pic">
                            <img
                              src={ques.user_image?ques.user_image:logo}
                              alt="profile-pic"
                              width="80"
                              height="80"
                            />
                          </div>
                          <div>
                            <div className="date">{createdAt}</div>
                            <div className="name">
                              {ques.first_name} {ques.last_name}
                            </div>
                            <div className="question">{ques.question_text}</div>
                          </div>
                        </div>
                        <div className="lefthalf">
                          <div className="reply">
                            {ques.total_reply == 0 ? "NO" : ques.total_reply}{" "}
                            REPLIES
                          </div>
                          <div className="icon">
                            <i className="fa fa-angle-right fa-2x"></i>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              )}
            </div>
          )}

          <Navbar
            navLinks={navBar}
            activeNav={activeNav}
            changeOver={this.toggleCourse}
          />
          <div className="question">
            {forumQuestions.length == 0 ? (
              <Fragment>
                <h3>No Questions Available.</h3>
                <br />
                <br />
                <button onClick={() => this.props.history.push("/askQuestion")}>
                  Add Question
                </button>
              </Fragment>
            ) : null}
            {forumQuestions.map((ques) => {
              let createdAt = ques.created_date.split(".")[0].replace("T", " ");
              return (
                <Fragment>
                  <div
                    className={`forum-post ${
                      this.state.forumType == "general" ? " general" : ""
                    }`}
                    onClick={() => this.gotoQuestion(ques.id)}
                  >
                    <div className="lefthalf">
                      <div className="profile-pic">
                        <img
                          src={ques.user_image?ques.user_image:require('../../assets/avatar.png')}
                          alt="profile-pic"
                          width="80"
                          height="80"
                        />
                      </div>
                      <div>
                        <div className="date">{createdAt}</div>
                        <div className="name">
                          {ques.first_name} {ques.last_name}
                        </div>
                        <div className="question">{ques.question_text}</div>
                      </div>
                    </div>
                    <div className="lefthalf">
                      <div className="reply">
                        {ques.total_reply == 0 ? "NO" : ques.total_reply}{" "}
                        REPLIES
                      </div>
                      <div className="icon">
                        <i className="fa fa-angle-right fa-2x"></i>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>

        {
          <div
            className="modal addQuestion"
            tabindex="-1"
            role="dialog"
            id="addQuestion"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="modal-body">
                  <p>
                    Before writing, we strongly suggest that you search your
                    topic to avoid repeated questions.
                  </p>
                  <div className="actions">
                    <button className="hollow" data-dismiss="modal">
                      Search
                    </button>
                    <button
                      data-dismiss="modal"
                      onClick={() => this.props.history.push("/askQuestion")}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, { setForumType })(Forum);
