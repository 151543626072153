import React, { useState, Fragment } from "react";
import ReportIssue from "./ReportIssue";
import {
  AiOutlineInstagram,
  RiFacebookCircleFill,
  GrTwitter,
  SiYoutube,
} from "react-icons/all";
import { Button, Input } from "rsuite";
import axios from "axios";
import loader from "../../assets/loader.gif";
import { connect } from "react-redux"
import history from "../../helper/History"
function Footer({ isAuthenticated }) {
  // const [issue,setIssue]=useState('')
  // const [loading,setLoading]=useState(false)
  // const [data,setData]=useState(null)

  // const handleSubmitIssue=()=>{
  //     setLoading(true)
  //     axios.post('/api/student/report/',{issue})
  //     .then((res)=>{
  //         setData(res.data)
  //         setLoading(false)
  //     })
  //     .catch((err)=>{
  //         console.log("Report Issue Err: ",err.response)
  //     })
  // }

  const navigate = () => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }

  return (
    <div className="footer-container">
      {/* {loading&& (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            <div className="modal-backdrop show"></div>
            <div class="loader">
              <img src={loader} alt="loader" />
            </div> 
          </Fragment>
        )} */}
      <div className="container" style={{ padding: 0 }} >
        <div className="footer-grid">
          <div>
            <div>
              <img src={require("../../assets/logo-white.png")} />
            </div>
            <p>We believe in empowering creative careers</p>
            <p>© 2017-{new Date().getFullYear()}</p>
          </div>
          <div>
            <h4>Further Information</h4>
            <p>niwe.ca</p>
            {/* <a
              href="https://www.google.com/maps/place/NIWE+Academy/@51.087056,-113.9973969,17z/data=!3m1!4b1!4m5!3m4!1s0x537164eec9637fbd:0x3a24ba3fb510512f!8m2!3d51.087056!4d-113.9952081"
              target="_blank"
            >
              <p>NIWE Academy 3817 27th St NE Calgary AB T1Y 7G2</p>
            </a> */}
            <a href="tel:+1 (403) 453 8200">
              <p>+1 (403) 453 8200</p>
            </a>
            <ul style={{ marginTop: 15 }}>
              <li onClick={() => history.push('/terms')}>
                <a href="#">Terms of Use</a>
              </li>
            </ul>
            <div className="border-line" />
          </div>
          <div>
            <h4 style={{ display: "none" }} >Further Information</h4>

            <h4>Follow Us</h4>
            <div className="social-container">
              <a href="https://www.instagram.com/niweacademy" target="_blank">
                <AiOutlineInstagram className="icons" />
              </a>
              <a
                href="https://www.facebook.com/NIWEAcademy/?ref=bookmarks"
                target="_blank"
              >
                <RiFacebookCircleFill className="icons" />
              </a>
              <a href="https://twitter.com/niwe_academy" target="_blank">
                <GrTwitter className="icons" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVOT1o3CzQy9Up1oo6UzsHQ"
                target="_blank"
              >
                <SiYoutube className="icons" />
              </a>
            </div>
            <div className="border-line" />
          </div>
          <div className="help-box">
            <h4>Help Center</h4>
            <p>
              Having issues with the system? Want to report a bug? Let us know.
            </p>
            <div>
              {isAuthenticated ? <Button
                appearance="primary"
                block
                size="lg"
                data-toggle={"modal"}
                data-target={"#reportIssue"}

              >
                Report an Issue
              </Button> :
                <Button onClick={navigate} appearance='primary' block size="lg" >
                  Report an Issue
                </Button>}
            </div>
          </div>
        </div>
      </div>
      <ReportIssue
        name="Report Issue"
        modalId="reportIssue"
        apiUrl="/api/student/report/"
        section="Footer"
      />
    </div>
  );
}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, null)(Footer)