import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  getQuizInstructs,
  moduleDetails,
  activateExams,
  getExamInstructs,
} from "../../actions/courseAction";
import "./quiz.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { options } from "less";
import { findDOMNode } from "react-dom";
import { Modal } from "rsuite";
// import $ from "jquery"

class QuizResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };
  }

  startQuiz = async () => {
    await this.props
      .getQuizInstructs(this.props.courseDetails.moduleDetails.quiz)
      .then((res) => {
        this.props.history.push("/quizInstructions");
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  enableExam = () => {
    this.props.activateExams();
  };

  nextModule = () => {
    localStorage.setItem(
      "currentModule",
      this.props.courseDetails.moduleDetails.next_module.id
    );
    this.props
      .moduleDetails(this.props.courseDetails.moduleDetails.next_module.id)
      .then((res) => {
        this.props.history.push("/coursestart");
      }).catch((err) => {
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  prevModule = () => {
    this.props.history.push("/coursestart");
  };

  restartModule = () => {
    let {
      id,
      current_index,
      subscription,
    } = this.props.courseDetails.moduleDetails;
    axios
      .post("/api/student/reset_module/", {
        module: id,
        subscription,
        current_index,
      })
      .then((res) => {
        let { id } = this.props.courseDetails.moduleDetails;
        this.props.moduleDetails(id).then((res) => {
          this.props.history.push("/coursestart");
        });
      })
      .catch((err) =>
        toast.error(`Module reset failed ${err}`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };

  gotToExam = async () => {
    await this.props
      .getExamInstructs(this.props.courseDetails.moduleDetails.course_id)
      .then((res) => {
        this.props.history.push("/examInstructions");
      })
      .catch((err) => {
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    // if(prevProps.courseDetails.quizResult.result){
    //   const modal = document.querySelector('#module-popup').modal(options)
    //   modal();
    // }
  }

  componentDidMount() {
    // if(this.props.courseDetails.quizResult.result){
    //   const modal = document.querySelector('#module-popup')
    //   modal.modal();
    // }
    if (this.props.courseDetails.quizResult.result) {
      this.setState({ modal: true });
      // const modal = document.querySelector('#module-popup')
      // modal().modal()
      // $('#module-popup').modal('show')
    }
  }

  render() {
    let attempt;
    const {
      enable_exam,
      quiz_name,
      result,
      score,
      total_score,
      remain_attempts,
      minimum_percent,
    } = this.props.courseDetails.quizResult;
    var words = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    words.forEach((el, index) => {
      if (index == remain_attempts) {
        attempt = el;
      }
    });
    return (
      <Fragment>
        <div className="container">
          <div className="quiz-instructions">
            <h1 className="result-heading caps">{quiz_name}</h1>
            <p className="exam-info">
              <div>
                Your Score:
                <b>
                  {score}/{total_score}
                </b>
              </div>
              {result ? (
                <Fragment>
                  Congratulations! You have passed the quiz. You may now
                  continue on to the next module. If you wish to review your
                  grades with the instructor, please contact the administration
                  office.
                </Fragment>
              ) : (
                <Fragment>
                  You have failed the quiz. You did not achieve the minimum{" "}
                  {`${minimum_percent}% `}
                  required to pass on to the next module. Please try again.
                </Fragment>
              )}
            </p>
            {!result && (
              <h4 className="attempt-details">
                You have {attempt} attempt(s) left
              </h4>
            )}
            {enable_exam ? (
              <button onClick={this.gotToExam}>Go to Exam</button>
            ) : (
              <Fragment>
                {!result && remain_attempts == 0 ? (
                  <button onClick={this.restartModule}>
                    Restart Module
                    <span>
                      <i className="fa fa-angle-right fa-sm"></i>
                    </span>
                  </button>
                ) : (
                  <Fragment>
                    <button
                      className="hollow"
                      onClick={this.prevModule}
                      style={{ marginRight: 10 }}
                    >
                      <span>
                        <i className="fa fa-angle-left fa-sm"></i>
                      </span>
                      Previous module
                    </button>
                    {!result && remain_attempts > 0 && (
                      <button onClick={this.startQuiz}>
                        Re-try quiz
                        <span>
                          <i className="fa fa-angle-right fa-sm"></i>
                        </span>
                      </button>
                    )}
                  </Fragment>
                )}

                {result &&
                  this.props.courseDetails.moduleDetails.next_module.id && (
                    <button onClick={this.nextModule}>
                      Next module
                      <span>
                        <i className="fa fa-angle-right fa-sm"></i>
                      </span>
                    </button>
                  )}
              </Fragment>
            )}
          </div>
          <Modal show={this.state.modal}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={require("../../assets/badge.svg")}
                style={{ height: 80 }}
              />
              <div className="py-2" style={{ fontSize: 22, fontFamily: 500 }}>
                Module Complete!
              </div>
              <div className="py-3">
                <button
                  style={{ margin: 0 }}
                  onClick={() => this.setState({ modal: false })}
                >
                  Continue
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, {
  getQuizInstructs,
  moduleDetails,
  activateExams,
  getExamInstructs,
})(QuizResult);
