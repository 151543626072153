import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { startQuiz,moduleDetails } from "../../actions/courseAction";
import { toast } from "react-toastify";
import axios from "axios";

class QuizDescription extends Component {
  startQuiz = (quizId, moduleID) => {
    this.props.startQuiz(quizId, moduleID);
    this.props.history.push("/quiz");
  };

  prevModule = () => {
    this.props.history.push("/coursestart");
};

restartModule = () =>{
  let { id ,current_index ,subscription} = this.props.courseDetails.moduleDetails;
  axios.post('/api/student/reset_module/',{
    module:id,
    subscription,
    current_index
  }).then((res)=>{
    let { id } = this.props.courseDetails.moduleDetails;
    this.props.moduleDetails(id).then((res) => {
      this.props.history.push("/coursestart");
    });
  })
  .catch((err)=> toast.error(`Module reset failed ${err}`, {
    position: toast.POSITION.TOP_RIGHT,
  }))
}
  render() {
    let attempt;
    const { quiz, id } = this.props.courseDetails.moduleDetails;
    const {
      name,
      remain_attempts,
      description,
    } = this.props.courseDetails.quizInstructions;
    var words = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    words.forEach((el, index) => {
      if (index == remain_attempts) {
        attempt = el;
      }
    });
    return (
      <Fragment>
        <div className="container">
          <div className="quiz-instructions">
            <h1 className="exam-heading">{name}</h1>
            <p className="exam-info">{description}</p>
            <h4 className="attempt-details">
              You have {attempt} attempt(s)
            </h4>
            {
              remain_attempts > 0 ? <Fragment>
                <button onClick={() => this.startQuiz(quiz, id)}>
              Start Quiz
              <span>
                <i className="fa fa-angle-right fa-sm"></i>
              </span>
            </button>
              </Fragment>: <Fragment>
              <button className="hollow" onClick={this.restartModule}>
              <span>
                <i className="fa fa-angle-left fa-sm"></i>
              </span>
              Restart module
            </button>
              </Fragment>
            }
            
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, { startQuiz,moduleDetails })(QuizDescription);
