import React, { Component } from "react";
import 'rsuite/lib/styles/index.less'
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'reactjs-popup/dist/index.css';
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import CommonRoute from "./components/common/CommonRoute";
import "./fonts/sofiapro-light.otf";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import Dashboard from "./components/dashboard";
import Courses from "./components/courses";
import CourseResult from "./components/courseResults";
import CourseOverview from "./components/course/course-overview";
import CourseDetail from "./components/landing-page/CourseOverview"
import Header from "./components/header";
import Notifications from "./components/notifications";
import Lessons from "./components/course/lessons";
import Quiz from "./components/quiz/quiz";
import QuizDescription from "./components/quiz/startQuiz";
import QuizResult from "./components/quiz/quizResult";
import Exam from "./components/exam/exams";
import ExamStart from "./components/exam/examStart";
import ExamResult from "./components/exam/examResult";
import UserProfile from "./components/profile/userProfile";
import Forum from "./components/forum/forum";
import ForumQuestion from "./components/forum/forumQuestion";
import AddQuestion from "./components/forum/addQuestion";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/resetpassword";
import ForgotPassword from "./components/auth/forgotpassword";
import Footer from "./components/Footer/Footer"
import NotFound from "./components/not-found/NotFound";
import Landing from "./components/landing-page/Landing"
import Terms from "./components/Footer/Terms"
import factory from "./store";
import { PersistGate } from "redux-persist/integration/react";
import NETWORKSERVICE from "./network-interceptor";
import history from "./helper/History"
import PublicHeader from "./components/landing-page/Navbar"
const { store, persistor } = factory();
const state = store.getState()
const isAuthenticated = state.auth.isAuthenticated
// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    window.location.href = "/login";
  }
}else{
  localStorage.removeItem('isDummy')
}
NETWORKSERVICE.setupInterceptors(store);

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: null
    }
  }

  componentDidMount() {


  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}  >
          <Router history={history} >
            <div>
              <ToastContainer autoClose={10000} />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }} >
                <PrivateRoute path="/" component={Header} />
                <Switch>
                  <Route
                    exact
                    path="/resetpassword/:token"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                  />
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register:courseId" component={Register} />
                  <Route exact path="/register" component={Register} />

                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/browse" component={Courses} />
                  <PrivateRoute
                    exact
                    path="/coursedetails"
                    component={CourseOverview}
                  />
                  <Route
                    exact
                    path="/public-coursedetails"
                    component={CourseOverview}
                  />
                  <PrivateRoute exact path="/coursestart" component={Lessons} />
                  <PrivateRoute exact path="/quiz" component={Quiz} />
                  <PrivateRoute
                    exact
                    path="/quizInstructions"
                    component={QuizDescription}
                  />
                  <PrivateRoute
                    exact
                    path="/quizResult"
                    component={QuizResult}
                  />
                  <PrivateRoute exact path="/exam" component={Exam} />
                  <PrivateRoute
                    exact
                    path="/examInstructions"
                    component={ExamStart}
                  />
                  <PrivateRoute
                    exact
                    path="/examResult"
                    component={ExamResult}
                  />
                  <PrivateRoute
                    exact
                    path="/viewResult/:id"
                    component={CourseResult}
                  />

                  <PrivateRoute exact path="/profile" component={UserProfile} />
                  <PrivateRoute exact path="/forum" component={Forum} />
                  <PrivateRoute
                    exact
                    path="/mynotifications"
                    component={Notifications}
                  />
                  <PrivateRoute
                    exact
                    path="/forumQuestion/:id"
                    component={ForumQuestion}
                  />
                  <PrivateRoute
                    exact
                    path="/askQuestion"
                    component={AddQuestion}
                  />
                  <Route path="/terms" component={Terms} />
                  <Route path="*" component={NotFound} />
                </Switch>

                <PrivateRoute
                  path="/"
                  component={Footer}
                />
              </div>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
