import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import {updateSelectedCourse} from "../../actions/courseAction"
import TextFieldGroup from "../common/TextFieldGroup";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "../course/payments";
import "../common/common.scss";
import { toast } from "react-toastify";
import tick from "../../assets/tick.png";
import loader from "../../assets/loader.gif";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import exclamation from "../../assets/required.JPG";
import { DatePicker, DateRangePicker ,Input} from "rsuite"
import { RiErrorWarningLine } from "react-icons/all"
import history from "../../helper/History"
import moment from 'moment'
// If you want to use the provided css
import "react-google-places-autocomplete/dist/index.min.css";
const stripePromise = loadStripe("pk_test_JG9f3af2QL0GFvhhpmP0qZRB00bSb6T6dE");
const { afterToday } = DateRangePicker


class Register extends Component {
  constructor() {
    super();
    this.state = {
      fname: "",
      lname: "",
      email: "",
      password: "",
      password2: "",
      dob: "",
      add: "",
      postalCode: "",
      city: "",
      province: "",
      provinces: [
        "Nunavut",
        "Quebec",
        "Northwest Territories",
        "Ontario",
        " British Columbia",
        "Alberta",
        "Saskatchewan",
        "Manitoba",
        "Yukon",
        "Newfoundland and Labrador",
        "New Brunswick",
        "Nova Scotia",
        "Prince Edward Island",
      ],
      course: "",
      country: "",
      selectedCourse: {},
      courses: [],
      proceedToPayment: false,
      error: null,
      partialPayment: false,
      threshold: null,
      paymentStatus: false,
      isRequesting: false,
      registrationFees: 0,
      fieldDisable: false,
      payError: null

    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handlePayError(error) {
    console.log("Pay Error: ", error)
    if (error != null) {
      this.setState({ payError: error })
    }
    else {
      this.setState({ payError: null })
    }
  }



  componentDidMount() {

    window.open('https://niwe.eduley.com/','_self')
    axios.get("/api/course/thresold_amount/").then((res) => this.setState({ registrationFees: res.data })).catch((err) => console.log(err))
    window.addEventListener("click", function (e) {
      const select = document.querySelector(".tooltiptext");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.addEventListener("click", function (e) {
      const select = document.querySelector(".t1");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.addEventListener("click", function (e) {
      const select = document.querySelector(".custom-select1");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.addEventListener("click", function (e) {
      const select = document.querySelector(".custom-select2");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    } else {
      axios.get("/api/course/courselist_for_registation/").then((res) => {
        this.setState({ courses: res.data });
        console.log('Course List: ',res.data)
        let courseId=this.getToken(window.location.href)
        if(courseId){
          console.log("Course ID: ",courseId)
          let selectedCourse=this.state.courses.filter((item)=>item.id==courseId)
          console.log("Selcted Course: ",selectedCourse)
          this.props.updateSelectedCourse(selectedCourse[0])
          if(selectedCourse.length>0){
            this.setState({
              course: courseId,
              selectedCourse: selectedCourse[0],
            });
          }
        }
      });
    }
    axios.get("/api/course/thresold_amount/").then((res) => {
      this.setState({ threshold: res.data.thresold_amount });
    });

   
    // if (history.location.state !== undefined) {
      console.log("History: ", history)
    // }
  }


  getToken=(url)=>{
    var urlString = new URL(url);
    var c=urlString.searchParams.get('courseId')
    console.log("Token",c)
    return c;
}

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidUpdate(prevProps, prevState,) {
    const { city, postalCode, country, province } = this.state
    if (prevState.city !== city && prevState.postalCode !== postalCode &&
      prevState.country !== country && prevState.province !== province) {
      console.log("prevState: ", prevState.city)
      console.log("Thi state: ", city)
      this.setState({ fieldDisable: true })
    }
    if (prevState.postalCode != postalCode) {
      // var str=postalCode;
      // str=str.replace(/\s+/g, '')
      this.setState({
        // postalCode:postalCode.replace(/\s+/g, '')
      })
    }
  }

  componentWillUnmount() {
    window.addEventListener("click", function (e) {
      const select = document.querySelector(".tooltiptext");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.removeEventListener("click", function (e) {
      const select = document.querySelector(".t1");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.removeEventListener("click", function (e) {
      const select = document.querySelector(".custom-select1");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
    window.removeEventListener("click", function (e) {
      const select = document.querySelector(".custom-select2");
      if (select !== null && !select.contains(e.target)) {
        select.classList.remove("open");
      }
    });
  }
  openSelect = () => {
    document.querySelector(".custom-select1").classList.toggle("open");
  };

  openProvinceSelect = () => {
    document.querySelector(".custom-select2").classList.toggle("open");
  };

  select = (e) => {
    let selectedCourse = this.state.courses.filter(
      (el) => el.id == e.target.id
    );
    this.setState({
      course: e.target.id,
      selectedCourse: selectedCourse[0],
    });
  };

  selectProvince = (e) => {
    this.setState({ province: e.target.id });
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    // $('#phoneNumber').on('change keyup', function() {
    //   // Remove invalid characters
    //   var sanitized = $(this).val().replace(/[^0-9]/g, '');
    //   // Update value
    //   $(this).val(sanitized);
    // });

  }
  disablePresaved = (event) => {
    // event.persist()
    event.target.autocomplete = 'nope';
    console.log("Event: ", event);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.province == "") {
      document
        .getElementsByClassName("tooltiptext")[0]
        .classList.toggle("open");
      return;
    }

    if (this.state.course == "") {
      document
        .getElementsByClassName("tooltiptext")[1]
        .classList.toggle("open");
      return;
    }
    let address = this.state.add
    if (!address) {
      address = document.getElementById('react-google-places-autocomplete-input').value
    }

    const newUser = {
      first_name: this.state.fname,
      last_name: this.state.lname,
      email: this.state.email,
      dob: this.state.dob,
      address: address,
      city: this.state.city,
      course: this.state.course,
      province: this.state.province,
      postal: this.state.postalCode,
      password: this.state.password,
      ph_no: this.state.phNum,
    };
    console.log("New Registration: ", newUser)
    if (this.state.password !== this.state.password2) {
      toast.error(" Password doesn't Match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      this.setState({ isRequesting: true });
      this.props
        .registerUser(newUser)
        .then((res) => {
          this.setState({ proceedToPayment: true, isRequesting: false });
        })
        .catch((err) => {
          this.setState({ isRequesting: false });
          toast.error(err.response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // this.setState({ error: err.response.data.msg });
        });
    }
  }

  handlePaymentResponse = (response, fail) => {
    if (fail) {
      toast.error("Payment Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (response.data.status == 500) {
        toast.error("Do not support this Card", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (response.data.status == 501) {
        toast.error("Insufficient Funds", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.setState({ paymentStatus: true });
      }
    }
  };

  googleAddress = (description) => {
    console.log("Description: ", description)
    var componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "long_name",
      country: "long_name",
      postal_code: "short_name",
    };
    geocodeByAddress(description)
      .then((results) => {
        var obj = {};
        console.log(results);
        let place = results[0];
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            // document.getElementById(addressType).value = val;
            obj[addressType] = val;
          }
        }
        let province = obj.administrative_area_level_1;
        let add = obj.street_number + " " + obj.route;
        let postalCode = obj.postal_code;
        let city = obj.locality;
        let country = 'Canada'
        this.setState({ add, province, city, postalCode, country });
      })
      .catch((error) => console.error(error));
  };

  render() {
    const {
      threshold,
      selectedCourse,
      selectedOption,
      paymentStatus,
      isRequesting,
      registrationFees,
      course,
    } = this.state;
    let partialPayment = threshold < selectedCourse.price;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="register">
          <div className="container">
            {this.state.error && (
              <div style={{ textAlign: "center" }}>
                <div className="error">{this.state.error}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-8 m-auto">
                {!this.state.proceedToPayment ? (
                  <Fragment>
                    <img
                      src={logo}
                      className="logo"
                      alt="logo niwe"
                      width="150"
                      height="80"
                      style={{ margin: "2.5rem auto", display: "flex" }}
                    />
                    <br />{" "}
                    <form onSubmit={this.onSubmit} autoComplete="none" onFocus={(event) => this.disablePresaved(event)} >
                      <div className="split-two">
                        <TextFieldGroup
                          placeholder="First name"
                          name="fname"
                          value={this.state.fname}
                          onChange={this.onChange}
                          style={{ textTransform: "capitalize" }}

                        />
                        <TextFieldGroup
                          placeholder="Last Name"
                          name="lname"
                          value={this.state.lname}
                          onChange={this.onChange}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <div>
                        {registrationFees && 
                        <GooglePlacesAutocomplete
                          inputClassName="form-group googleAddress"
                          required={true}
                          inputStyle={{ width: "100%", height: "36px", zIndex: 999 }}
                          apiKey={registrationFees ? registrationFees.google_api_key : ""}
                          displayFromSuggestionSelected={({
                            structured_formatting,
                          }) => {
                            return structured_formatting.main_text;
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["ca"],
                            },
                          }}
                          onSelect={({ description }) =>
                            this.googleAddress(description)
                          }
                        />}
                      </div>

                      <div className="split-two">
                        <TextFieldGroup
                          placeholder="City"
                          name="city"
                          value={this.state.city}
                          onChange={this.onChange}
                        // disabled={this.state.fieldDisable}
                        />

                        <div
                          class="form-group  custom-select1-wrapper "
                          onClick={this.openProvinceSelect}
                        >
                          <div
                            class="custom-select2 select form-control tooltip1"
                            style={{
                              position: "relative",
                              paddingRight: "18px",
                            }}
                          >
                            <div class="custom-select2__trigger ">
                              <span className="dropdown-inline" >
                                {this.state.province
                                  ? this.state.province
                                  : "Province"}
                              </span>
                              <div class="arrow"></div>
                            </div>
                            <span class="tooltiptext">
                              <img
                                src={exclamation}
                                style={{ marginRight: "8px" }}
                                width="17"
                                height="17"
                                alt=""
                              />
                              Please Select this field
                            </span>
                            <div
                              class="custom-options"
                              name="province"
                              onClick={(e) => this.selectProvince(e)}
                            >
                              {this.state.provinces.map((el, index) => {
                                return (
                                  <span
                                    class=" form-control form-control-lg custom-option"
                                    id={el}
                                  >
                                    {el}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="split-two">


                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg input-field"
                            required
                            maxlength={6}
                            pattern={
                              "[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}"
                            }
                            title="six letter postal code (eg.t2t2t2)"
                            placeholder="Postal Code"
                            name="postalCode"
                            value={this.state.postalCode}
                            // disabled={this.state.fieldDisable}
                            onChange={this.onChange}
                          />
                        </div>
                        <TextFieldGroup
                          placeholder="Country"
                          name="country"
                          type="text"
                          value={this.state.country}
                          onChange={this.onChange}
                        // disabled={this.state.fieldDisable}

                        />

                      </div>
                      <div className="split-two">

                        <div className="form-group phoneNumber">
                          {/* <Input
                           type="number" id="phoneNumber" placeholder="Phone" name="phNum" value={this.state.phNum} onChange={(v)=>this.setState({phNum:v})} /> */}
                          <input
                            type="number"
                            className="form-control form-control-lg input-field"
                            id="phoneNumber"
                            maxlength={10}
                            minlength={10}
                            pattern="^[0-9]*$"
                            title="Enter 10 Digit Phone Number"
                            required
                            placeholder="Phone"
                            name="phNum"
                            value={this.state.phNum}
                            onChange={this.onChange}
                          />
                        </div>
                        <span className="form-group" >
                          {/* <label className="dobex">Date of Birth</label>  */}
                          {/* <TextFieldGroup
                          placeholder="dd-mm-yy"
                          name="dob"
                          type="date"
                          readOnly
                          value={this.state.dob}
                          onChange={this.onChange}
                        /> */}
                          <DatePicker block placeholder="Date of Birth" limitEndYear={0} oneTap format="DD-MM-YYYY"
                            name="dob" disabledDate={afterToday()} placement="auto"
                            onChange={(value) => this.setState({ dob: moment(value).format('YYYY-MM-DD') })} />
                        </span>


                      </div>
                      <div className="split-two">

                        <div
                          class="form-group  custom-select1-wrapper "
                          onClick={this.openSelect}
                        >
                          <div class="custom-select1 select form-control tooltip1">
                            <div class="custom-select1__trigger">
                              <span className="dropdown-inline" >
                                {this.state.selectedCourse.name
                                  ? this.state.selectedCourse.name
                                  : "Select Course"}
                              </span>
                              <div class="arrow"></div>
                            </div>
                            <span class="t1 tooltiptext">
                              <img
                                src={exclamation}
                                style={{ marginRight: "8px" }}
                                width="17"
                                height="17"
                                alt=""
                              />
                              Please Select this field
                            </span>

                            <div
                              class="custom-options"
                              onClick={(e) => this.select(e)}
                            >
                              {this.state.courses.map((el, index) => {
                                return (
                                  <span
                                    class=" form-control form-control-lg custom-option"
                                    id={el.id}
                                    name="course"
                                  >
                                    {el.name}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-control form-control-lg select ">
                            <span className="dropdown-inline">Fees: {this.state.selectedCourse.course_price}</span>

                          </div>
                        </div>
                      </div>

                      <TextFieldGroup
                        placeholder="Email"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.onChange}
                      />

                      <TextFieldGroup
                        placeholder="Password"
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                      <TextFieldGroup
                        placeholder="Confirm Password"
                        name="password2"
                        type="password"
                        value={this.state.password2}
                        onChange={this.onChange}
                      />
                      <div className="signIn">
                        <button type="submit">Sign Up</button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Link
                          className="nav-link signin themeclr"
                          id="signin"
                          to="/login"
                          style={{
                            
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                          }}
                        >

                          Sign In
                        </Link>
                      </div>
                    </form>
                  </Fragment>
                ) : (
                    <Fragment>
                      <div className="col-md-11" style={{ marginTop: "50px" }}>
                        <div className="payments">
                          {/* <h4>
                          The registration fee for this program is ${registrationFees.registation_fees}. You can
                          make your payment here.
                        </h4> */}
                          {this.state.payError != null && <div className="payment-message" >
                            <RiErrorWarningLine size={20} className="payment-msg-icon" />
                            <p><span>Error.</span>{this.state.payError}</p>
                          </div>}
                          <br />
                          {partialPayment && (
                            <div
                              className="alert alert-warning"
                              role="alert"
                              style={{ fontSize: "14px" }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                {" "}
                                <i
                                  class="fa fa-info-circle"
                                  aria-hidden="true"
                                ></i>{" "}
                              </span>
                            The registration fee for this program is ${registrationFees.registation_fees} CAD. You can
                          make your payment here.
                            </div>
                          )}
                          <div className="mb-24">
                            <label htmlFor="course-name">Course Name</label>
                            <input
                              readOnly
                              type="text"
                              id="course-name"
                              value={this.state.selectedCourse.name}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="mb-24">
                            <label htmlFor="Fees">Course Fees</label>
                            <input
                              readOnly
                              type="text"
                              id="Fees"
                              value={this.state.selectedCourse.course_price}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="mb-24">
                            <label htmlFor="Fees">Amount To Pay</label>
                            <input
                              type="text"
                              id="Fees"
                              readOnly
                              value={
                                partialPayment
                                  ? `$${registrationFees.registation_fees} CAD`
                                  : this.state.selectedCourse.course_price
                              }
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="stripe">
                            {registrationFees!==0&&<Elements stripe={loadStripe(registrationFees.stripe_pub_key)}>
                              <CardForm
                                paymentPartial={partialPayment}
                                id={this.state.course}
                                handlePaymentResponse={this.handlePaymentResponse}
                                handlePayError={(e) => this.handlePayError(e)}

                              />
                            </Elements>}
                          </div>
                          <div
                            style={{ textAlign: "center  ", fontSize: "20px" }}
                          >
                            <Link
                              className="nav-link themeclr"
                              to="/login"
                              style={{
                               
                                backgroundColor: "transparent",
                                textDecoration: "underline",
                                marginTop: "10px",
                              }}
                            >
                              Sign In Instead
                          </Link>
                          </div>
                        </div>
                      </div>
                      <Fragment>
                        {paymentStatus && (
                          <div
                            className="modal enroll show"
                            tabIndex="-1"
                            role="dialog"
                            id="enrollModal"
                            style={{ top: "10%" }}
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content" >
                                <div className="modal-body">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div style={{ marginBottom: "1rem" }}>
                                      <h3>Payment was Successful !</h3>
                                    </div>
                                    <div style={{ marginBottom: "2rem" }}>
                                      <img
                                        src={tick}
                                        width="150"
                                        height="150"
                                        alt="sucess"
                                      />
                                    </div>

                                    <div>
                                      <button
                                        className="hollow"
                                        onClick={() =>
                                          this.props.history.push({pathname:"/login",state:{selectedCouse:this.state.selectedCourse}})
                                        }
                                        style={{ margin: "0" }}
                                      >
                                        Login
                                    </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    </Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { registerUser,updateSelectedCourse })(Register);
