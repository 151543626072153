import React, { Component, Fragment } from "react";
import Navbar from "../../shared/Navbar/navbar";
import logo from "../../assets/profile.png";
import "./userProfile.scss";
import ReportIssue from "../Footer/ReportIssue";
import axios from "axios";
import upload from "../../assets/upload.svg";
import loader from "../../assets/loader.gif";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip, Whisper } from "rsuite";
import { RiErrorWarningLine } from "react-icons/all";
import { isMobile } from "react-device-detect";
import history from "../../helper/History";
export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.handleAddImage = this.handleAddImage.bind(this);
    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = {
      pages: [
        "User Details",
        "Billing Information",
        "Program Information",
        "Overview",
      ],
      name: "sophie",
      data: {},
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      resetPassword: false,
      file: "",
      imagePreviewUrl: "",
      isRequesting: true,
      currentPage: 0,
      programData: [],
      studentOverview: "",
      billData: [],
      file: null,
      dragOver: false,
      fileUploadSucess: false,
      sectionName: null,
      trigger: [],
      override: false,
      cardNumber: "",
      expiryDate: "",
      current_module: null,
      moduleStats: [],
      classCompletion: 0,
      myCompletion: 0,
      myCompletion_text: "",
    };
  }

  /**
      Drag and Drop Event Handlers
   **/
  handleDragEnter(e) {
    e.preventDefault();
  }
  handleDragOver(e) {
    e.preventDefault();
    if (!this.state.dragOver) {
      this.setState({
        dragOver: true,
      });
    }
  }
  handleDragLeave(e) {
    e.preventDefault();
    this.setState({
      dragOver: false,
    });
  }
  handleDrop(e) {
    e.preventDefault();
    let file = e.dataTransfer.files[0];

    // Validate file is of type Image
    let fileType = file.type.split("/")[0];
    if (fileType !== "image") {
      this.setState({
        file: null,
        errorNotification: "Not an image File",
        dragOver: false,
      });
      return setTimeout(() => {
        this.setState({
          errorNotification: null,
        });
      }, 3000);
    }
    // this.refs.image.files = e.dataTransfer.files;
    document.getElementById("upload-image-input").fileList =
      e.dataTransfer.files[0];
    this.setState({
      file,
      dragOver: false,
    });
    this.handleUploadImage();
  }

  /**
    Handle Manually (File Input) Added Files
 **/
  handleAddImage(e) {
    e.preventDefault();
    let file = this.refs.image.files[0];
    // Validate file is of type Image
    let fileType = this.refs.image.files[0].type.split("/")[0];
    if (fileType !== "image") {
      toast.error("Not an image file", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        file: null,
        errorNotification: "Not an image File",
        dragOverClass: "",
      });
      return;
    }

    this.setState({
      file,
    });
    this.handleUploadImage();
  }

  /**
    Handle Upload after Upload Button Clicked
 **/
  handleUploadImage() {
    if (this.refs.image.files[0]) {
      /**
          Handle image Upload
       **/
      this.setState({ isRequesting: true });
      let file = this.refs.image.files[0];
      let formData = new FormData();
      formData.append("image", file);
      axios
        .patch(
          `/api/student/update_student_info/${this.state.data.id}/`,
          formData
        )
        .then((res) => {
          this.setState({
            data: res.data,
            isRequesting: false,
            fileUploadSucess: true,
          });
        })
        .catch((err) => {
          this.setState({ isRequesting: false, fileUploadSucess: false });
          console.log("Upload err", err);
        });
    }
  }

  changePassword = (e) => {
    e.preventDefault();
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      toast.error("Please enter matching new password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    this.setState({ isRequesting: true });

    let obj = {
      old_password: this.state.oldPassword,
      new_password: this.state.newPassword,
    };
    axios
      .post("/api/student/change_password/", obj)
      .then((res) => {
        this.setState({
          resetPassword: false,
          isRequesting: false,
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        console.log("Password Response: ", res.data);
        toast.error(res.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ isRequesting: false });

        console.log("change failed", err);
        console.log("change failed", err.response);
      });
  };

  componentDidMount() {
    axios.get("/api/student/student_info/").then((res) => {
      this.setState({ data: res.data[0] });
      setTimeout(this.setState({ isRequesting: false }), 2000);
    });
    axios.get("/api/student/program_info/").then((res) => {
      this.setState({ programData: res.data });
    });
    axios.get("/api/student/student_overview/").then((res) => {
      this.setState({ studentOverview: res.data });
    });

    axios.get("/api/student/bill_info/").then((res) => {
      this.setState({
        billData: res.data,
        cardNumber: "xxxx-xxxx-xxxx-" + res.data[0].last4_digit,
        expiryDate: res.data[0].expiry_month + "/" + res.data[0].expiry_year,
      });
    });

    if (isMobile) {
      this.setState({ trigger: ["focus", "click", "active"] });
    } else {
      this.setState({ trigger: ["hover", "focus", "click", "active"] });
    }

    if (history.location.state !== undefined) {
      console.log("History is there: ", history);
      this.setState({
        currentPage: history.location.state.pageId,
        override: true,
      });
    }
  }

  changeProperty = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  notify = () =>
    toast.error("Error Notification !", {
      position: toast.POSITION.TOP_RIGHT,
    });

  toggleCourse = (ID) => {
    console.log("Id: ", ID);
    this.setState({ currentPage: ID });
  };

  resetModal = () => {
    this.setState({ fileUploadSucess: false });
  };

  download(link) {
    window.open(link);
  }

  expiryDate = (e) => {
    let str = e.target.value;
    this.setState({ expiryDate: e.target.value });
    function checkValue(str, max) {
      if (str.charAt(0) !== "0" || str == "00") {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str =
          num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
            ? "0" + num
            : num.toString();
      }
      return str;
    }

    var input = str;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 99);
    var output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + " /" : v;
    });
    this.setState({ expiryDate: output.join("").substr(0, 6) });
    console.log("Expirarrr: ", this.state.expiryDate);
  };

  render() {
    const {
      pages,
      name,
      resetPassword,
      oldPassword,
      newPassword,
      confirmNewPassword,
      isRequesting,
      currentPage,
      programData,
      studentOverview,
      billData,
      fileUploadSucess,
    } = this.state;
    const {
      first_name,
      last_name,
      date_of_birth,
      email,
      image,
      is_payment_complete,
      address,
      city,
      phone,
      province,
    } = this.state.data;
    let dragOverClass = this.state.dragOver
      ? `display-box drag-over`
      : `display-box`;

    let uploadText = !this.state.fileUploadSucess ? (
      <div className="upload">
        <div>
          <i class="fa fa-upload fa-3x" aria-hidden="true"></i>
        </div>
        <div className="text">Drag and drop your files here</div>
        <div className="subtext">Or upload your file here</div>
      </div>
    ) : (
      <div className="upload ">
        <i class="fa fa-upload fa-3x success" aria-hidden="true"></i>
        <div id="myProgress">
          <div id="myBar"></div>
        </div>
        <div className="subtext">Upload Complete!</div>
      </div>
    );

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0.1" }}></div>
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <h1 class="page-heading">Profile</h1>
          <Navbar navLinks={pages} changeOver={this.toggleCourse} />
          {currentPage == 0 && (
            <div>
              <div className="userProfile">
                <div className="userimage">
                  <img
                    src={image ? image : logo}
                    width="216"
                    height="216"
                    alt="user Image"
                  />
                  <label>
                    <input
                      type="file"
                      onChange={(e) => this.upload(e)}
                      style={{ display: "none" }}
                    />
                    <button data-toggle="modal" data-target="#enrollModal">
                      Edit
                    </button>
                  </label>
                </div>
                <div className="user-info">
                  <div className="mb-24">
                    <label for="email"> Email</label>
                    <input type="email" id="email" value={email} />
                  </div>

                  {resetPassword ? (
                    <Fragment>
                      <form onSubmit={this.changePassword}>
                        <div className="mb-24">
                          <label for="oldPassword"> Current Password</label>
                          <input
                            type="password"
                            id="oldPassword"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={this.changeProperty}
                          />
                        </div>
                        <div className="mb-24">
                          <label for="newPassword"> New Password</label>
                          <input
                            type="password"
                            id="newPassword"
                            minlength={8}
                            required
                            name="newPassword"
                            value={newPassword}
                            onChange={this.changeProperty}
                          />
                          <p className="pass-info">Min 8 chars</p>
                        </div>
                        <div className="mb-24">
                          <label for="confirmNewPassword">
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            minlength={8}
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            required
                            onChange={this.changeProperty}
                          />
                        </div>
                        <button
                          className={`${
                            oldPassword !== "" &&
                            oldPassword !== newPassword &&
                            confirmNewPassword == newPassword &&
                            newPassword.length > 7
                              ? ""
                              : "disabled"
                          } `}
                          type="submit"
                        >
                          Save
                        </button>
                        <button
                          onClick={() =>
                            this.setState({ resetPassword: false })
                          }
                          className="hollow"
                        >
                          Cancel
                        </button>
                      </form>
                    </Fragment>
                  ) : (
                    <div className="mb-24">
                      <label for="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value="**********"
                      />
                      <span
                        onClick={() => this.setState({ resetPassword: true })}
                        className="edit-password"
                      >
                        Change Password
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <hr />
              <h3>Student Information</h3>

              <div className="student-info">
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="firstname" className="pt-2">
                      {" "}
                      First Name (legal)
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      readOnly
                      value={first_name}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="lastname" className="pt-2">
                      {" "}
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      readOnly
                      value={last_name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="mailadd" className="pt-2">
                      {" "}
                      Mailing address
                    </label>
                    <input type="text" id="mailadd" readOnly value={address} />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="emailAddress" className="pt-2">
                      {" "}
                      Email address
                    </label>
                    <input
                      type="text"
                      id="emailAddress"
                      readOnly
                      value={email}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="city" className="pt-2">
                      {" "}
                      City/Town
                    </label>
                    <input type="text" id="city" readOnly value={city} />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="cellPhone" className="pt-2">
                      {" "}
                      Cell phone
                    </label>
                    <input type="text" id="cellPhone" readOnly value={phone} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="username" className="pt-2">
                      {" "}
                      Province
                    </label>
                    <input
                      type="text"
                      id="username"
                      readOnly
                      value={province}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="dob" className="pt-2">
                      Date of birth
                    </label>
                    <input
                      type="text"
                      id="dob"
                      readOnly
                      value={date_of_birth}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                className="pt-2"
              >
                <button
                  style={{ marginRight: 10 }}
                  data-toggle="modal"
                  data-target="#contactAdmin"
                  onClick={() =>
                    this.setState({ sectionName: "Student Information" })
                  }
                >
                  Contact admin
                  <span>
                    <i className="fa fa-angle-right fa-sm"></i>
                  </span>
                </button>
                <Whisper
                  trigger={this.state.trigger}
                  speaker={
                    <Tooltip visible>
                      <div>
                        <h4>Message</h4>
                        <p>
                          Send a message to Admin if you need any assistance
                          with your information on this page.
                        </p>
                      </div>
                    </Tooltip>
                  }
                >
                  <RiErrorWarningLine size={24} className="action-btn-info" />
                </Whisper>
              </div>
            </div>
          )}
          {currentPage == 1 &&
            (billData.length > 0 ? (
              <div>
                <div className="student-info" style={{ marginBottom: "30px" }}>
                  <div className="left-half">
                    <div className="mb-24">
                      <label for="cardNo">Card number</label>
                      <input
                        type="text"
                        id="cardNo"
                        readOnly={!this.state.override}
                        onChange={(e) =>
                          this.setState({ cardNumber: e.target.value })
                        }
                        value={this.state.cardNumber}
                        // value={"xxxx-xxxx-xxxx-" + billData[0].last4_digit}
                      />
                    </div>
                  </div>
                  <div className="left-half">
                    <div className="mb-24">
                      <label for="expiry">Expiration date</label>
                      <input
                        type="text"
                        id="expiry"
                        readOnly={!this.state.override}
                        onChange={(e) => this.expiryDate(e)}
                        // value={
                        //   billData[0].expiry_month +
                        //   "/" +
                        //   billData[0].expiry_year
                        // }
                        value={this.state.expiryDate}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{ marginRight: 10 }}
                    data-toggle="modal"
                    data-target="#contactAdmin"
                    onClick={() =>
                      this.setState({ sectionName: "Billing Information" })
                    }
                  >
                    Contact admin
                    <span>
                      <i className="fa fa-angle-right fa-sm"></i>
                    </span>
                  </button>
                  <Whisper
                    trigger={this.state.trigger}
                    speaker={
                      <Tooltip visible>
                        <div>
                          <h4>Message</h4>
                          <p>
                            Send a message to Admin if you need any assistance
                            with your information on this page.
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <RiErrorWarningLine size={24} className="action-btn-info" />
                  </Whisper>
                </div>
              </div>
            ) : (
              <div className="billing-info">
                <h1>Sorry No Data Available</h1>
                <div
                  className="left-half"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    data-toggle="modal"
                    data-target="#contactAdmin"
                    onClick={() =>
                      this.setState({ sectionName: "Billing Information" })
                    }
                  >
                    Contact admin
                    <span>
                      <i className="fa fa-angle-right fa-sm"></i>
                    </span>
                  </button>
                  <Whisper
                    trigger={this.state.trigger}
                    speaker={
                      <Tooltip visible>
                        <div>
                          <h4>Message</h4>
                          <p>
                            Send a message to Admin if you need any assistance
                            with your information on this page.
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <RiErrorWarningLine size={24} className="action-btn-info" />
                  </Whisper>
                </div>
              </div>
            ))}

          {currentPage == 2 && (
            <div>
              <h3>Student Overview</h3>

              <div className="student-info">
                <div className="row">
                    <div className="col-md-6 col-sm-12 ">
                    <label for="total_sub" className="pt-2">
                      Total Subscriptions
                    </label>
                    <input
                      type="text"
                      id="total_sub"
                      readOnly
                      value={studentOverview.total_subscriptions}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="course_comp" className="pt-2">
                      Course Completed
                    </label>
                    <input
                      type="text"
                      id="course_comp"
                      readOnly
                      value={studentOverview.course_completed}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="time_spent" className="pt-2">
                      Time Spent on platform 
                    </label>
                    <input
                      type="text"
                      id="time_spent"
                      readOnly
                      value={studentOverview.time_spent}
                    />
                  </div>
                  {/* <div className="col-md-6 col-sm-12 ">
                    <label for="total_sub" className="pt-2">
                      Last Login
                    </label>
                    <input
                      type="text"
                      id="total_sub"
                      readOnly
                      value={studentOverview.last_login}
                    />
                  </div> */}
                </div>
              </div>
              <div>
                <h3 style={{marginTop:'50px',marginBottom:'25px'}}>Subscribed Courses</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ marginRight: 10 }}
                    data-toggle="modal"
                    data-target="#contactAdmin"
                    onClick={() =>
                      this.setState({ sectionName: "Program Information" })
                    }
                  >
                    Contact admin
                    <span>
                      <i className="fa fa-angle-right fa-sm"></i>
                    </span>
                  </button>
                  <Whisper
                    trigger={this.state.trigger}
                    speaker={
                      <Tooltip visible>
                        <div>
                          <h4>Message</h4>
                          <p>
                            Send a message to Admin if you need any assistance
                            with your information on this page.
                          </p>
                        </div>
                      </Tooltip>
                    }
                  >
                    <RiErrorWarningLine size={24} className="action-btn-info" />
                  </Whisper>
                </div>
              </div>
              {programData.map((data, index) => {
                let startMonth =
                  monthNames[new Date(data.course_data.enroll_date).getMonth()];
                let startYear = new Date(
                  data.course_data.enroll_date
                ).getFullYear();
                let endMonth =
                  monthNames[new Date(data.course_data.end_date).getMonth()];
                let endYear = new Date(data.course_data.end_date).getFullYear();
                return (
                  <div>
                    <h1>
                      {startMonth} {startYear} - {endMonth} {endYear}
                    </h1>

                    <div className="program-info">
                      <div className="left-half">
                        <div className="mb-24">
                          <label for="firstname">Course name</label>
                          <input
                            type="text"
                            id="firstname"
                            readOnly
                            value={data.course_data.course}
                          />
                        </div>

                        <div className="mb-24">
                          <label for="mailadd">Delivery mode</label>
                          <input
                            className="caps"
                            type="text"
                            id="mailadd"
                            readOnly
                            value={data.course_data.payment_mode}
                          />
                        </div>
                      </div>
                      <div className="left-half">
                        <div className="mb-24">
                          <label for="lastname">Instructor name</label>
                          <input
                            type="text"
                            id="lastname"
                            readOnly
                            value={data.course_data.instructor}
                          />
                        </div>
                        <div className="mb-24">
                          <label for="emailAddress">Cost</label>
                          <input
                            type="text"
                            id="emailAddress"
                            readOnly
                            value={"$" + data.course_data.cost}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      {data.track_status === "COMPLETED" ? (
                        <div className="mb-24">
                          <label for="course_complete">Course Status</label>
                          <input
                            type="text"
                            id="course_complete"
                            readOnly
                            value="Completed"
                          />
                        </div>
                      ) : (
                        data.program_list.map((datas) => {
                          if (data.current_module === datas.id) {
                            return (
                              <>
                                {datas.progress === 0 ? (
                                  <div className="mb-24">
                                    <label for="course_complete">
                                      Course Status
                                    </label>
                                    <input
                                      type="text"
                                      id="course_complete"
                                      readOnly
                                      value="You have not started the course yet !"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div className="mb-24">
                                      <label for="module">Current Module</label>
                                      <input
                                        type="text"
                                        id="module"
                                        readOnly
                                        value={data.module_index}
                                      />
                                    </div>
                                    <div
                                      className="mb-24"
                                      style={{ marginLeft: "100px" }}
                                    >
                                      <label for="lesson">Current Lesson</label>
                                      <input
                                        type="text"
                                        id="lesson"
                                        readOnly
                                        value={
                                          datas.lesson_index == 0 &&
                                          datas.progress == 100
                                            ? "All Lessons are Complete"
                                            : datas.lesson_index
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          }
                        })
                      )}
                    </div>
                    {data.my_completion < 100 && data.my_completion > 0 && (
                      <div style={{ display: "flex" }}>
                        <div className="mb-24">
                          <label for="course_complete">My Completion</label>
                          <input
                            type="text"
                            id="course_complete"
                            readOnly
                            value={data.my_completion + " %"}
                          />
                        </div>
                      </div>
                    )}

                    {data.is_certificate && (
                      <div className="program-info__buttons">
                        <div className="action-btn certificate-download">
                          <button
                            style={{ marginRight: 10 }}
                            className={`"hollow" ${
                              data.certificate != null ? "" : "disabled"
                            } `}
                            onClick={() => this.download(data.certificate)}
                          >
                            Download Certificate
                          </button>
                        </div>
                        <div className="action-btn certificate-download">
                          <button
                            className={`"hollow" ${
                              data.certificate != null ? "" : "disabled"
                            } `}
                            onClick={() => this.download(data.transcript)}
                          >
                            Download Transcript
                          </button>
                        </div>
                        {/* <div>
                          <button
                            style={{ marginRight: 10 }}
                            data-toggle="modal"
                            data-target="#contactAdmin"
                          >
                            Contact admin
                            <span>
                              <i className="fa fa-angle-right fa-sm"></i>
                            </span>
                          </button>
                        </div> */}
                      </div>
                    )}
                    {/* {!data.is_certificate && (
                      <div style={{ paddingBottom: 10 }}>
                        <button
                          style={{ marginRight: 10 }}
                          data-toggle="modal"
                          data-target="#contactAdmin"
                        >
                          Contact admin
                          <span>
                            <i className="fa fa-angle-right fa-sm"></i>
                          </span>
                        </button>
                      </div>
                    )} */}
                    <div>
                      <span style={{ fontSize: "12px" }}>{data.msg}</span>
                    </div>
                    {index < programData.length - 1 && <hr />}
                  </div>
                );
              })}
            </div>
          )}

          {currentPage == 3 && (
            <div>
              <h3>Student Information</h3>

              <div className="student-info">
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="firstname" className="pt-2">
                      {" "}
                      First Name (legal)
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      readOnly
                      value={first_name}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="lastname" className="pt-2">
                      {" "}
                      Last name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      readOnly
                      value={last_name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="mailadd" className="pt-2">
                      {" "}
                      Mailing address
                    </label>
                    <input type="text" id="mailadd" readOnly value={address.replace(/\r\n/g,', ')} />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="emailAddress" className="pt-2">
                      {" "}
                      Email address
                    </label>
                    <input
                      type="text"
                      id="emailAddress"
                      readOnly
                      value={email}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="city" className="pt-2">
                      {" "}
                      City/Town
                    </label>
                    <input type="text" id="city" readOnly value={city} />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="cellPhone" className="pt-2">
                      {" "}
                      Cell phone
                    </label>
                    <input type="text" id="cellPhone" readOnly value={phone} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12 ">
                    <label for="username" className="pt-2">
                      {" "}
                      Province
                    </label>
                    <input
                      type="text"
                      id="username"
                      readOnly
                      value={province}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="dob" className="pt-2">
                      Date of birth
                    </label>
                    <input
                      type="text"
                      id="dob"
                      readOnly
                      value={date_of_birth}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="total_sub" className="pt-2">
                      Total Subscriptions
                    </label>
                    <input
                      type="text"
                      id="total_sub"
                      readOnly
                      value={studentOverview.total_subscriptions}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="course_comp" className="pt-2">
                      Course Completed
                    </label>
                    <input
                      type="text"
                      id="course_comp"
                      readOnly
                      value={studentOverview.course_completed}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="time_spent" className="pt-2">
                      Time Spent on platform
                    </label>
                    <input
                      type="text"
                      id="time_spent"
                      readOnly
                      value={studentOverview.time_spent}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <label for="total_sub" className="pt-2">
                      Last Login
                    </label>
                    <input
                      type="text"
                      id="total_sub"
                      readOnly
                      value={studentOverview.last_login}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {
          <div
            className="modal enroll"
            tabIndex="-1"
            role="dialog"
            id="enrollModal"
            style={{ top: "20%" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  onClick={this.resetModal}
                >
                  &times;
                </button>
                <div className="modal-body">
                  {uploadText}
                  {!fileUploadSucess && (
                    <div>
                      <input
                        type="file"
                        ref="image"
                        id="upload-image-input"
                        className="upload-image-input"
                        accept="image/*"
                        onDrop={this.handleDrop}
                        onDragEnter={this.handleDragEnter}
                        onDragOver={this.handleDragOver}
                        onDragLeave={this.handleDragLeave}
                        onChange={this.handleAddImage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
        <ReportIssue
          name="Contact Admin"
          modalId="contactAdmin"
          apiUrl="/api/student/contact_admin/"
          section={this.state.sectionName}
        />
      </Fragment>
    );
  }
}
