/** @format */

import React, { Component, Fragment } from "react";
import "./navbar.scss";

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  toggleCategory = (e) => {
    if (e.target.id !== "") {
      this.setState({ active: e.target.id });
      this.props.changeOver(e.target.id);
      console.log("Dashboard target: ", e.target.id);
      console.log("E. Target: ", e.target);
    }
  };
  componentDidMount() {
    const { navLinks, heading, activeNav } = this.props;
    if (navLinks.length > 0) {
      console.log("navLinks: ", navLinks);
      console.log("activeNav: ", activeNav);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let page = localStorage.getItem("page");
    if (prevProps.navLinks.length == 0 && this.props.navLinks.length > 0) {
      if (prevProps.activeNav == 0 && this.props.activeNav == 0 && (page == null || page == undefined)) {
        this.setState({ active: 0 });
        this.props.changeOver(0);
      }
    }
  }

  render() {
    const { active } = this.state;
    const { navLinks, heading, activeNav } = this.props;
    return (
      <Fragment>
        <div className="your-courses">
          {heading !== undefined && (
            <span style={{ marginRight: "5px" }}>{heading} : </span>
          )}
          <ul onClick={this.toggleCategory}>
            {navLinks.map((el, index) => {
              let useActive = activeNav !== undefined ? activeNav : active;
              let selection = useActive == index ? "active" : "";
              return (
                <li className={`${selection} caps`} id={index}>
                  {" "}
                  {el}
                </li>
              );
            })}
          </ul>
        </div>
        <hr />
      </Fragment>
    );
  }
}
