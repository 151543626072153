import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import loader from "../../assets/loader.gif";
import { toast } from "react-toastify";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      error: null,
      isRequesting: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
      console.log("Login Error: ", nextProps.errors);
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.setState({ isRequesting: true });

    this.props
      .loginUser(userData)
      .then((res) => {
        this.setState({ isRequesting: false });
      })
      .catch((err) => {
        console.log("Login Errooooo: ", err.response.request);
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.setState({ error: err.response.data.detail, isRequesting: false });
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { error, isRequesting, email, password } = this.state;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div className="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="login">
          <div className="containers" >

            {/* {error && <div className="error">{error}</div>} */}
            <div className="login__box ">
              <img
                src={logo}
                className="logo"
                alt="logo niwe"
                width="150"
                height="80"
                style={{ marginBottom: "2.5rem" }}
              />
              <br />
              <p className="lead text-center">Sign in to your account</p>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="Email "
                  name="email"
                  type="text"
                  value={email}
                  onChange={this.onChange}
                />

                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.onChange}
                />
                <div className="signIn">
                  <button
                    className="hollow"
                    type="button"
                    onClick={() => window.open('https://niwe.eduley.com/', '_self')}
                    // onClick={() => this.props.history.push("/register")}
                    style={{ marginRight: 10 }}
                  >
                    Register
                  </button>
                  <button type="submit">Login</button>
                </div>
              </form>
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  className="nav-link themeclr"
                  to="/forgotpassword"
                  style={{

                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="lead" style={{
                borderTop: "1px solid #bbb",
                marginTop: "8px",
                borderRadius: "5px"
              }} />
              <div
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p  style={{ marginTop: '1.1rem', marginRight: '1rem' }}>
                  Registered in year 2023?
                </p>
                <button
                  className="hollow"
                  style={{fontSize:14}}
                  onClick={() => window.open("https://niwe.eduley.com/", '_self')}
                >
                  Please login to New Portal
                </button>
              </div>
              
            </div>

            {error && <div className="login__box_message"  >
              <p className="">{error}.{' '}<br />
                If you have registered in 2023 then try logging in the new portal</p>
              <div className="signIn">
                <button onClick={() => window.open("https://niwe.eduley.com/", '_self')}
                >Login to New Portal</button>
              </div>

            </div>}

          </div>


        </div>
      </Fragment>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginUser })(Login);
