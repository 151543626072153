/** @format */

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  moduleDetails,
  enrollCourse,
  startCourse,
  courseDetails,
  checkSavedCard,
  payFromSavedCard
} from "../../actions/courseAction";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import loader from "../../assets/loader.gif";
import CardForm from "./payments";
import tick from "../../assets/tick.png";
import axios from "axios";
import { Player, ControlBar, Shortcut, PlayToggle } from "video-react";
import PropTypes from "prop-types";
import { Tooltip, Whisper, Icon, Button } from "rsuite";
import { RiErrorWarningLine } from "react-icons/all";
import { isMobile } from "react-device-detect";
import history from "../../helper/History";
import PublicHeader from "../../components/landing-page/Navbar"
import SavedCard from "../../components/card-details/SavedCard"
import Info from "../landing-page/Info"
import Footer from "../Footer/Footer"
import * as _ from "lodash"
const stripePromise = loadStripe("pk_test_JG9f3af2QL0GFvhhpmP0qZRB00bSb6T6dE");
class CourseOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [1, 2, 3, 4, 5, 6, 7, 8],
      payment: false,
      isRequesting: false,
      inputemail: "",
      paymentSucess: false,
      registrationFees: 0,
      stripeKey:null,
      payError: null,
      isCardSaved: false,
      updateCard: false
    };
    this.newShortcuts = [
      {
        keyCode: 39, // Right arrow
        ctrl: false, // Ctrl/Cmd
        handle: (player, actions) => {
          if (!player.hasStarted) {
            return;
          }

          // this operation param is option
          // helps to display a bezel
          const operation = {
            action: "forward-0",
            source: "shortcut",
          };
          actions.forward(0, operation); // Go forward 30 seconds
        },
      },
    ];
  }

  updateCardTrue = () => {
    this.setState({ updateCard: true })
  }
  updateCardFalse = () => {
    this.setState({ updateCard: false })

  }

  handlePayError(error) {
    console.log("Pay Error: ", error);
    if (error != null) {
      this.setState({ payError: error });
    } else {
      this.setState({ payError: null });
    }
  }

  componentDidMount() {
    const { courseDetails } = this.props.courseDetailss;
    if(_.isEmpty(courseDetails)){
      history.push('/')
    }
    axios
      .get("/api/course/thresold_amount/")
      .then((res) =>
        this.setState({ 
          registrationFees: res.data.registation_fees,
          stripeKey:res.data.stripe_pub_key 
        
        })
      )
      .catch((err) => console.log(err));
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "#F4F4F4";

    console.log("Course Overview Props: ", this.props);
    if (this.props.auth.isAuthenticated) {
      axios
        .get("/api/course/detail_by_id/" + courseDetails.id + "/")
        .then((res) => {
          console.log("Course Overview Response: ", res.data);
          this.props.courseDetails(res.data);
        });

      this.props.checkSavedCard()
        .then((res) => {
          this.setState({ isCardSaved: res.data.is_card_saved })
          console.log("Isss cardd:", res.data)
        })

    } else {
      console.log("Course Detail: ",courseDetails)
    
      // axios.get('/')
    }

    console.log("Is dummy: ", localStorage.getItem("isDummy"));

    if (isMobile) {
      this.setState({ trigger: ['focus', 'click', 'active'] })
    } else {
      this.setState({ trigger: ['hover', 'focus', 'click', 'active'] })
    }

    console.log("History: ",history)


  }

  componentWillUnmount() {
    document.getElementsByClassName("container")[0].style.backgroundColor =
      "transparent";
  }

  enrollCourse = () => {
    setTimeout(() => {
      document.getElementsByClassName("modal-backdrop show")[0].remove();
      document.getElementsByClassName("modal-backdrop in")[0].remove();
      document
        .getElementsByClassName("modal-open")[0]
        .classList.remove("modal-open");
    }, 1000);
    this.setState({ paymentSucess: true });

    let { course_price } = this.props.courseDetailss.courseDetails;
    let { thresold_amount } = this.props.auth.profile;
    const paymentPartial =
      parseInt(course_price.split("$")[1].split(" ")[0]) > thresold_amount;
    this.props.enrollCourse(paymentPartial);

    const { courseDetails } = this.props.courseDetailss;
    axios
      .get("/api/course/detail_by_id/" + courseDetails.id + "/")
      .then((res) => {
        console.log("Course Overview Response: ", res.data);
        this.props.courseDetails(res.data);
      });
  };

  paymentSuccess = () => {
    this.setState({ paymentSuccess: true })
  }

  startCourse = () => {
    let id = this.props.courseDetailss.courseDetails.id;
    // localStorage.setItem("currentModule", id);

    this.props
      .startCourse(id)
      .then((res) => {
        this.continueCourse();
      })
      .catch((err) =>
        
        toast.error("Access isnt Granted", {
          position: toast.POSITION.TOP_RIGHT,
        })
      );
  };

  continueCourse = () => {
    // let id = this.props.courseDetailss.courseDetails.id;
    // localStorage.setItem("currentModule", id);
    let { current_module } = this.props.courseDetailss;
    this.props.moduleDetails(current_module).then((res) => {
      this.props.history.push("/coursestart");
    }).catch((err) => {
      let error = JSON.parse(err.response.request.response);
      this.setState({
        isRequesting: false,
        error:
          error.non_field_errors != undefined
            ? error.non_field_errors[0]
            : undefined,
      });
      toast.error(this.state.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };

  gotoModule = (modId) => {
    // debugger;
    localStorage.setItem("currentModule", modId);
    this.props.moduleDetails(modId).then((res) => {
      this.props.history.push("/coursestart");
    }).catch((err) => {
      let error = JSON.parse(err.response.request.response);
      this.setState({
        isRequesting: false,
        error:
          error.non_field_errors != undefined
            ? error.non_field_errors[0]
            : undefined,
      });
      toast.error(this.state.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  };

  openPaymentModal = () => {
    this.setState({ payment: true });
  };

  handlePaymentResponse = (response, fail) => {
    const { err } = response;
    if (fail) {
      toast.error("Payment Failed. Try Again Later", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.resetModal();
    } else {
      if (response.data.status == 500) {
        toast.error("Do not support this Card", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (response.data.status == 501) {
        toast.error("Insufficient Funds", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.enrollCourse();
      }
    }
  };

  resetModal = () => {
    window.location.reload();
  };

  setPayEmail = (e) => {
    this.setState({
      inputemail: e.target.value,
    });
  };
  enrolNow = () => {
    const { courseDetails } = this.props.courseDetailss;
    if (this.props.auth.isAuthenticated) {
      // this.openModa()
    }
    else {
      history.push({
        pathname: `/register?courseId=${courseDetails.id}`,
        state: {
          redirect: '/public-coursedetails',
          course: this.props.courseDetailss.courseDetails
        }

      })
    }
    console.log("Sent Props:", this.props.courseDetailss.courseDetails)
  }

  handleInternalCoursePayment=()=>{
    const {courseDetails}=this.props.courseDetailss;
    const {id}=courseDetails;
    let url = "/api/student/pay/"
    let data={
        course:id,
        full_pay:true
    }
    this.setState({isRequesting:true})
    axios.post(url,data).
    then((res)=>{
      console.log("Payment Resonse for Interal course: ",res.data)
      this.setState({isRequesting:false})

      axios
      .get("/api/course/detail_by_id/" + id + "/")
      .then((res) => {
        console.log("Course Overview Response: ", res.data);
        this.props.courseDetails(res.data);
      });
    }).
    catch((err)=>{
      console.log("Payment error for interal course:",err.response)
      this.setState({isRequesting:false})
    })


  }



  render() {
    const {
      modules,
      courseDetails,
      current_module,
    } = this.props.courseDetailss;
    const isDummy = JSON.parse(localStorage.getItem("isDummy"));
    const { thresold_amount } = this.props.auth.profile;
    const { isAuthenticated } = this.props.auth;
    const {intro_video_link,
      video_file_link,
      id,
      is_active,
      status,
      is_enrolled,
      price,
      is_internal,
    } = courseDetails;
    const paymentPartial = price > thresold_amount;
    const { isRequesting, inputemail, paymentSucess, isCardSaved,stripeKey } = this.state;

    const top = {
      top: "0%",
    };
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            <div className="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container" style={{ backgroundColor: "#F4F4F4" }}>
          {!isAuthenticated && <PublicHeader />}
          <div className="course-insights">
            <div className="course-video">
              <Player
                src={intro_video_link}
                poster={require("../../assets/poster.jpg")}
              >
                <ControlBar autoHide={false} disableDefaultControls={true}>
                  <Shortcut clickable={false} shortcuts={this.newShortcuts} />
                  <PlayToggle className="play-toggle-course" />
                </ControlBar>
              </Player>
            </div>
            <div className="description">
              <div className="course-name caps">{courseDetails.name}</div>
              <div className="author">WITH {courseDetails.instructor}</div>
              <div className="summary">
                {courseDetails.description}
                <br />
                <br />
                <div>
                  <div>
                    <b>Duration</b>: {courseDetails.duration_in_weeks} weeks
                  </div>
                  <div>
                    <b>Modules</b>: {courseDetails.total_module}
                  </div>
                  <div>
                    <b>Cost:</b> {courseDetails.course_price}
                  </div>
                  <div>
                    <b>Credit</b>: {courseDetails.credit}
                  </div>
                  <div>
                    <b>Instructor</b>:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {courseDetails.instructor},{" "}
                    </span>
                    {courseDetails.designation}
                  </div>
                </div>
                <div className="begin">
                  {courseDetails.is_enrolled ? (
                    courseDetails.is_started ? (
                      <button
                        className={`${
                          status !== "On Hold" &&
                            status !== "Stop" &&
                            is_active
                            ? ""
                            : "disabled"
                          }`}
                        onClick={this.continueCourse}
                      >
                        Go to Course
                        <span>
                          <i className="fa fa-angle-right fa-sm"></i>
                        </span>
                      </button>
                    ) : (
                        <Fragment>
                          <button
                            className={`${
                              status !== "On Hold" &&
                                status !== "Stop" &&
                                is_active
                                ? ""
                                : "disabled"
                              }`}
                            onClick={this.startCourse}
                          >
                            {/* Start this course */}
                            Go to Course
                          <span>
                              <i className="fa fa-angle-right fa-sm"></i>
                            </span>
                          </button>
                        </Fragment>
                      )
                  ) : (
                      <button
                        data-toggle="modal"
                        data-target={(isCardSaved && !is_internal) ? "#savedCards" :(!isCardSaved && !is_internal)? "#enrollModal":""}
                        onClick={!is_internal?this.enrolNow:this.handleInternalCoursePayment}
                        className={(isDummy == is_internal)  ? "" : "disabled" }
                      >
                        Enrol Now
                        <span>
                          <i className="fa fa-angle-right fa-sm"></i>
                        </span>
                      </button>
                    )}
                  {!is_active &&
                    status !== null &&
                    paymentPartial &&
                    !isNaN(price - this.state.registrationFees) && (
                      <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                                Oops! It looks like we are still waiting for
                                your final payment of $
                                {price - this.state.registrationFees} CAD.
                                Please provide this payment for access to this
                                course.
                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
                      </Whisper>
                    )}
                  {status == "On Hold" || status == "Stop" ? (
                    <Whisper
                      trigger={this.state.trigger}
                      speaker={
                        <Tooltip visible>
                          <div>
                            <h4>Message</h4>
                            <p>
                              Please Contact your Admin to start your course
                            </p>
                          </div>
                        </Tooltip>
                      }
                    >
                      <RiErrorWarningLine
                        size={24}
                        className="action-btn-info"
                      />
                    </Whisper>
                  ) : (
                      <div />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

       {!_.isEmpty(courseDetails)? <div className="course-overview">
          <h2>Course overview</h2>
          {modules.length > 0 && modules.map((el, index) => {
            return (
              <div key={index} className={status == "Enrolled" || status == "Stop" || status == "On Hold" || status == null &&
                !is_active ? "modules disabled-modules" : "modules"}>
                <div className="p-l-r">
                  <div className="num">MODULE {index + 1}</div>
                  {el.is_complete || current_module == el.id ? (
                    <div
                      className="heading ptr non-cap"
                      onClick={() => status == "Enrolled" || status == "Stop" || status == "On Hold" || status == null &&
                        !is_active ? undefined : this.gotoModule(el.id)}
                    >
                      {el.name}
                    </div>
                  ) : (
                      <div className="heading non-cap">{el.name}</div>
                    )}

                  <div className="summary">{el.description}</div>
                  <div className="stats">
                    <div className="stat">
                      <div className="stat-label">Total Lessons</div>
                      <div className="stat-value">{el.total_lesson}</div>
                    </div>
                    <div className="stat">
                      <div className="stat-label">Days to Complete</div>
                      <div className="stat-value">{el.days}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
           <div className="instructor-detail" >
             {(courseDetails?.instructor_detail?.description!==null)&&
        <Info 
        title="Instructor Detail" 
        type={courseDetails.instructor_detail.type}
        info={courseDetails.instructor_detail.description} 
        file={courseDetails.instructor_detail.file} />}
        </div>
        </div>:history.push('/')}

       

        {/* Modals related to enroll Now */}

        {paymentSucess && (
          <div
            className="modal enroll show"
            tabIndex="-1"
            role="dialog"
            id="paymentModal"
            style={{ top: "10%" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginBottom: "1rem" }}>
                      <h3>{this.state.updateCard ? "Card Updated Successfully !" : "Payment was Successful !"}</h3>
                    </div>
                    <div style={{ marginBottom: "2rem" }}>
                      <img src={tick} width="150" height="150" alt="sucess" />
                    </div>

                    <div>
                      <button
                        className="hollow"
                        onClick={() => this.setState({ paymentSucess: false })}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(!courseDetails.is_enrolled && isAuthenticated) && (
          <div
            className="modal enroll"
            tabIndex="-1"
            role="dialog"
            id="enrollModal"
            style={{ top: "0%" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <div className="modal-body">
                  <div className="payments">
                    {/* <h3>
                      The registration fee for this program is
                      {paymentPartial
                        ? `$${this.state.registrationFees} CAD`
                        : courseDetails.course_price}
                      . You can make your paymentt here.
                    </h3> */}
                    {this.state.payError != null && (
                      <div className="payment-message">
                        <RiErrorWarningLine
                          size={20}
                          className="payment-msg-icon"
                        />
                        <p>
                          <span>Error.</span>
                          {this.state.payError}
                        </p>
                      </div>
                    )}

                    <div>
                      {paymentPartial && (
                        <div
                          className="alert alert-warning"
                          role="alert"
                          style={{ fontSize: "14px" }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
                          </span>
                          The registration fee for this program is
                          {paymentPartial
                            ? ` $${this.state.registrationFees} CAD`
                            : courseDetails.course_price}
                          . You can make your payment here.
                        </div>
                      )}
                      <div className="mb-24">
                        <label htmlFor="Fees">Course Fees</label>
                        <input
                          type="text"
                          id="Fees"
                          value={courseDetails.course_price}
                          placeholder="enter your email"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="mb-24">
                        <label htmlFor="Fees">Amount to pay</label>
                        <input
                          type="text"
                          id="Fees"
                          value={
                            is_internal?"$0.00 CAD": paymentPartial
                              ? `$${this.state.registrationFees} CAD`
                              : courseDetails.course_price
                          }
                          placeholder="enter your email"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="stripe">
                      {stripeKey!==null&& <Elements stripe={loadStripe(stripeKey)}>
                          <CardForm
                            paymentPartial={paymentPartial}
                            id={id}
                            handlePaymentResponse={this.handlePaymentResponse}
                            handlePayError={(e) => this.handlePayError(e)}
                          />
                        </Elements>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        {(!courseDetails.is_enrolled && isAuthenticated && isCardSaved) &&
          <div>
            <SavedCard
              fees={paymentPartial ? `$${this.state.registrationFees} CAD` : courseDetails.course_price}
              fullPay={paymentPartial}
              courseDetails={courseDetails}
              currentModule={current_module}
              paymentSuccess={this.paymentSuccess}
              payFromSavedCard={this.props.payFromSavedCard}
              enrollCourse={this.enrollCourse}
              updateCardTrue={this.updateCardTrue}
              updateCardFalse={this.updateCardFalse}
              handlePaymentResponse={this.handlePaymentResponse}
              handlePayError={(e) => this.handlePayError(e)}
              payError={this.state.payError}
              registrationFees={this.state.registrationFees}
            />
          </div>

        }
      {!this.props.auth.isAuthenticated&&<Footer/>}
      </Fragment>
    );
  }
}

Shortcut.propTypes = {
  // Allow click to play/pause, by default true
  clickable: PropTypes.bool,
  // Allow double click to toggle fullscreen state, by default true
  dblclickable: PropTypes.bool,
  // Add your own shortcuts
  shortcuts: PropTypes.array,
  disableCompletely: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  courseDetailss: state.courseDetails,
});

export default connect(mapStateToProps, {
  moduleDetails,
  enrollCourse,
  startCourse,
  courseDetails,
  checkSavedCard,
  payFromSavedCard
})(CourseOverview);
