import React, { Component, Fragment } from "react";
import logo from "../../assets/logo.png";
import TextFieldGroup from "../common/TextFieldGroup";
import { toast } from "react-toastify";
import axios from "axios";

export default class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      newPassword: "",
      confirmPassword: "",
      error: null,
      token: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    axios
      .post("/api/password_reset/validate_token/", {
        token,
      })
      .then((res) => {
        this.setState({ token });
      })
      .catch((err) => {
        toast("Token Expired", err);
      });
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.newPassword !== this.state.confirmPassword) {
      toast.error("Please enter same  password ", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const passwordData = {
      password: this.state.newPassword,
      token: this.state.token,
    };

    axios
      .post("/api/password_reset/confirm/", passwordData)
      .then((res) => {
        this.props.history.push("/login");
        toast.error("Password Changed Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error("Password Reset Failed. Token Expired. Try Again", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.history.push("/login");
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    const { error, newPassword, confirmPassword } = this.state;

    return (
      <Fragment>
        <div className="login">
          <div className="container" style={{ textAlign: "center" }}>
            {error && <div className="error">{error}</div>}
            <div className="row">
              <div className="col-md-5 m-auto">
                <img
                  src={logo}
                  className="logo"
                  alt="logo niwe"
                  width="150"
                  height="80"
                  style={{ marginBottom: "2.5rem" }}
                />
                <br />
                <p className="lead text-center">Reset your account password</p>
                <form onSubmit={this.onSubmit}>
                  <TextFieldGroup
                    placeholder="New Password "
                    name="newPassword"
                    maxlength={18}
                    minlength={8}
                    pattern="^([0-9]{10})$"
                    type="password"
                    value={newPassword}
                    onChange={this.onChange}
                  />

                  <TextFieldGroup
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    maxlength={18}
                    minlength={8}
                    type="password"
                    value={confirmPassword}
                    onChange={this.onChange}
                  />
                  <div className="signIn">
                    <button className={` hollow ${
                      confirmPassword===newPassword&&
                      newPassword.length > 7
                        ? ""
                        : "disabled"
                    } `} type="submit  ">
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
