import React, { useEffect, useState, Fragment } from "react";
import Navbar from "../../shared/Navbar/navbar";
import coursePic from "../../assets/course.PNG";
import axios from "axios";
import { connect } from "react-redux";
import history from "../../helper/History"
import loader from "../../assets/loader.gif"
import { courseDetails, getTopCourses, publicCourseDetails } from "../../actions/courseAction"

function TopCourse({ publicCourseDetails, getTopCourses, topCourses }) {
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [categories, setCategories] = useState([])
    const [active, setActive] = useState(0)
    const [search, setSearch] = useState('')
    const [categoryData, setCategoryData] = useState([])
    const openCourse = (course) => {
        console.log("Adtya", course);
        setLoading(true)
        publicCourseDetails(course)
            .then((res) => {
                console.log("Top Res Data: ",res)
                history.push({
                    pathname:`/public-coursedetails`,
                    state:{isValid:true}
            });
                setLoading(false)
            })
    };

    useEffect(() => {
        getTopCourses()
    }, [])

    useEffect(() => {
        if (topCourses !== undefined && topCourses.length > 0) {
            setCourses(topCourses)
        }
    }, [topCourses])

    const toggleCourse = (ID) => {
        setLoading(true)
        if (ID > 0) {
            let categories = categoryData.slice();
            let category = categories[ID - 1].id;
            axios
                .get("/api/course/courselist/?category=" + category)
                .then((res) => {
                    setActive(ID)
                    setCourses(res.data)
                    setLoading(false)
                })
                .catch((err) => console.log("err", err));
        } else {
            this.getAllCourses();
        }
    };

    const renderCourses = (courses) => {
        return courses.map((course, index) => {
            return (
                <div className="card" onClick={() => openCourse(course)} key={index} >
                    <div className="card-body">
                        <img
                            src={course.course_logo != null ? course.course_logo : coursePic}
                            alt="course-pic"
                            height="300"
                            style={{ width: "100%", borderRadius: "5px" }}
                        />
                        <h5 className="card-title caps">{course.name}</h5>
                        <p className="card-text">{course.description}</p>
                        <span className="learn-more">Learn more</span>
                    </div>
                </div>
            );
        });
    };
    return (
        <Fragment>
            {loading && (
                <Fragment>
                    <div class="loader">
                        <img src={loader} alt="loader" />
                    </div>
                </Fragment>
            )}
            <div className="dashboard browse-course mt-5">
                <div className="welcome">
                    Top Courses
                   
                </div>
                <Navbar
                    navLinks={categories}
                    activeNav={active}
                    changeOver={toggleCourse}
                />
                <div class="x course-container">

                    <Fragment>
                        {courses.length > 0 ?
                            renderCourses(courses) : <h1>Course coming soon!</h1>}
                    </Fragment>


                </div>
                <br />
                <br />
            </div>
        </Fragment>
    )
}
const mapStateToProps = state => {
    return {
        topCourses: state.courseDetails.topCourses
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, { getTopCourses, courseDetails, publicCourseDetails })(TopCourse);