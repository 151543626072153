/** @format */

import React, { Component, Fragment } from "react";
import Lesson from "./lesson";
import "./course.scss";
import Stepper1 from "../../shared/Stepper/stepper";
import { connect } from "react-redux";
import {
  startLesson,
  moduleDetails,
  getQuizInstructs,
  getExamInstructs,
} from "../../actions/courseAction";
import { toast } from "react-toastify";
import history from "../../helper/History";
import { Whisper, Tooltip } from "rsuite";
import { RiErrorWarningLine, BsArrowLeft, BsArrowRight } from "react-icons/all";
import _ from "lodash"
import { isMobile } from "react-device-detect"
import axios from 'axios'
class Lessons extends Component {
  constructor() {
    super();
    this.state = {
      lessonNo: 0,
      videoClicked: false,
      navBack: false,
    };
  }

  async componentDidMount() {
    const { moduleDetails } = this.props.courseDetails;
    const currentLesson = moduleDetails.lesson[this.state.lessonNo];


    let cm = localStorage.getItem("currentModule");
    this.props.moduleDetails(cm)


    this.setState({
      lessonNo: this.props.courseDetails.moduleDetails.current_lesson.index - 1,
    });

    this.setState({
      navBack: false
    })
    console.log("History Props: ", history);
    console.log("Main Lesson: ", this.props);
    console.log("Current Lessson ID: ", currentLesson);
    console.log("Current Lessson No: ", this.state.lessonNo);

    if (currentLesson !== undefined) {
      this.buttonValidation(currentLesson.id)
    }

    if (isMobile) {
      this.setState({ trigger: ['focus', 'click', 'active'] })
    } else {
      this.setState({ trigger: ['hover', 'focus', 'click', 'active'] })
    }

  }

  markMediaAsClicked = (lesson, src) => {
    let cm = localStorage.getItem("currentModule");
    let data = {
      [lesson.lesson_content_1 === src ? 'is_content1_seen' : 'is_content2_seen']: true
    }

    axios.patch(`/api/student/track_lesson_content/${lesson.lesson_track_id}/`, data)
      .then((res) => {
        this.props.moduleDetails(cm)
      })

  }

  videoClickedTrue = async (id, lesson, src) => {
    console.log("Id: ", id, "lesson: ", lesson, "src: ", src)
    let videoClicked = await JSON.parse(localStorage.getItem('videoClicked')) || []
    console.log("Video Clicked: ", videoClicked)

    this.markMediaAsClicked(lesson, src)
  }


  //Algo to check whether video has been clicked or not
  buttonValidation = async (id) => {
    const { lessonNo } = this.state;
    const { moduleDetails } = this.props.courseDetails;
    const currentLesson = moduleDetails.lesson[lessonNo];

    let videoClicked = await JSON.parse(localStorage.getItem('videoClicked')) || []
    if (videoClicked.length > 0) {
      let condition = videoClicked.filter((item) => {
        console.log("Item ID: ", item.id, "ID: ", id)
        if ((item.id === id && item.content1 && item.content2) || (item.id === id && item.content1 && item.content2 == null) || (item.id === id && item.content1 == null && item.content2)) {
          console.log("Video has been clicked");
          this.setState({ videoClicked: true })
          return item
        } else
          this.setState({ videoClicked: false })
        console.log("Video not clicked")
      })
      if (condition.length > 0) {
        this.setState({ videoClicked: true })
      }
      // check to validate if assignment is uploaded or not
      if (currentLesson.assignment.length > 0) {
        for (let index in currentLesson.assignment) {
          if (currentLesson.assignment[index].assignment_type == "DOC") {
            for (let index2 in currentLesson.assignment[index].doc_assignment) {
              if (currentLesson.assignment[index].doc_assignment[index2].uploaded_assignment == null) {
                this.setState({ videoClicked: false })
              } else {
                this.setState({ videoClicked: true })
              }
            }
          }
        }
      }
    }
  }





  nextLesson = (currentLessonNo) => {
    console.log("Next Lesson No: ", currentLessonNo)
    let next_lesson = this.props.courseDetails.moduleDetails.lesson[currentLessonNo + 1];
    if (!next_lesson.is_user_complete) {
      this.props.startLesson(
        next_lesson,
        this.props.courseDetails.moduleDetails.current_index
      );
    }
    this.setState({ lessonNo: currentLessonNo + 1 });
  };

  backLesson = (currentLessonNo) => {
    this.setState({ lessonNo: currentLessonNo - 1 });
  };

  stepperChangedLesson = (lessonNo) => {
    this.setState({ lessonNo });
  };

  nextModule = () => {
    this.props
      .moduleDetails(this.props.courseDetails.moduleDetails.next_module.id)
      .then((res) => {
      }).catch((err) => {
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    localStorage.setItem("currentModule", this.props.courseDetails.moduleDetails.next_module.id
    );
  };

  gotToQuiz = async () => {
    await this.props
      .getQuizInstructs(this.props.courseDetails.moduleDetails.quiz)
      .then((res) => {
        this.props.history.push("/quizInstructions");
      })
      .catch((err) => {
        console.log(err)
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  gotToExam = async () => {

    await this.props
      .getExamInstructs(this.props.courseDetails.moduleDetails.course_id)
      .then((res) => {
        console.log('res--', res)
        this.props.history.push("/examInstructions");
      }).catch((err) => {
        let error = JSON.parse(err.response.request.response);
        this.setState({
          isRequesting: false,
          error:
            error.non_field_errors != undefined
              ? error.non_field_errors[0]
              : undefined,
        });
        toast.error(this.state.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

  };

  async componentDidUpdate(prevProps, prevState) {
    const { moduleDetails, module } = this.props.courseDetails;
    const { lessonNo } = this.state;
    const currentLesson = moduleDetails.lesson[this.state.lessonNo];
    let datas = undefined;
    if (prevState.lessonNo !== this.state.lessonNo) {
      let videoClicked = await JSON.parse(localStorage.getItem('videoClicked')) || []
      let cm = localStorage.getItem("currentModule");
      this.props.moduleDetails(moduleDetails.id)
      if (currentLesson !== undefined) {
        this.buttonValidation(currentLesson.id)
        console.log("else ke nadr:")
      }
    }

    if (this.props.courseDetails.courseDetails.is_all_module_complete !== true) {
      if (prevProps.courseDetails.moduleDetails.current_lesson.index < this.props.courseDetails.moduleDetails.current_lesson.index
        && this.state.navBack
      ) {
        console.log("Prev Props: Prev Module ", this.props.courseDetails)
        let cm = localStorage.getItem("currentModule");
        this.setState({ lessonNo: this.props.courseDetails.moduleDetails.current_lesson.index })
        this.props.moduleDetails(cm)
      }
    }

    if (prevProps.courseDetails.moduleDetails.index !== this.props.courseDetails.moduleDetails.index) {
      let cm = localStorage.getItem("currentModule");
      this.props.moduleDetails(cm)
      window.location.reload()
    }

  }

  navigateToPreviousModule = (id) => {
    this.props.moduleDetails(id)
    localStorage.setItem('currentModule', id)
    this.setState({ navBack: true })

  }

  render() {
    const { lessonNo, videoClicked } = this.state;
    // const videoClicked = JSON.parse(localStorage.getItem('videoClicked'))
    const { moduleDetails } = this.props.courseDetails;
    const { current_lesson } = moduleDetails;

    const currentLesson = moduleDetails.lesson[lessonNo];
    // const currentModule = moduleDetails
    const { subscription } = moduleDetails;
    const {
      total_lesson,
      check_all_lesson_complete,
      is_complete,
      lesson,
      is_quiz,
      is_exam_complete,
      next_module,
      // current_module_info,
      current_index,
    } = moduleDetails;
    const modContainer = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      bottom: "-12px",
      zIndex: "10",
    };
    return (
      <div className="container ">
        {/* {currentLesson && this.videoClickedFalse(currentLesson.id)} */}
        <div>
          <div style={modContainer}>
            {/* <BsArrowLeft size={30} /> */}
            <div className="mod">
              {"MODULE "}
              {moduleDetails.index == undefined ? 1 : moduleDetails.index}
            </div>
            {/* <BsArrowRight size={30}/> */}
          </div>
        </div>
        <div className="bg">
          <Stepper1
            activeLesson={lessonNo}
            totalSteps={lesson}
            navigateToLesson={this.stepperChangedLesson}
          />
          {currentLesson == undefined ? <p>No Lessons</p> : (
            <Lesson
              current_index={current_index}
              subscription={subscription}
              lesson={currentLesson}
              isVideoClicked={videoClicked}
              videoClickedTrue={this.videoClickedTrue}
              currentLessonId={currentLesson.id}
              buttonValidation={this.buttonValidation}
              getModuleDetails={this.props.moduleDetails}
            />
          )}
          <div className="course-nav-btns">
            <div>
              {lessonNo !== 0 && (
                <button
                  className="hollow"
                  onClick={() => this.backLesson(lessonNo)}
                >
                  <span>
                    <i className="fa fa-angle-left fa-sm"></i>
                  </span>
                  Previous
                </button>
              )}
              {moduleDetails.previous_module.id !== null && currentLesson !== undefined && currentLesson.index == 1 ? (
                <button
                  className="hollow"
                  onClick={() => this.navigateToPreviousModule(moduleDetails.previous_module.id)}
                >
                  <span>
                    <i className="fa fa-angle-left fa-sm"></i>
                  </span>
                  Previous Module
                </button>
              ) : <div />}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {total_lesson - 1 > lessonNo && (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={`${currentLesson.is_user_complete ? "" : "disabled"}`}
                      onClick={() => {
                        this.nextLesson(lessonNo);
                      }}
                    >
                      Next
                      <span>
                        <i className="fa fa-angle-right fa-sm"></i>
                      </span>
                    </button>

                    {(currentLesson.is_user_complete) ? <div /> : (
                      <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                                {"Please Go through your lesson first."}
                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
                      </Whisper>
                    )}
                  </div>
                </Fragment>
              )}
              {(!is_quiz || is_complete) &&
                next_module.index &&
                lessonNo === total_lesson - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={`${check_all_lesson_complete ? "" : "disabled"
                        }`}
                      onClick={this.nextModule}
                    >
                      Next Module
                    </button>

                    {!check_all_lesson_complete ? (
                      <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                                The Module is not available to you at this time. It will be available once your professor reviews your assignments.
                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
                      </Whisper>
                    ) : <div />}
                  </div>
                )}
              {lessonNo === total_lesson - 1 &&
                is_complete &&
                next_module.id == null &&
                is_exam_complete && (
                  <button onClick={() => this.props.history.push("/dashboard")}>
                    Go to dashboard
                  </button>
                )}
              {lessonNo === total_lesson - 1 &&
                next_module.id == null &&
                !is_exam_complete &&
                !is_quiz && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={this.gotToExam}
                      className={is_complete && currentLesson.is_user_complete ? "" : "disabled"}
                    >
                      Go to Exam
                    </button>

                    {!is_complete || !currentLesson.is_user_complete ? (
                      <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                                {!currentLesson.is_user_complete ? "Please Go through your lesson first." : "The Exam is not available to you at this time.It will be available once your professor reviews your assignments."}

                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
                      </Whisper>
                    ) : <div />}
                  </div>
                )}

              {lessonNo === total_lesson - 1 && !is_complete && is_quiz && (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={`${check_all_lesson_complete && currentLesson.is_user_complete ? "" : "disabled"
                        }`}
                      style={{ marginLeft: "24px" }}
                      onClick={this.gotToQuiz}
                    >
                      Go to Quiz
                    </button>
                    {!check_all_lesson_complete || !currentLesson.is_user_complete || !currentLesson.is_user_complete ? (
                      <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                                {!currentLesson.is_user_complete && currentLesson.assignment.length > 0 ?
                                  "You need to attempt all assignments before you go to Quiz." : "Please attend the lesson video before proceeding."}

                                {/* {(currentLesson.is_user_complete && !videoClicked) &&
                                  (currentLesson.lesson_content_1_type!==null && currentLesson.lesson_content_1_type=="video") || (currentLesson.lesson_content_2_type!==null && currentLesson.lesson_content_2_type=="video")
                                  ? 
                                  "Please attend the lesson video before proceeding.":"Please go through your lesson first."} */}

                                {!check_all_lesson_complete &&
                                  "The Quiz is not available to you at this time.It will be available once your professor reviews your assignments."}
                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
                      </Whisper>
                    ) : <div />}
                  </div>
                </Fragment>
              )}
              {/* {lessonNo === total_lesson - 1 &&
                check_all_lesson_complete &&
                is_complete &&
                !is_quiz && (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <button onClick={this.gotToExam}>Go to Exams</button>
                    </div>
                  </Fragment>
                )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, {
  startLesson,
  getQuizInstructs,
  getExamInstructs,
  moduleDetails,
})(Lessons);
