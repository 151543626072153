import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { startExam } from "../../actions/courseAction";

class ExamStart extends Component {

    startExam = () => {
     const { id, course}=this.props.courseDetails.examInstructions[0]
     let obj ={
       course,
       exam:id
     }
    this.props.startExam(obj).then((res)=>{
      this.props.history.push("/exam");
    })
  };

  render() {
      const { name , description }= this.props.courseDetails.examInstructions.length>0&&this.props.courseDetails.examInstructions[0]
    // const name = this.props.courseDetails.moduleDetails.course;
    // const description ="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
    return (
      <Fragment>
        <div className="container">
          <div className="quiz-instructions">
           {name!==undefined? <>
            <h1 className="exam-heading">{name}</h1>
            <p className="exam-info">{description}</p>
            {/* <p className="exam-info">{time_allowed} mins</p> */}
            <button onClick={this.startExam} >Start Exam</button>
            </>:
            <>
            <h1 className="exam-heading">Exam is not Available for the moment.</h1>

            </>
            
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps,{startExam})(ExamStart);
