import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './common.scss';

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  type,
  onChange,
  disabled,
  minlength,
  maxlength,
  style,
  readOnly
}) => {
  return (
    <div className="form-group">
      <input
        type={type}
        className={classnames('form-control form-control-lg input-field', {
          'is-invalid': error
        })}
        minLength={minlength}
        maxLength={maxlength}
        required
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={style}
        // readOnly={readOnly}
      />
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string
};

TextFieldGroup.defaultProps = {
  type: 'text',
  minlength:1,
  maxlength:100
};

export default TextFieldGroup;
