import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Document, Page,pdfjs } from 'react-pdf';
import {AiOutlineLeft,AiOutlineRight,AiOutlineClose} from "react-icons/all"
import {IconButton} from "rsuite"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import doc from "../../assets/diploma.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer({ link,id,closeResource }) {


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function getDomainName(hostName) {
        return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    }


    const prevPage=()=>{
        if(pageNumber>1){
            setPageNumber((prev)=>--prev)
        }
    }
    const nextPage=()=>{
        if(pageNumber<numPages){
            setPageNumber((prev)=>++prev)
        }
    }

   

    useEffect(()=>{
        console.log("Domain Name: ",link)
    },[])

    return (
        <div className="pdf-viewer close" id={`pdf-viewer-${id}`} >
            <div className="pdf-viewer__overlay" >
                <div className="pdf-viewer__body">
                    <div className="pdf-viewer__body__content" >
                    <div className="pdf-viewer__body__closeBtn" onClick={()=>closeResource(id)} >
                    <AiOutlineClose size={22} />
                    </div>
                <Document onLoadError={(err)=>console.log("Error: ",err)}  file={link} onLoadSuccess={onDocumentLoadSuccess} >
                <Page pageNumber={pageNumber} />
            </Document>
            <div className="pdf-viewer__pageChanger" >
                <div className="pdf-viewer__pageChanger__box" >
                <IconButton icon={<AiOutlineLeft/>} onClick={prevPage} disabled={pageNumber==1} />
                <p>Page {pageNumber} of {numPages}</p>
                <IconButton icon={<AiOutlineRight/>} onClick={nextPage} disabled={pageNumber==numPages}  />
                </div>
            </div>
                    </div>
               
                </div>
           
            </div>
         
        </div>
    )
}



PDFViewer.propTypes = {
    link: PropTypes.string.isRequired,
    id:PropTypes.any,
    openResource:PropTypes.func.isRequired,
    closeResource:PropTypes.func.isRequired
}