import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_PROFILE,
  CLEAR_STORE,
  RESET_PROFILE,
  PAYMENT_COMPLETE,
  RESET_PASSWORD,
  GET_LANDING_DETAIL_SUCCESS
} from "./types";

// Register User
export const registerUser = (userData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/student/register/", userData)
      .then((res) => {
        const token = res.data.token;
        setAuthToken(token);
        resolve();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        reject(err);
      });
  });
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/login/", userData)
      .then(async (res) => {
        // Save to localStorage
        const token = res.data.access;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        localStorage.setItem('isDummy', res.data.is_dummy)
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        await dispatch(setCurrentUser(decoded));
        await dispatch(setProfile(res.data));
        resolve('logged in')
      })
      .catch((err) => {
        reject(err)
      });
  });
};

export const getLandingDetail = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/landing_page/configure/')
      .then((res) => {
        dispatch({ type: GET_LANDING_DETAIL_SUCCESS, payload: res.data })
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem('isDummy');
  localStorage.removeItem('videoClicked');
  // Remove auth header for future requests
  setAuthToken(false);
  dispatch({
    type: CLEAR_STORE,
  });
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(resetProfile());
};

export const setProfile = (data) => {
  return {
    type: SET_PROFILE,
    payload: data,
  };
};

export const resetProfile = () => {
  return {
    type: RESET_PROFILE
  };
};

export const paymentComplete = () => (dispatch) => {
  dispatch({
    type: PAYMENT_COMPLETE,
  });
};

export const resetPassword = (token) => dispatch => {
  dispatch({
    type: RESET_PASSWORD,
  });
}
