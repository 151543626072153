/** @format */

import React, { Component, Fragment,createRef } from "react";
import zoom from "../../assets/zoomicon.png";
import filedownload from "../../assets/filedownload.png";
import "./course.scss";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { connect } from "react-redux";
import {
  userAssignmentComplete,
  moduleDetails, uploadAssignment
} from "../../actions/courseAction";
import { Player, ControlBar, Shortcut, PlayToggle } from "video-react";
import PropTypes from "prop-types";
import history from "../../helper/History";
import moment from "moment";
import { Button, IconButton, Icon, Uploader } from "rsuite";
import { RiDownloadCloud2Line, FiUploadCloud } from "react-icons/all";
import axios from "axios";
import _ from "lodash"
import { ProgressBar } from "react-bootstrap"
import { photos } from "../../contants/images";
import { Whisper, Tooltip } from "rsuite";
import {isMobile} from "react-device-detect"
import { RiErrorWarningLine,AiOutlineLink } from "react-icons/all";
import PDFViewer from "./PDFViewer"
class Lesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      disabled: false,
      submit: {},
      submitAr: [838],
      uploadFile: null,
      uploaded:false,
      uploadId:[],
      singleUploadID:0,
      as: false,
    };
    this.player1Ref=createRef();
    this.player2Ref=createRef();
    this.videoPlayer1Ref=createRef();
    this.videoPlayer2Ref=createRef();  
    this.newShortcuts = [
      {
        keyCode: 39, // Right arrow
        ctrl: false, // Ctrl/Cmd
        handle: (player, actions) => {
          if (!player.hasStarted) {
            return;
          }
          const operation = {
            action: "forward-0",
            source: "shortcut",
          };
          actions.forward(0, operation); // Go forward 30 seconds
        },
      },
    ]
  }

  async componentDidMount() {
    const lesson = this.props.lesson;
    console.log("lesson submit asin: ", lesson);
    let videoClicked = await JSON.parse(localStorage.getItem('videoClicked')) || []
    if (videoClicked.length > 0) {
      console.log("Lesson has no media, Previous media: ", videoClicked)
      const media = _.filter(videoClicked, { id: lesson.id })
      const newMedia = _.remove(videoClicked, (n) => { return n.id !== lesson.id })
    }
    if (lesson.lesson_content_1 == null && lesson.lesson_content_2 == null) {
      this.props.videoClickedTrue(lesson.id, lesson, null)
    }

    if (isMobile) {
      this.setState({ trigger: ['focus', 'click', 'active'] })
    } else {
      this.setState({ trigger: ['hover', 'focus', 'click', 'active'] })
    }
  }


  componentWillMount() {
    if (history.location.state) {
    }
    let cm = localStorage.getItem("currentModule");
    console.log("History props inleson: ", history);
  }


  componentDidUpdate(prevProps) {
    const lesson = this.props.lesson;
    if (prevProps.lesson.id !== this.props.lesson.id) {
      if (lesson.lesson_content_1 == null && lesson.lesson_content_2 == null) {
        this.props.videoClickedTrue(lesson.id, lesson, null)
      }
    }
  }



  markAssignmentComplete = (assignment) => {
    let cm = localStorage.getItem("currentModule");

    let obj = {
      current_index: this.props.current_index,
      assignment_id: assignment.id,
      subscription: this.props.subscription,
      lesson_id: this.props.lesson.id,
    };
    this.props.userAssignmentComplete(obj, this.props.lesson.index);
    axios.post("/api/student/submit_assignment/", obj).then((res) => {
      console.log("markasingmm lk: ", res.data);
      this.setState({
        submit: res.data,
      });
      this.setState({
        submitAr: [...this.state.submitAr, res.data.assignment_id],
      });
      this.props.moduleDetails(cm)
      console.log("Submit Array: ", this.state.submitAr);
      console.log("Submit  dddd: ", this.state.submit);
    });
    this.setState({ disabled: true });
  };



  callModule = (src,player) => {
    const lesson = this.props.lesson;

    if(player=="player-1" && lesson.lesson_content_2!==null ){
      if(lesson.lesson_content_2_type=="audio"){
        this.player2Ref.current.audio.current.pause()
      }else{
        this.videoPlayer2Ref.actions.pause()
        console.log("VIdeo pLyaer methed: ",this.videoPlayer2Ref)
        
      }  
    }
    if(player=="player-2" &&  lesson.lesson_content_1!==null ){
      if(lesson.lesson_content_1_type=="audio"){
        this.player1Ref.current.audio.current.pause()
      }else{
        this.videoPlayer1Ref.actions.pause()
        console.log("VIdeo pLyaer methed: ",this.videoPlayer1Ref)
      }
      
     
    }

    let cm = localStorage.getItem("currentModule");
    this.props.moduleDetails(cm);
    console.log("Src: ", src)
    console.log("Current mdlasdk", cm);
    this.props.videoClickedTrue(this.props.lesson.id, this.props.lesson, src)
  };


  renderAssignment = (assignment, key) => {
    if (
      assignment.assignment_type === "DOC" &&
      assignment.doc_assignment.length > 0
    ) {
      return (
        <div key={key} className="assign pt-3">
          <div className="assignment">
            <div className="assignment-details">
              <div>
                <div className="mod-info caps">{assignment.name}</div>
                <div>{assignment.description}</div>
              </div>
              <div className="upload-btn-box">
                <a href={`${assignment.doc_assignment[0].doc_link}`} target="_blank"
                  onClick={() => this.markAssignmentComplete(assignment)}
                >
                  <Button appearance="primary" block style={{ width: 160 }} >
                    Download
                    <span style={{ paddingLeft: 10 }}>
                      <RiDownloadCloud2Line />
                    </span>
                  </Button>
                </a>
                {assignment.doc_assignment.length>0 &&
                  this.renderUploadSection(assignment.doc_assignment[0], assignment.is_professor_review)
                }
              </div>
            </div>
         
          </div>
          {assignment.doc_assignment[0].uploaded_assignment!==null&& 
           <div className="assignment__download-details" >
               <div>
                  <AiOutlineLink/>
                  <a href={assignment.doc_assignment[0].uploaded_assignment} target="_blank" >View Uploaded Assignment</a>
                </div> 
            </div>}
        </div>
      );
    } else if (assignment.assignment_type === "PRA") {
      return (
        <div key={key} className="pt-3">
          <div className="assignment not-upload">
            <div className="assignment-details">
              <div className="assignment-content">
                <div className="mod-info">Practical Test</div>
                <div>{assignment.description}</div>
              </div>
            </div>
            <div
              className={` prac-req ${
                assignment.is_practical_submit ||
                  this.state.submitAr.includes(assignment.id)
                  ? "disabled"
                  : ""
                }`}
            >
              <button
                style={{ width: 160 }}
                className={`${
                  assignment.is_practical_submit ||
                    this.state.submitAr.includes(assignment.id)
                    ? "disabled submitBtn"
                    : "submitBtn"
                  }`}
                onClick={() => this.markAssignmentComplete(assignment)}
              >
                {assignment.is_practical_submit ||
                  this.state.submitAr.includes(assignment.id)
                  ? "Submitted"
                  : "Submit Request"}

                <span>
                  <i className="fa fa-angle-right fa-sm"></i>
                </span>
              </button>
            </div>

          </div>
        </div>
      );
    } else if (
      assignment.assignment_type === "ZOOM" &&
      assignment.zoom_assignment.length > 0
    ) {
      return (
        <div key={key} className="pt-3">
          <div className="assignment not-upload">
            <div className="assignment-details">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="icon">
                  <img src={zoom} alt="zoom-icon" width="67" height="67" />
                </div>
                <div className="assignment-content">
                  <div className="mod-info caps">
                    Join your teacher on Zoom!
                  </div>
                  <div>{}</div>
                  <div>
                    <b>Meeting ID:</b> {assignment.zoom_assignment[0].zoom_id}
                  </div>
                  <div>
                    <b>Password:</b>{" "}
                    {assignment.zoom_assignment[0].zoom_password}
                  </div>
                  {/* <div><b>Date & Time:</b> {new Date(assignment.zoom_datetime).toGMTString().split('').slice(0,-7).join('')}</div> */}
                  <div>
                    <b>Date & Time:</b>{" "}
                    {moment(assignment.zoom_assignment[0].zoom_datetime).format(
                      "LLLL"
                    )}
                  </div>
                </div>
              </div>
              <div style={{ width: 160 }}>
                <a
                  href={assignment.zoom_assignment[0].zoom_link}
                  target="_blank"
                  onClick={() => this.markAssignmentComplete(assignment)}
                >

                  <IconButton
                    block
                    appearance="primary"
                    icon={<Icon icon="external-link" />}
                    placement="right"
                  >
                    Join Now
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    else {
      return <div>No assignment</div>;
    }

  };


  renderUploadSection = (id, checked,assignmentId) => {
    const { percent } = this.props;
    const {uploadId,singleUploadID,submitAr}=this.state;
    console.log("Download Submit Array: ",submitAr, id)
    return (
      <div className={(checked !== null && checked)?"upload-assignment uploaded":"upload-assignment"}>
        {this.state.uploadFile !== null&&singleUploadID==id.assignment_track_id && percent  > 0 ?
          <ProgressBar now={percent} label={`${percent}%`} animated />
          :
          <div className="upload-btn" >
            <Uploader appearance="primary" onUpload={(e) => this.handleUpload(e, id.assignment_track_id)} fileListVisible={false}
              accept="application/pdf,audio/mp4,audio/avi,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel"
            >
              <Button appearance="primary" block style={{ width: 160 }} 
              // disabled={!submitAr.includes(id.id)}   
              classPrefix={(!id.is_downloaded|| (checked !== null && checked))?"disabled":""}
              >
                {uploadId.includes(id.assignment_track_id)?"Uploaded":"Upload"}
                    <span style={{ paddingLeft: 10 }}>
                  <FiUploadCloud />
                </span>
              </Button>
            </Uploader>
            {(checked !== null && checked)?<Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                              This Assignment has already been evaluated and cannot be re-submittted.
                              </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
            </Whisper>:
            id.uploaded_assignment!==null?
            <Whisper
                        trigger={this.state.trigger}
                        speaker={
                          <Tooltip visible>
                            <div>
                              <h4>Message</h4>
                              <p>
                             It will overwrite your previously submitted assignment.
                             </p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <RiErrorWarningLine
                          size={24}
                          className="action-btn-info"
                        />
            </Whisper>:undefined
            
            }
          </div>}
      </div>
    )
  }



  handleUpload = (e, id) => {
    let cm = localStorage.getItem("currentModule");
    let ids=this.state.uploadId;
    ids.push(id)
    this.setState({ uploadFile: e.blobFile,uploaded:false,singleUploadID:id})

    let data = {
      id: id,
      file: e.blobFile
    }
    this.props.uploadAssignment(data)
      .then((res) => {
        this.setState({ uploadFile: null,uploaded:true,uploadId:ids })
        this.props.moduleDetails(cm).then((res)=>{
          this.props.buttonValidation(res.current_lesson.id)
          console.log("Uploaded Assignment Detail: ",res)
        });

      })
      console.log("Same iD? :",this.state.uploadId.filter((item)=>item==id))

  }

  handlePlayerOneStateChange=(state)=>{

  }

  openResource=(id)=>{
    const pdfBox=document.querySelector(`#pdf-viewer-${id}`);
    pdfBox.classList.remove('close')
  }

  closeResource=(id)=>{
    const pdfBox=document.querySelector(`#pdf-viewer-${id}`);
    pdfBox.classList.add('close')
  }

  render() {
    const lesson = this.props.lesson;
    const resource = this.props.lesson.resource;
    return (
      <Fragment>
        <div className="content">
          <h1 className="course-heading caps">
            {this.props.courseDetails.courseDetails.name}
          </h1>
          <div className="lesson caps bold mb-0">{lesson.name}</div>
          <p className="mt-2" >{lesson.description}</p>

          {lesson.content_1_description !== null && lesson.content_1_description !== '' &&
            <div style={{paddingTop:"3em"}}>
              <div className="title">INFO</div>
              <div className="description">{lesson.content_1_description}</div>
            </div>}
          <div>
            {lesson.lesson_content_1_type == 'video' && <div className="video">
              <div
                className="lesson-player pt-3"
                onClick={() => this.callModule(lesson.lesson_content_1,'player-1')}
                style={{ marginBottom: 15 }}
              >
                <Player
                  ref={player=>{this.videoPlayer1Ref=player}}
                  // ref={this.videoPlayer1Ref}
                  src={lesson.lesson_content_1}
                  videoId="video-1"
                  fluid
                  poster={require("../../assets/poster.jpg")}
                >
                  <ControlBar autoHide={false} disableDefaultControls={false}  >
                    <Shortcut clickable={false} shortcuts={this.newShortcuts} />
                    <PlayToggle />
                  </ControlBar>
                </Player>
              </div>
            </div>}
            {lesson.lesson_content_1_type == 'audio' &&
              <div className="audio-player">
                <div className="audio-player__logo" >
                  <img src={photos.poster} />
                </div>
                <AudioPlayer 
                ref={this.player1Ref}
                src={lesson.lesson_content_1} autoPlayAfterSrcChange={false} onPlay={() => this.callModule(lesson.lesson_content_1,"player-1")} />
              </div>
            }

            {lesson.content_2_description !== null && lesson.content_2_description !== '' &&
              <div className="py-3">
                <div className="title">INFO</div>
                <div className="description">{lesson.content_2_description}</div>
              </div>}
            {lesson.lesson_content_2_type == 'video' && <div className="video">
              <div
                className="lesson-player p-2"
                onClick={() => this.callModule(lesson.lesson_content_2,'player-2')}
                style={{ marginBottom: 15 }}
              >
                <Player
                  ref={player=>{this.videoPlayer2Ref=player}}
                  // ref={this.videoPlayer2Ref}
                  src={lesson.lesson_content_2}
                  fluid
                  videoId="video-2"
                  poster={require("../../assets/poster.jpg")}
                  
                  >
                  <ControlBar autoHide={false} disableDefaultControls={true}>
                    <Shortcut clickable={false} shortcuts={this.newShortcuts} />
                    <PlayToggle  />
                  </ControlBar>
                </Player>
              </div>
            </div>}
            {lesson.lesson_content_2_type == 'audio' &&
              <div className="audio-player">
                <div className="audio-player__logo" >
                  <img src={photos.poster} />
                </div>
                <AudioPlayer 
                ref={this.player2Ref}
                src={lesson.lesson_content_2} autoPlayAfterSrcChange={false} onPlay={() => this.callModule(lesson.lesson_content_2,"player-2")} />
              </div>}
          </div>
          {lesson.assignment.length > 0 && (
            <div className="title pt-5">YOUR ASSIGNMENTS</div>
          )}
          {lesson.assignment.map((el, index) =>
            this.renderAssignment(el, index)
          )}

          {lesson.resources && (
            <>
              <div className="title pt-5">RESOURCES</div>
              <div className="description">{lesson.resources}</div>
            </>
          )}
          {resource.map((res, index) => {
            return (
              <div key={index} style={{ marginTop: 15 }}>
                <div className="assignment not-upload">
                  <div className="assignment-details">
                    <div>
                      <div className="mod-info caps">{res.name}</div>
                      <div>{res.description}</div>
                    </div>
                    {res.resource_type=="link"?
                    <div style={{ width: 160 }}>
                      <a href={`${res.doc_link}`} target="_blank">
                        <IconButton
                          block
                          appearance="primary"
                          icon={<Icon icon="external-link" />}
                          placement="right"
                        >
                          View Resource
                        </IconButton>
                      </a>
                    </div>:
                     <div style={{ width: 160 }}>
                       <IconButton
                         block
                         appearance="primary"
                         icon={<Icon icon="file-pdf-o" />}
                         placement="right"
                         onClick={()=>this.openResource(index)}
                       >
                         View Resource
                       </IconButton>
                   </div>
                    }
                  </div>
                </div>
               {res.resource_type=="file"&& 
               <PDFViewer 
               id={index} 
               link={res.resource_file} 
               type={res.resource_type}  
               closeResource={this.closeResource}
               />}
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}
Shortcut.propTypes = {
  // Allow click to play/pause, by default true
  clickable: PropTypes.bool,
  // Allow double click to toggle fullscreen state, by default true
  dblclickable: PropTypes.bool,
  // Add your own shortcuts
  shortcuts: PropTypes.array,
  disableCompletely: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
  percent: state.courseDetails.uploadPercent
});

export default connect(mapStateToProps, {
  userAssignmentComplete,
  moduleDetails,
  uploadAssignment
})(Lesson);
