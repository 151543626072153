/** @format */

import React from "react";
import { Modal } from "rsuite";
import axios from "axios";
import Popup from "reactjs-popup";
import {
  COURSE_SELECTED,
  MODULE_DETAILS,
  MODULE_STATS,
  LOAD_QUIZ_QUESTIONS,
  SAVE_SINGLE_QUIZ_ANSWER,
  SET_QUIZ_ID,
  START_LESSON,
  SAVE_MULTIPLE_QUIZ_ANSWER,
  ENROLL_COURSE,
  START_COURSE,
  CHANGE_USER_COMPLETE,
  QUIZ_INSTRUCTIONS,
  SUBMIT_QUIZ,
  CLEAR_QUIZ,
  RESET_ANSWERS,
  SET_FORUM_TYPE,
  EXAM_INSTRUCTIONS,
  START_EXAM_TIMMER,
  CLEAR_EXAM_DATA,
  ACTIVATE_EXAM_DATA,
  CLEAR_EXAM_TIMER,
  GET_TOP_COURSES_SUCCESS,
  GET_PUBLIC_COURSES_SUCCESS,
  GET_PUBLIC_COURSE_MODULE_SUCCESS,
  GET_LANDING_DETAIL_SUCCESS,
  UPLOAD_ASSIGNMENT_SUCCESS,
  UPLOAD_PERCENT,
  UPDATE_SELECTED_COURSE
} from "./types";
import { toast } from "react-toastify";
import { reject } from "lodash";

export const moduleSummaryStats = (id) => (dispatch) => {
  axios
    .post("/api/course/course_module/", { id })
    .then((res) => {
      localStorage.setItem("currentModule", res.data.current_module);
      // debugger;
      dispatch({
        type: MODULE_STATS,
        payload: res.data,
      });
      console.log("module.summry: ", res.data)
    })
    .catch((err) => console.log("courseDetails", err));
};


export const courseDetails = (course) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/course/detail_by_id/" + course.id + "/")
      .then((res) => {
        let data = { ...res.data, ...course };
        moduleSummaryStats(res.data.id)(dispatch);
        dispatch({
          type: COURSE_SELECTED,
          payload: data,
        });
        console.log("course.summry: ", data)
        resolve(res);
      })
      .catch((err) => {
        // reject(err);
      });
  });
};

export const publicCourseDetails = (course) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/course/public_course_module/", { id: course.id })
      .then((res) => {
        localStorage.setItem("currentModule", res.data.current_module);
        dispatch({ type: COURSE_SELECTED, payload: course })
        dispatch({
          type: GET_PUBLIC_COURSE_MODULE_SUCCESS,
          payload: res.data,
        });
        console.log("module.summry: ", res.data)
        resolve(res)
      })
      .catch((err) => console.log("courseDetails", err));
  })
}

export const moduleDetails = (current_module) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/course/module_data_for_lesson/${current_module}/`)
      .then((res) => {
        dispatch({
          type: MODULE_DETAILS,
          payload: res.data,
        });
        startLesson(res.data.current_lesson, res.data.current_index)(dispatch);
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

export const enrollCourse = (paymentState) => (dispatch) => {
  dispatch({
    type: ENROLL_COURSE,
    payload: paymentState,
  });
};

export const startCourse = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/student/start_subscription/", {
        course: id,
      })
      .then((res) => {
        setTimeout(() => {
          dispatch({
            type: START_COURSE,
          });
        }, 1000);

        resolve(res);
      })
      .catch((err) => {
        console.log("startCourse", err);
        reject(err);
      });
  });
};

export const startLesson = (next_lesson, current_index) => (dispatch) => {
  axios
    .post("/api/student/start_lesson/", {
      lesson_id: next_lesson.id,
      current_index,
    })
    .then((res) => {
      dispatch({
        type: START_LESSON,
        payload: { id: next_lesson.id, index: next_lesson.index },
      });
      console.log("startLesson", res.data);
    })
    .catch((err) => console.log("startLesson", err));
};

export const userAssignmentComplete = (obj, lessonDetails) => (dispatch) => {
  axios
    .post("/api/student/submit_assignment/", obj)
    .then((res) => {
      console.log("Lesseon Complete p---lesson detail: ", lessonDetails);
      console.log("Lesseon Complete p---data: ", res.data);
      let data = res.data;
      if (res.data.is_user_complete) {
        dispatch({
          type: CHANGE_USER_COMPLETE,
          payload: lessonDetails,
          data,
        });
      }
      if (res.data.msg) {
        toast.error(res.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch((err) => {
      console.log("submitQuiz", err);
    });
};

export const getQuizInstructs = (quizID) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/quiz/quizdetail/" + quizID + "/")
      .then((res) => {
        dispatch({
          type: QUIZ_INSTRUCTIONS,
          payload: res.data,
        });
        resolve(res);
        console.log("getQuizInstructs ------>>>>>>", res.data);
      })
      .catch((err) => reject(err));
  });
};

export const startQuiz = (quizId, moduleID) => (dispatch) => {
  axios
    .post("/api/quiz/quiz_attempt/", { quiz: quizId, module: moduleID })
    .then((res) => {
      dispatch({
        type: SET_QUIZ_ID,
        payload: res.data.attemp_quiz_id,
      });
      getQuizQuestions(quizId)(dispatch);
    })
    .catch((err) => {
      toast.error(`Quiz attempt api error ${err}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

export const getQuizQuestions = (quizID) => (dispatch) => {
  let url = `/api/quiz/questions/?quiz=${quizID}`;
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: LOAD_QUIZ_QUESTIONS,
        payload: res.data,
      });
    })
    .catch((err) => console.log("getQuizQuestions", err));
};

export const saveSingleAnswer = (answerIndex, ansId) => (dispatch) => {
  dispatch({
    type: SAVE_SINGLE_QUIZ_ANSWER,
    payload: { answerIndex, ansId },
  });
};

export const saveMultipleAnswer = (ansIndex, answerId) => (dispatch) => {
  dispatch({
    type: SAVE_MULTIPLE_QUIZ_ANSWER,
    payload: { ansIndex, answerId },
  });
};

export const submitQuiz = (obj) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/quiz/quiz_submit/", obj)
      .then((res) => {
        dispatch({
          type: SUBMIT_QUIZ,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => console.log("submitQuiz", err));
  });
};

export const resetAnswers = () => (dispatch) => {
  dispatch({
    type: RESET_ANSWERS,
  });
};

export const clearQuiz = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUIZ,
  });
};

export const setForumType = (forumType, forumId) => (dispatch) => {
  let obj = { forumName: forumType, forumId: forumId };
  dispatch({
    type: SET_FORUM_TYPE,
    payload: obj,
  });
};

export const startExam = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/exam/attempt/", body)
      .then((res) => {
        dispatch({
          type: START_EXAM_TIMMER,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const getExamInstructs = (courseId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/exam/details/?course=" + courseId)
      .then((res) => {
        dispatch({
          type: EXAM_INSTRUCTIONS,
          payload: res.data,
        });
        setTimeout(() => resolve(res), 1000);
      })
      .catch((err) => reject(err));
  });
};

export const getTopCourses = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/course/top_courses/')
      .then((res) => {
        dispatch({ type: GET_TOP_COURSES_SUCCESS, payload: { data: res.data.data } });
        setTimeout(() => resolve(res), 1000);
      })
      .catch((err) => reject(err));
  })
}

export const getPublicCourses = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/course/public_course_list/')
      .then((res) => {
        dispatch({ type: GET_PUBLIC_COURSES_SUCCESS, payload: { data: res.data } });
        setTimeout(() => resolve(res), 1000);
      })
      .catch((err) => reject(err));
  })
}

export const getLandingDetail = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/landing_page/configure/')
      .then((res) => {
        dispatch({ type: GET_LANDING_DETAIL_SUCCESS, payload: res.data })
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const uploadAssignment = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('submitted_file', data.file)
    const url = "/api/student/upload_assignment_file/" + data.id + "/";
    axios.patch(url, formData, {
      onUploadProgress: (e) => {
        const progress = e.lengthComputable ? (e.loaded / e.total) * 100 : 0
        let percent = parseInt(progress).toFixed(2)
        dispatch({ type: UPLOAD_PERCENT, payload: parseInt(percent) })
      }
    })
      .then((res) => {
        dispatch({ type: UPLOAD_ASSIGNMENT_SUCCESS, payload: res.data })
        toast.error("Assignment Uploaded Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const checkSavedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/student/check_card/')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const payFromSavedCard = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/student/pay/', data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const updateSelectedCourse=(data)=>(dispatch)=>{
  return new Promise((resolve,reject)=>{
    dispatch({type:UPDATE_SELECTED_COURSE,payload:data})
    resolve(data)
  })
}



export const submitExam = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXAM_TIMER,
  });
};

export const clearExamInstructions = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXAM_DATA,
  });
};

export const activateExams = () => (dispatch) => {
  dispatch({
    type: ACTIVATE_EXAM_DATA,
  });
};
