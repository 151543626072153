/** @format */

import React, { Component, Fragment } from "react";
import "./stepper.scss";
export default class Stepper1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "",
    };
  }
  componentDidMount() {
    let width = document.getElementsByClassName("stepper1")[0].clientWidth;
    this.setState({ width });
    console.log("Total Steps: ", this.props.totalSteps);
    console.log("Active: ", this.props.totalSteps);
  }
  changeStep = (e) => {
    this.props.navigateToLesson(e);
  };

  render() {
    const { totalSteps, activeLesson } = this.props;
    const stepperSteps = totalSteps;
    let size = window.screen.width > 600 ? 49 : 26;
    let barlength =
      (this.state.width - size * stepperSteps.length) /
      (stepperSteps.length - 1);
    return (
      <Fragment>
        <div className="stepper1">
          {stepperSteps.map((step, index) => {
            return (
              <div
                key={index}
                className={`step-container ${!step.is_user_complete ? "disableClick" : !stepperSteps[index]?.is_user_complete
                  ? "disableClick"
                  : ""
                  }`}
                onClick={(e) => this.changeStep(index)}
              >
                <div className={`step  ${activeLesson == index ? "currentlesson" : `${
                  step.is_user_complete || index == 0
                    ? ""
                    : "incomplete"
                  }`
                  }`}
                >
                  {/* {step.name === undefined ? index + 1 : step.name} */}
                  {index + 1}
                </div>
                <div
                  className={`barline ${
                    step.is_user_complete ? "" : "incomplete-barline"
                    } ${activeLesson == index ? "currentlesson" : ""}  `}
                  style={{ width: `${barlength}px` }}
                ></div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}
