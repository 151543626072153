import isEmpty from "../validation/is-empty";

import {
  SET_CURRENT_USER,
  SET_PROFILE,
  RESET_PROFILE,
  PAYMENT_COMPLETE,
  RESET_PASSWORD,
  GET_LANDING_DETAIL_SUCCESS
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  landing: null,
  user: {},
  profile: {},
  resetToken: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case PAYMENT_COMPLETE:
      return {
        ...state,
        profile: {
          ...state.profile,
          is_payment_complete: true,
        },
      };

    case RESET_PROFILE:
      return {
        ...state,
        profile: {},
      };

    case RESET_PASSWORD:
      return {
        ...state,
        resetToken: action.payload
      }

    case GET_LANDING_DETAIL_SUCCESS:
      return {
        ...state,
        landing: action.payload
      }

    default:
      return state;
  }
}
