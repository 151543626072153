import React, { useState } from 'react'
import { HiOutlineMenuAlt3, AiOutlineClose } from "react-icons/all"
import { logo } from "../../contants/images"
import history from "../../helper/History"
import { Button } from "rsuite"
export default function Navbar() {

    const [isClicked, setClicked] = useState(false)
    return (
        <div className="landing-navbar" >
            <nav className="nav-bar" >
                <div className="brand-box" onClick={()=>history.push('/')} >
                    <img src={logo.logo} />
                </div>
                <div className="menu-icon">
                    {!isClicked ? <HiOutlineMenuAlt3 size={40} onClick={() => setClicked(!isClicked)} /> :
                        <AiOutlineClose size={40} onClick={() => setClicked(!isClicked)} className="micon"  />}
                </div>
                <ul className={isClicked ? "nav-menu active" : "nav-menu"} >
                    <div className="right-box">
                        <div>
                            <Button appearance="ghost" size="lg" onClick={() => window.open('https://niwe.eduley.com/','_self')} block >Register</Button>
                        </div>
                        <div>
                            <Button appearance="primary" size="lg" onClick={() => history.push('/login')} block >Login</Button>
                        </div>
                    </div>
                    <div className="mobile-menu" >
                        <div>
                            <Button appearance="primary" size="lg" onClick={() => window.open('https://niwe.eduley.com/','_self')} block >Register</Button>
                        </div>
                        <div>
                            <Button appearance="primary" size="lg" onClick={() => history.push('/login')} block >Login</Button>
                        </div>
                    </div>
                    {/* <li onClick={_logout} style={{ cursor: "pointer" }}><IoIosLogOut /> Logout</li> */}
                </ul>
            </nav>
        </div>
    )
}
