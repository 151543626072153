/** @format */

import React, { Component, Fragment } from "react";
import logo from "../../img/showcase.jpg";
import axios from "axios";
import loader from "../../assets/loader.gif";
import "../../App.scss";
import { connect } from "react-redux";

class ForumQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: -1,
      forumQuestions: {},
      expand: false,
      comment: "",
      isRequesting: true,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getDetails(id);
    axios.get("/api/student/student_info/").then((res) => {
      const { user } = res.data[0];
      this.setState({ userId: user });
    });
  }

  getDetails = (id) => {
    axios
      .get(`/api/forum/forumdata/${id}/`)
      .then((res) => {
        this.setState({
          forumQuestions: res.data,
          isRequesting: false,
        });
      })
      .catch((err) => console.log("err", err));
  };

  handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  expandtextArea = () => {
    this.setState({ expand: true });
  };

  submitComment = (e) => {
    this.setState({ isRequesting: true });
    e.preventDefault();
    let comment = {
      person: this.state.userId,
      thread: this.props.match.params.id,
      reply_text: this.state.comment,
    };
    axios
      .post("/api/forum/comment/", comment)
      .then((res) => {
        this.getDetails(this.props.match.params.id);
        this.setState({ comment: "", isRequesting: false });
      })
      .catch((err) => console.log("ERR", err));
  };

  render() {
    const expandStyle = {
      width: "100%",
      height: "100px",
      borderRadius: "10px",
    };

    const { forumQuestions, expand, comment, isRequesting } = this.state;
    const { forumName } = this.props.courseDetails.forumDetails;

    const {
      first_name,
      last_name,
      user_image,
      question_text,
      created_date,
      comments,
    } = forumQuestions ? forumQuestions : null;
    const createdAt = created_date
      ? created_date.split(".")[0].replace("T", " ")
      : "";
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <div className="welcome">
            NiWE forums
            {/* <button
              className="hollow"
              onClick={() => this.props.history.push("/forum")}
            >
              {" "}
              <span>
                <i className="fa fa-angle-left fa-sm"></i>
              </span>
              Go Back
            </button> */}
          </div>
          <div className="course-name caps">{forumName}</div>
          <div className="question">
            <div className="question-asked-by">
              <div>
                <img
                  src={user_image ? user_image : logo}
                  alt="pic"
                  width="96"
                  height="96"
                />
              </div>
              <div>
                <div className="asked-time">{createdAt}</div>
                <div className="author">
                  {first_name}
                  {last_name}
                </div>
                <div className="question">{question_text}</div>
              </div>
            </div>
            <div className="comments">
              {comments &&
                comments.map((cmnt, index) => {
                  let ansCreatedAt = cmnt.created_date
                    .split(".")[0]
                    .replace("T", " ");

                  return (
                    <Fragment>
                      <div className="comment">
                        <div>
                          <img
                            src={cmnt.user_image ? cmnt.user_image : logo}
                            alt="pic"
                            width="96"
                            height="96"
                          />
                        </div>
                        <div>
                          <div className="commented-time">{ansCreatedAt}</div>

                          <div className="commented-by">
                            {cmnt.first_name}
                            {cmnt.last_name}
                          </div>
                          <div className="comment-text">{cmnt.reply_text}</div>
                        </div>
                      </div>
                      {comments.length - 1 == index ? null : (
                        <div className="hr"></div>
                      )}
                    </Fragment>
                  );
                })}
              <form onSubmit={this.submitComment}>
                <div className="add-comment">
                  <textarea
                    onChange={this.handleChange}
                    onClick={this.expandtextArea}
                    style={expand ? expandStyle : {}}
                    className="expand"
                    cols="10"
                    placeholder="Add a comment…"
                    value={comment}
                    required
                  ></textarea>
                  {!expand && (
                    <div className="icon">
                      <i className="fa fa-angle-right fa-2x"></i>
                    </div>
                  )}
                </div>
                <div style={{ float: "right", marginTop: "10px" }}>
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  courseDetails: state.courseDetails,
});

export default connect(mapStateToProps, null)(ForumQuestion);
