import React from "react";

const NotFound = (props) => {
  return (
      <div class="container text-center my-5">
        <h1 class="text-primary">
          <img src="https://img.icons8.com/cute-clipart/100/000000/bug.png" />{" "}
          404
        </h1>
        <h1 class="text-primary">Page not found</h1>
        <h4 style={{marginBottom:"20px"}}>
          We are sorry, the page you requested could not be found.Please go back
          to the homepage .
        </h4>
        <button className="holow" onClick={()=>props.history.push("/")} > Go Back</button>
     
    </div>
  );
};

export default NotFound;
