import React, { Component, Fragment } from "react";
import axios from "axios";
import loader from "../assets/loader.gif";
import {connect} from 'react-redux'


class CourseResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isRequesting: true,
    };
  }
  componentDidMount() {
    axios
      .post("/api/exam/exam_score/", { course: this.props.match.params.id })
      .then((res) => {
        this.setState({ data: res.data, isRequesting: false });
      })
      .catch((err) => this.setState({ isRequesting: false }));
  }

  download(link) {
    window.open(link);
  }

  render() {
    const result = {
      margin: "20px 0",
    };
    const {
      course_name,
      score,
      status,
      certificate,
      subscription_status,
      is_certificate,
      msg,
      transcript,
    } = this.state.data;
    const { isRequesting } = this.state;
    return (
      <Fragment>
        {isRequesting && (
          <Fragment>
            <div className="modal-backdrop in" style={{ opacity: "0" }}></div>
            {/* <div className="modal-backdrop show"></div> */}
            <div class="loader">
              <img src={loader} alt="loader" />
            </div>
          </Fragment>
        )}
        <div className="container">
          <h1>Results</h1>
          {isRequesting ? (
            "Loading..."
          ) : (
              <Fragment>
                {course_name &&(
                  <div style={result}>
                    <h1>Course : {course_name}</h1>

                    <h3 style={{ marginBottom: "20px" }}>
                      Your result is : <b>{status}</b>
                    </h3>

                    <h3 style={{ marginBottom: "40px" }}>
                      {" "}
                    Total Marks Obtained :<b>
                        {" "}
                        {score ? score : "PENDING"}
                      </b>{" "}
                    </h3>
                    {this.props.courseDetails.generate_certificate&&<Fragment>
                    <div>
                      <button
                        className={`hollow`}
                        onClick={() => this.download(certificate)}
                        style={{ marginBottom: "10px", marginRight: 10 }}
                      >
                        Download Certificate
                  </button>

                      <button
                        className={`hollow`}
                        onClick={() => this.download(transcript)}
                        style={{ marginBottom: "10px" }}
                      >
                        Download Transcript
                  </button>
                    </div>

                    <div>
                      <span style={{ fontSize: "12px" }}>
                        {msg}
                      </span>
                    </div>
                    </Fragment>}
                  </div>
                )}
              </Fragment>
            )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps=state=>{
  return{
    courseDetails:state.courseDetails.courseDetails
  }
}


export default connect(mapStateToProps,null)(CourseResult)